import { ArrowDownSvg, EditSvg } from '../../../../../assets/svg-icon';
import { useAppDispatch, useAppSelector } from '../../../../../hooks/redux';
import React, { FC, MouseEvent, useState } from 'react';
import { activePopup } from '../../../../../store/redux-toolkit/settings/account/account-slice';
import classNames from 'classnames';
import eng from '../../../../../assets/images/flag1.png';
import ua from '../../../../../assets/images/flag3.png';
import { TextareaField } from '../../../../../ui/Formik/TextareaField/TextareaField';
import { getProfileSelector } from '../../../../../store/redux-toolkit/settings/account/account-selector';
import { useTranslation } from 'react-i18next';

type TProps = {
  addLan: (value: string) => void;
  profileLan: string | undefined;
};

export const GeneralInfo: FC<TProps> = ({ addLan, profileLan }) => {
  const { t } = useTranslation();
  const [lanPopup, setLanPopup] = useState<boolean>(false);
  const profile = useAppSelector(getProfileSelector);
  const [lan, setLan] = useState<string | undefined>(profileLan);

  const dispatch = useAppDispatch();

  const onEdit = (event: MouseEvent) => {
    const { name } = event.currentTarget as HTMLButtonElement;

    dispatch(activePopup(name));
  };

  const onOpenPopupLan = (event: MouseEvent) => {
    const { name } = event.target as HTMLButtonElement;
    name && addLan(name);
    // name && dispatch(changeAppLanguage(name));
    setLanPopup(!lanPopup);
    name && setLan(name);
  };

  return (
    <div className='content-block'>
      <div className='account-wrap'>
        <div className='account-row'>
          <div className='form-group'>
            <div className='input input--mb-0'>
              <p className='input-name'>{t('Page.Settings.Account.display_name')}</p>
              <div className='input-with-edit'>
                <div className='input-wrapper'>
                  <input
                    value={profile?.username || ''}
                    className='input-item'
                    type='text'
                    placeholder='Display Name'
                    disabled
                  />
                </div>
                <button
                  onClick={onEdit}
                  name='display-name'
                  className='button button--icon-type'
                  type='button'
                  aria-label='onEdit'
                >
                  <span className='btn-icon'>
                    <EditSvg />
                  </span>
                </button>
              </div>
            </div>
          </div>
          <div className='form-group'>
            <div className='input input--mb-0'>
              <p className='input-name'>{t('Page.Settings.Account.email_address')}</p>
              <div className='input-with-edit'>
                <div className='input-wrapper'>
                  <input
                    value={profile?.email || ''}
                    className='input-item '
                    type='text'
                    placeholder='Email'
                    disabled
                  />
                </div>
                <button
                  onClick={onEdit}
                  name='email-address'
                  className='button button--icon-type'
                  type='button'
                  aria-label='onEdit'
                >
                  <span className='btn-icon'>
                    <EditSvg />
                  </span>
                </button>
              </div>
              {/* <p className='input-notification input-notification--mt-4 input-notification--type2'>
                {t('Page.Settings.Account.unverified')}
                <button type='button'>{t('Page.Settings.Account.resend_email')}</button>
              </p> */}
            </div>
          </div>
        </div>
        <div className='account-row'>
          <div className='form-group'>
            <div className='select-block select-block--mb-0'>
              <p className='input-name'>{t('Page.Settings.Account.pref_communication_lang')}</p>
              <div className={classNames('select', { active: lanPopup })}>
                <button
                  aria-label='onOpenPopupLan'
                  onClick={onOpenPopupLan}
                  type='button'
                  className='select__current'
                >
                  <span className='dropdown-icon'>
                    <img loading='lazy' src={(lan || profileLan) === 'en' ? eng : ua} alt='Flag' />
                  </span>
                  {(lan || profileLan) === 'en' ? 'English' : 'Ukrainian'}
                  <span className='select__current-arrow'>
                    <ArrowDownSvg />
                  </span>
                </button>
                <div className='select__drop'>
                  <div className='select__drop-scroll'>
                    <div className='select__drop-item'>
                      <ul>
                        <li>
                          <button onClick={onOpenPopupLan} name='en' type='button'>
                            <span className='dropdown-icon'>
                              <img loading='lazy' src={eng} alt='Eng logo' />
                            </span>
                            English
                          </button>
                        </li>
                        <li>
                          <button onClick={onOpenPopupLan} name='ua' type='button'>
                            <span className='dropdown-icon'>
                              <img loading='lazy' src={ua} alt='Ua logo' />
                            </span>
                            Ukrainian
                          </button>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <p className='input-notification input-notification--mt-4 input-notification--type2'>
                {t('Page.Settings.Account.choose_language')}
              </p>
            </div>
          </div>
          <TextareaField name='bio' label='Page.Settings.Account.bio' placeholder='Bio' />
        </div>
      </div>
    </div>
  );
};
