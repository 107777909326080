export const onScrollDown = () => {
  const check = document.getElementById('header-content');

  if (check) {
    const element = document.getElementById('header-content')!;
    const header = document.getElementById('header-id')!;

    window.scrollTo({
      top: element?.clientHeight + header?.clientHeight,
      // behavior: 'smooth',
    });
  }
};
