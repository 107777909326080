import React, { FC } from 'react';
import img1 from '../../assets/images/sponsor-logo-new1.png';
import img2 from '../../assets/images/sponsor-logo-new2.png';
import img3 from '../../assets/images/sponsor-logo-new3.png';
import img4 from '../../assets/images/sponsor-logo-new4.png';
import { IBackersResponse } from '../../store/redux-toolkit/backers/backers-types';

interface IProps {
  backersPlatinum: IBackersResponse;
}

export const SponsorsSlider: FC<IProps> = ({ backersPlatinum }) => {
  return (
    <div className='sponsors-slider-box'>
      <div className='swiper-container sponsors-slider'>
        <div className='swiper-wrapper'>
          {backersPlatinum?.data.map((backer) => (
            <div key={backer.uuid} className='swiper-slide'>
              <div className='sponsor-item'>
                <span className='d-flex sponsor-item__logo'>
                  <img loading='lazy' src={backer.avatar_url} alt='sponsor' />
                </span>
                <span className='sponsor-item__logo-bg'>
                  <img loading='lazy' src={backer.avatar_url} alt='sponsor' />
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
