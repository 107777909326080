import React, { FC, useEffect } from 'react';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getStatusPopupsSelector } from '../../../store/redux-toolkit/auth/app-selector';
import { PopupAuth } from '../../../ui/Popups/PopupAuth';
import { ERoutes, ESuccess } from '../../../enum/types';
import { clearSuccess } from '../../../store/redux-toolkit/auth/auth-slice';
import images from '../../../assets/svg-icon/success-auth.svg';
import { ResetPasswordForm } from '../../../components/Forms/ResetPasswordForm/ResetPasswordForm';
import { resetPassword } from '../../../store/redux-toolkit/auth/auth-thunks';
import { IResetPass } from './types';

const ResetPassword: FC = () => {
  const dispatch = useAppDispatch();
  const statusPopup = useAppSelector(getStatusPopupsSelector);
  const searchParams = new URLSearchParams(location.search);
  const token = searchParams.get('token');

  const handleResetPassSubmit = (values: IResetPass): void => {
    dispatch(resetPassword({ ...values, token }));
  };

  useEffect(() => {
    return () => {
      dispatch(clearSuccess());
    };
  }, []);

  return statusPopup === ESuccess.reset ? (
    <PopupAuth
      image={images}
      text='Your password has been successfully changed'
      title='Successful'
      link={ERoutes.login}
      textBtn='Log in'
    />
  ) : (
    <ResetPasswordForm resetPassSubmit={handleResetPassSubmit} />
  );
};

export default ResetPassword;
