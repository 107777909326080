import React, { FC } from 'react';
import { useLocation, Link } from 'react-router-dom';
import { authPathname } from '../../services/constants/authPathname';
import { FooterSocials } from './FooterSocials/FooterSocials';
import { ERoutes } from '../../enum/types';
import { LogoSvg } from '../../assets/svg-icon';
import { onScrollDown } from '../../utils/scrollY';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const Footer: FC = () => {
  const { pathname } = useLocation();
  const { t } = useTranslation();

  return authPathname.includes(pathname) ? (
    <footer className='authorization-section-custom'>
      <div className='authorization'>
        <p className='authorization-copyright'>
          {`© ${moment().format('YYYY')} ${t('Footer.copyright_short')}`}
        </p>
      </div>
    </footer>
  ) : (
    <footer className='landing-footer'>
      <div className='container'>
        <div className='footer-top'>
          <FooterSocials />

          <div className='footer-bottom'>
            <div className='container'>
              <div className='footer-box'>
                <div className='footer-box__left'>
                  <div className='landing-terms-nav'>
                    {/* <Link to={ERoutes.faq}>FAQ</Link>
                    <Link to='/404'>Community Rules</Link> */}
                    <Link onClick={onScrollDown} to={ERoutes.terms}>
                      {t('Footer.terms_of_use')}
                    </Link>
                    <Link to={ERoutes.privacyPolicy}>{t('Footer.privacy_policy')}</Link>
                    {/* <Link to={ERoutes.cookiePolicy}>Cookie Policy</Link> */}
                  </div>
                  <p className='copyright'>
                    {`© ${moment().format('YYYY')} ${t('Footer.copyright')}`}
                  </p>
                </div>
                <div className='footer-box-right'>
                  <div className='logo'>
                    <LogoSvg width={'6em'} height={'2em'} />
                  </div>
                  {/* <p>{t('Footer.made_in')}</p> */}
                </div>
              </div>
            </div>
          </div>

          {/* <div className='landing-terms-nav'></div> */}
          {/* <button onClick={onScrollUp} className='to-top-btn'>
            <div className='arrow-down'>
              <span className='arrow-down__elem'></span>
              <span className='arrow-down__elem'></span>
              <span className='arrow-down__elem'></span>
            </div>
          </button> */}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
