export enum LoadingStatus {
  LOADED = 'LOADED',
  LOADING = 'LOADING',
  ERROR = 'ERROR',
  NEVER = 'NEVER',
  SUCCESS = 'SUCCESS',
}

export enum ERoutes {
  //Public routes
  home = '/',
  login = '/login',
  registration = '/registration',
  emailVerify = '/email-verify',
  forgotPass = '/forgot-password',
  resetPass = '/password/reset',
  project = 'project',
  updates = '/updates',
  comments = '/comments',
  team = '/team',
  contacts = '/contacts',
  terms = '/terms',
  privacyPolicy = '/privacy-policy',
  cookiePolicy = '/cookie-policy',
  faq = '/faq',
  successful = '/successful',
  error = '/error',
  backers = '/backers',
  //Privat routes
  settings = '/settings',
  account = '/settings/account',
  accountInformationTab = '/settings/account/information',
  accountAddressTab = '/settings/account/address',
  security = '/settings/security',
  securityChangePassword = '/settings/security/change-password',
  securityTwoFactor = '/settings/security/two-factor',
  notification = '/settings/notification',
  payment = '/settings/payment',
  transaction = '/settings/transaction',
  myComments = '/settings/my-comments',
  sponsors = '/sponsors',
}

export enum ESuccess {
  reset = 'reset-success',
  forgot = 'forgot-success',
}
