import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../services/http/interceptor/request-service';
import { showError } from '../../../utils/showError';
import { endpoint } from '../../../services/endpoint';
import { IProfileError } from '../settings/account/account-types';
import { notificationContainer } from '../../../ui/notificationContainer';
import { t } from 'i18next';
import { clearErrors } from './comments-slice';
import {
  ICommentRequest,
  ICreateComments,
  ICreateSubComments,
  ISubCommentRequest,
} from './comments-types';

export const postComments = createAsyncThunk(
  'comments/postComments',
  async (payload: ICreateComments, thunkApi) => {
    // const { paramsApi } = payload;

    try {
      const response = await requestService.post(endpoint.comments.POST_COMMENTS, payload);
      return { ...response.data, sub: payload };
    } catch (error) {
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getComments = createAsyncThunk(
  'comments/getComments',
  async (payload: ICommentRequest, thunkApi) => {
    try {
      const response = await requestService.get(endpoint.comments.GET_COMMENTS, { ...payload });
      thunkApi.dispatch(clearErrors());
      return response.data;
    } catch (error) {
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const postSubComments = createAsyncThunk(
  'comments/postSubComments',
  async (payload: ICreateSubComments, thunkApi) => {
    // const { paramsApi } = payload;

    try {
      const response = await requestService.post(endpoint.comments.POST_SUB_COMMENTS, payload);
      return response.data;
    } catch (error) {
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const getSubComments = createAsyncThunk(
  'comments/getSubComments',
  async (payload: ISubCommentRequest, thunkApi) => {
    const { commentUUID } = payload;

    const data = {
      page_number: payload.page_number,
      show_count: payload.show_count,
    };

    try {
      const response = await requestService.get(endpoint.comments.GET_SUB_COMMENTS(commentUUID), {
        ...data,
      });
      thunkApi.dispatch(clearErrors());

      return { ...response.data, sub: payload };
    } catch (error) {
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);
