import React, { FC } from 'react';
import live_support from '../../../assets/images/project-support.png';
import { useTranslation } from 'react-i18next';

export const Support: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-info project-info--type2 project-info--support'>
      <div className='project-support-text'>
        <div className='project-title-block'>
          <h2 className='project-title'>{t('Page.Project.live_support')}</h2>
        </div>
        <div className='project-text '>
          <h3 className='info-subtitle info-subtitle--size2'>
            {t('Page.Project.live_support_desc')}
          </h3>
        </div>
      </div>
      <div className='project-img project-img--support'>
        <div className='d-flex project-img__item'>
          <img loading='lazy' src={live_support} alt='support' />
        </div>
      </div>
    </div>
  );
};
