import {
  MakeNewFriendsOneSvg,
  MakeNewFriendsSvg,
  MakeNewFriendsThreeSvg,
  MakeNewFriendsTwoSvg,
  PlayYourWayFourSvg,
  PlayYourWayOneSvg,
  PlayYourWayThreeSvg,
  PlayYourWayTwoSvg,
} from '../../../assets/svg-icon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const PlayYourWay: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-bg-section'>
      <div className='container container--main'>
        <h3 className='project-subtitle'>
          {t('Page.Project.play')} <span>{t('Page.Project.it_your_way')}</span>
        </h3>
        <div className='project-info-grid'>
          <div className='project-info-card'>
            <div className='play-option-icon'>
              <span className='d-flex play-option-icon__figure'>
                <PlayYourWayOneSvg width={'9em'} height={'9em'} />
              </span>
              <span className='d-flex play-option-icon__item'>
                <PlayYourWayTwoSvg width={'4em'} height={'4em'} />
              </span>
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.play_one_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.play_one_subtitle')}</p>
            </div>
          </div>
          <div className='project-info-card'>
            <div className='play-option-icon'>
              <span className='d-flex play-option-icon__figure'>
                <PlayYourWayThreeSvg width={'9em'} height={'9em'} />
              </span>
              <span className='d-flex play-option-icon__item'>
                <PlayYourWayFourSvg width={'4em'} height={'4em'} />
              </span>
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.play_two_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.play_two_subtitle')}</p>
            </div>
          </div>
          <div className='project-info-card'>
            <div className='play-option-icon'>
              <span className='d-flex play-option-icon__figure'>
                <MakeNewFriendsSvg width={'9em'} height={'9em'} />
              </span>
              <span className='d-flex play-option-icon__item'>
                <MakeNewFriendsOneSvg width={'4em'} height={'4em'} />
              </span>
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.play_three_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.play_three_subtitle')}</p>
            </div>
          </div>
          <div className='project-info-card'>
            <div className='play-option-icon'>
              <span className='d-flex play-option-icon__figure'>
                <MakeNewFriendsTwoSvg width={'9em'} height={'9em'} />
              </span>
              <span className='d-flex play-option-icon__item'>
                <MakeNewFriendsThreeSvg width={'4em'} height={'4em'} />
              </span>
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.play_four_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.play_four_subtitle')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
