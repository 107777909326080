import axios, { AxiosRequestConfig, Method } from 'axios';
import onError from './onError';
import { getHost } from '../../../../src/utils/getHost';

class RequestService {
  get = (
    url: string,
    params?: object,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('GET', url, null, isAuthRequired, contentType, params);
  };

  post = (
    url: string,
    body: any,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('POST', url, body, isAuthRequired, contentType);
  };

  multipart = (
    url: string,
    body: any,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('POST', url, body, isAuthRequired, contentType);
  };

  put = (
    url: string,
    body?: any,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('PUT', url, body, isAuthRequired, contentType);
  };

  delete = (
    url: string,
    body?: any,
    isAuthRequired: boolean = true,
    contentType: string = 'application/json'
  ) => {
    return createRequest('DELETE', url, body, isAuthRequired, contentType);
  };
}

const createRequest = (
  method: Method,
  url: string,
  body: any,
  isAuthRequired: boolean,
  contentType: string,
  params?: object
) => {
  const config: AxiosRequestConfig = {
    method: method,
    // url: 'http://dev-playch.dev-now.xyz/api/v1' + url,
    url: window.location.protocol + '//' + getHost() + '/api/v1' + url,
    data: body,
    params,
    // @ts-ignore
    headers: setHeader(isAuthRequired, contentType),
  };

  return axios(config);
};

const setHeader = (isAuthRequired: boolean, contentType: string): void => {
  if (isAuthRequired) {
    axios.defaults.headers.common['Authorization'] = `Bearer ${
      localStorage.getItem('token') || undefined
    }`;
  } else {
    delete axios.defaults.headers.common['Authorization'];
  }
  axios.defaults.headers.common['Content-Type'] = contentType;
};

axios.interceptors.response.use((response) => response, onError);

export default new RequestService();
