import React, { FC, useRef } from 'react';
import { useAppSelector } from '../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { CountrySelect } from '../../../ui/Countries/Countries';
import { ReactComponent as Logo } from '../../../assets/images/Logo-dark.svg';
import Input from '../../../ui/Formik/Input';
import PasswordField from '../../../ui/Formik/PasswordField';
import { CaptchaWrapper } from './CaptchaWrapper/CaptchaWrapper';
import CheckBox from '../../../ui/Formik/Checkbox';
import { Link } from 'react-router-dom';
import { ERoutes, LoadingStatus } from '../../../enum/types';
import { registerSchema } from '../../../form-validations/register-validation';
import { IPropsRegistration } from './types';
import { IRegistration } from '../../../store/redux-toolkit/auth/auth-types';
import { LoadingSmall } from '../../../ui/LoadingSmall/LoadingSmall';
import type HCaptcha from '@hcaptcha/react-hcaptcha';

const initialValues: IRegistration = {
  captcha_code: '',
  country: '',
  email: '',
  first_name: '',
  last_name: '',
  password: '',
  password_confirmation: '',
  username: '',
};

export const SignUpForm: FC<IPropsRegistration> = ({ loginSubmit }) => {
  const { t } = useTranslation();
  const { loadingState } = useAppSelector((store) => store.auth);
  const captchaRef = useRef<HCaptcha>(null);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={registerSchema}
      onSubmit={(values: IRegistration, { setSubmitting }) => {
        loginSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty, setFieldValue, errors, values }) => {
        const addCountry = (value: string) => {
          setFieldValue('country', value);
        };

        const onActiveCaptcha = (value: string) => {
          setFieldValue('captcha_code', value);
        };

        return (
          <Form className='form form--authorization'>
            <div className='form__logo'>
              <Logo width={'3em'} height={'3em'} />
            </div>
            <h2 className='form-title'>{t('Forms.Sign_up.sign_up')}</h2>
            <div className='form__body'>
              <Field
                name='country'
                className='input-item'
                type='text'
                component={CountrySelect}
                addCountry={addCountry}
              />

              <div className='form-two-items'>
                <Field
                  name='first_name'
                  className='input-item'
                  type='text'
                  component={Input}
                  placeholder={t('Forms.Sign_up.first_name')}
                  value={values.first_name}
                />
                <Field
                  name='last_name'
                  className='input-item'
                  type='text'
                  component={Input}
                  placeholder={t('Forms.Sign_up.last_name')}
                  value={values.last_name}
                />
              </div>
              <div className='input'>
                <div className='input-wrapper'>
                  <Field
                    name='username'
                    className='input-item input-item--pr-60'
                    type='text'
                    component={Input}
                    placeholder={t('Forms.Sign_up.display_name')}
                    autoComplete='off'
                    value={values.username}
                  />
                </div>
              </div>
              <div className='input'>
                <div className='input-wrapper'>
                  <Field
                    name='email'
                    className='input-item'
                    type='email'
                    component={Input}
                    placeholder={t('Forms.Sign_up.email_address')}
                    value={values.email}
                  />
                </div>
              </div>
              <Field
                className='input-item input-item--pr-90'
                name='password'
                component={PasswordField}
                placeholder={t('Forms.Sign_up.password')}
                value={values.password}
              />
              <Field
                className='input-item input-item--pr-90'
                name='password_confirmation'
                component={PasswordField}
                placeholder={t('Forms.Sign_up.password')}
                value={values.password_confirmation}
              />
              <Field
                name='receive_news'
                component={CheckBox}
                text={t('Forms.Sign_up.receive_news')}
              />
              <Field name='terms_of_service' component={CheckBox} />
            </div>
            <CaptchaWrapper
              onActiveCaptcha={onActiveCaptcha}
              errorCaptcha={errors.captcha_code}
              captchaRef={captchaRef}
            />
            <div className='form-submit'>
              <button
                disabled={!(isValid && dirty) || isSubmitting}
                className={`button button--full-width ${
                  loadingState === LoadingStatus.LOADING ? 'button--loading' : ''
                }`}
                type='submit'
              >
                {loadingState === LoadingStatus.LOADING ? <LoadingSmall /> : t('Global.continue')}
              </button>
            </div>
            <div className='form__extra'>
              <p>
                <a
                  href={ERoutes.privacyPolicy}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='link'
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className='form__extra'>
              <p>
                {t('Forms.Sign_up.sign_in')}
                <Link to={ERoutes.login} className='link'>
                  {t('Forms.Login.sign_in')}
                </Link>
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
