export const listRouts = [
  '/login',
  '/registration',
  '/email-verify',
  '/forgot-password',
  'password/reset',
  '/project',
  '/updates',
  '/comments',
  '/team',
  '/backers',
  '/contacts',
  // '/terms',
  '/settings',
  '/settings/account',
  '/settings/account/information',
  '/settings/account/address',
  '/settings/security',
  '/settings/security/change-password',
  '/settings/security/two-factor',
  '/settings/notification',
  '/settings/payment',
  '/settings/transaction',
  '/settings/my-comments',
];
