import { ERoutes } from '../../enum/types';
import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import images from '../../assets/images/icon-error.png';
import { useTranslation } from 'react-i18next';

export const Error: FC = () => {
  const { t } = useTranslation();

  return (
    <section className='authorization-section'>
      <form className='form form--authorization'>
        <div className='form-icon'>
          <img loading='lazy' src={images} alt='error' />
        </div>
        <h2 className='form-title'>{t('Popup.title_error')}</h2>
        <div className='form__body'>
          <div className='form-text form-text--ta-center'>
            <p>{t('Errors.error_page')}</p>
          </div>
        </div>
        <div className='form__extra'>
          <Link to={ERoutes.home} className='button button--full-width'>
            {t('Popup.button')}
          </Link>
        </div>
      </form>
    </section>
  );
};
