import React, { FC } from 'react';
import sponsor from '../../../assets/images/sponsor-logo1.png';
import sponsor2 from '../../../assets/images/sponsor-logo2.png';
import backer_img from '../../../assets/images/backer-lvl1.png';
import { IBackers } from '../../../store/redux-toolkit/backers/backers-types';

const data = [sponsor, sponsor2, sponsor, sponsor2];

interface IProps {
  backers_platinum: undefined | Array<IBackers>;
}

export const BackersPlatinumBlok: FC<IProps> = ({ backers_platinum }) => {
  return (
    <div className='backers-list backers-list--big'>
      {backers_platinum?.map((backer, index) => (
        <div key={index} className='backer'>
          <div className='d-flex backer__logo-wrap'>
            <div className='d-flex backer__logo'>
              <img loading='lazy' src={backer.avatar_url} alt='sponsor' />
            </div>
            <div className='backer__logo-bg'>
              <img loading='lazy' src={backer.avatar_url} alt='sponsor' />
            </div>
          </div>
          <div className='d-flex backer__lvl'>
            <img loading='lazy' src={backer_img} alt='lvl' />
          </div>
          <div className='backer__info'>
            <p>{backer.company_name || backer.username}</p>
          </div>
        </div>
      ))}
    </div>
  );
};
