import { toast, ToastOptions } from 'react-toastify';
import ToastifyComponent from '../components/ToastifyComponent';

const toastifyConfig: ToastOptions = {
  position: 'top-right',
  autoClose: 5000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const notificationContainer = (message: string, type: string): void => {
  switch (type) {
    case 'success':
      toast.success(<ToastifyComponent message={message} />, toastifyConfig);
      break;
    case 'error':
      toast.error(<ToastifyComponent message={message} />, toastifyConfig);
      break;
    case 'info':
      toast.info(<ToastifyComponent message={message} />, toastifyConfig);
      break;
    case 'warning':
      toast.warning(<ToastifyComponent message={message} />, toastifyConfig);
      break;
    case 'default':
      toast.info(<ToastifyComponent message={message} />, toastifyConfig);
      break;
    default:
      break;
  }
};
