import { RemindOrangeSvg, RemindSvg } from '../../assets/svg-icon';
import React, { FC, useEffect, useState, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../ui/Button/Button';
import { Socials } from './Socials/Socials';
import { InfoBox } from '../../components/InfoBox/InfoBox';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import {
  getNotification,
  postNotification,
} from '../../store/redux-toolkit/settings/notification/notification-thunks';
import { getNotificationSelector } from '../../store/redux-toolkit/settings/notification/notification-selector';
import { notificationContainer } from '../../ui/notificationContainer';
import { getMetrics } from '../../store/redux-toolkit/updates/updates-thunks';
import { getMetricsSelector } from '../../store/redux-toolkit/updates/updates-selector';
import { getPeymentLink, getPeymentsInfo } from '../../store/redux-toolkit/peyments/updates-thunks';
import {
  getLoaderSelector,
  getPaymentsSelector,
} from '../../store/redux-toolkit/peyments/updates-selector';

import rocket from '../../assets/images/beta-rocket.png';
import { BakePopUp } from '../../ui/BakePopUp/BakePopUp';
import { SponsorsSlider } from '../SponsorsSlider/SponsorsSlider';
import { getBackers } from '../../store/redux-toolkit/backers/backers-thunks';
import { selectBakersState } from '../../store/redux-toolkit/backers/backers-selector';
import { useNavigate } from 'react-router-dom';
import EmblaCarousel from '../../ui/EmblaCarousel/EmblaCarousel';
import { EmblaOptionsType } from 'embla-carousel';
import BG from '../../assets/images/BG.jpg';

const OPTIONS: EmblaOptionsType = { axis: 'y' };
const SLIDE_COUNT = 5;
const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

const HeaderContent: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const notification = useAppSelector(getNotificationSelector);
  const { isAuth } = useAppSelector((store) => store.auth);
  const metrics = useAppSelector(getMetricsSelector);
  const payments = useAppSelector(getPaymentsSelector);
  const loading = useAppSelector(getLoaderSelector);
  const [open, setOpen] = useState(false);
  const [num, setNum] = useState<string>('5');
  const [amount, setAmount] = useState<string>('5');
  const { backers_platinum } = useAppSelector(selectBakersState);
  const navigate = useNavigate();

  const onGetSum = (event: ChangeEvent) => {
    const { id } = event.target as HTMLInputElement;
    setNum(id);
    setAmount(typeof +id === 'number' ? id : '1');
  };

  const onSubmit = () => {
    if (+amount < 1) {
      notificationContainer('Page.Project.number_must', 'info');
      return;
    }

    dispatch(
      getPeymentLink({
        close: setOpen,
        api: {
          amount: +amount || +num,
          currency: 'usd',
        },
      })
    );
  };

  const handleInput = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setAmount(value);
  };

  useEffect(() => {
    isAuth && dispatch(getNotification());
    dispatch(getMetrics());
    dispatch(getPeymentsInfo());
    dispatch(
      getBackers({
        show_count: 100,
        page_number: 1,
        level: 'backer_platinum',
      })
    );
  }, []);

  const onNotification = () => {
    isAuth
      ? dispatch(
          postNotification({
            receive_email_notifications: !notification?.receive_email_notifications,
          })
        )
      : notificationContainer('Page.Settings.you_need_to_log_in', 'info');
  };

  const onBackProject = () => {
    // navigate(ERoutes.sponsors);
    setOpen(true);
  };

  const onPercent = () => {
    if (payments) {
      return 100 - (payments.goal - payments.invested) / ((1 / 100) * payments.goal);
    }
  };

  const onClosePpopUp = () => {
    setOpen(false);
  };

  return (
    <section
      style={{
        background: `url(${BG})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        position: 'relative',
      }}
      id='header-content'
      className='landing-section landing-section--intro'
    >
      <div className='bg-section' />
      {open ? (
        <BakePopUp
          onSubmit={onSubmit}
          onGetSum={onGetSum}
          onClosePpopUp={onClosePpopUp}
          handleInput={handleInput}
          num={num}
          amount={amount}
          loading={loading}
        />
      ) : null}
      <div
        style={{
          position: 'relative',
          zIndex: 1,
        }}
        className='container container--big'
      >
        <h1 className='home-title home-title--playch-font'>{t('Page.Project.title')}</h1>
        <p className='home-subtitle'>{t('Page.Project.sub_title')}</p>
        <div className='intro'>
          <EmblaCarousel slides={SLIDES} options={OPTIONS} />
          <div className='intro-info'>
            <p className='intro-info__checkout'>
              {t('Page.Project.get_ready')}
              <a
                href='https://playch.tv/'
                target='_blank'
                rel='noreferrer'
                className='checkout-beta'
              >
                <span className='checkout-beta__img'>
                  <img loading='lazy' src={rocket} alt='link' />
                </span>
                <span className='checkout-beta__text'>
                  <svg
                    width='123'
                    height='10'
                    viewBox='0 0 123 10'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M0.149902 10V4.375H9.5249L8.38232 6.25H2.0249V10H0.149902ZM0.149902 2.5V0.625H8.38232L9.5249 2.5H0.149902ZM12.8579 10L11.7153 8.125H21.0903V10H12.8579ZM11.7153 6.25V0.625H13.5903V6.25H11.7153ZM30.2681 6.52832L28.9058 4.24316L25.4927 10H23.2808L28.9058 0.625L34.5308 10H32.3481L31.396 8.40332H27.6167L28.73 6.52832H30.2681ZM36.7212 10V8.125H46.0962L44.9536 10H36.7212ZM37.8638 6.25L36.7212 4.375V0.625H38.5962V4.375H44.2212V0.625H46.0962V6.25H37.8638ZM48.2866 10V4.375H50.1616V8.125H57.6616V10H48.2866ZM48.2866 2.5L49.4292 0.625H57.6616V2.5H48.2866ZM59.8521 10V0.625H61.7271V10H59.8521ZM67.3521 10V6.25H63.3384L62.1958 4.375H67.3521V0.625H69.2271V10H67.3521ZM77.1675 2.5V0.625H85.3999L86.5425 2.5H77.1675ZM84.6675 6.25H79.0425V8.125H84.6675V6.25ZM86.5425 8.125L85.3999 10H77.1675V4.375H86.5425L85.3999 6.25L86.5425 8.125ZM90.6079 10V6.25H88.7329L89.8755 4.375H95.5005L94.3579 6.25H92.4829V8.125H97.522V10H90.6079ZM88.7329 2.5L89.8755 0.625H97.3755L96.2329 2.5H88.7329ZM103.594 10V4.375H105.469V10H103.594ZM99.8442 2.5V0.625H109.219V2.5H99.8442ZM118.397 6.52832L117.035 4.24316L113.622 10H111.41L117.035 0.625L122.66 10H120.477L119.525 8.40332H115.746L116.859 6.52832H118.397Z'
                      fill='#FBFBFB'
                    />
                  </svg>
                </span>
              </a>
            </p>
            {/* <div className='landing-progress'>
              <div className='landing-progress__value' style={{ width: onPercent() || 0 }}></div>
              <button className='landing-progress__btn' style={{ left: onPercent() || 0 }}>
                <ToddlerSvg />
              </button>
            </div> */}
            {/* <div className='project-goals'>
              <div className='project-goals__price'>
                <p className='project-goals__value'>{`${
                  payments?.currency.toUpperCase() || 'USD'
                } ${payments?.invested || 0}`}</p>
                <p className='project-goals__text'>
                  {t('Page.Project.pledged_of', {
                    currency: payments?.currency.toUpperCase() || 'USD',
                    goal: payments?.goal || 0,
                  })}
                </p>
              </div>
              <div className='project-goals__backers'>
                <p className='project-goals__value'>{payments?.backers || 0}</p>
                <p className='project-goals__text'>{t('Page.Project.backers')}</p>
              </div>
            </div> */}
            <div className='intro-info__btns'>
              <Button
                onClick={onNotification}
                title={t('Page.Project.remind_me')}
                classNames='button button--full-width button--type3'
                svg={
                  notification?.receive_email_notifications ? <RemindOrangeSvg /> : <RemindSvg />
                }
              />
              <Button
                onClick={onBackProject}
                title={t('Page.Project.back_this_project')}
                classNames='button--color'
              />
            </div>
            <Socials />
            <div className='info-text info-text--type2 info-text--ta-center'>
              <p>{t('Page.Project.each_of_your_contributions')}</p>
            </div>
          </div>
        </div>
        {backers_platinum?.data.length ? (
          <SponsorsSlider backersPlatinum={backers_platinum} />
        ) : null}

        <InfoBox metrics={metrics} />
      </div>
    </section>
  );
};

export default HeaderContent;
