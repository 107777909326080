import classNames from 'classnames';
import { PopupChangeEmail } from '../../../components/Settings/Account/PopupChangeEmail/PopupChangeEmail';
import { ERoutes, LoadingStatus } from '../../../enum/types';
import { accountSchema } from '../../../form-validations/account-validation';
import { Formik, Form } from 'formik';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, Outlet, useLocation, useOutletContext } from 'react-router-dom';
import { getTokenSelector } from '../../../store/redux-toolkit/auth/app-selector';
import { changeUsername } from '../../../store/redux-toolkit/auth/auth-slice';
import {
  getLoadingStatusSelector,
  getPopupSelector,
  getProfileSelector,
} from '../../../store/redux-toolkit/settings/account/account-selector';
import { activePopup } from '../../../store/redux-toolkit/settings/account/account-slice';
import {
  deleteProfile,
  postEmail,
  postProfile,
  postUsername,
} from '../../../store/redux-toolkit/settings/account/account-thunks';
import {
  IEmailChangeSubmitValue,
  IProfileRequst,
  IUserNameChangeSubmitValue,
} from '../../../store/redux-toolkit/settings/account/account-types';
import { LoadingSmall } from '../../../ui/LoadingSmall/LoadingSmall';
import { PopupChangeUserName } from '../../../ui/PopupChangeUserName/PopupChangeUserName';
import { PopupDelete } from '../../../ui/PopupDelete/PopupDelete';

type ContextType = {
  addCountry: (value: string) => void;
  values: IProfileRequst;
  size: number;
  addDate: (value: string) => void;
  addLan: (value: string) => void;
  addAvatar: (value: string) => void;
  hideAvatar: () => void;
  setSize: (value: number) => void;
};

const AccountPage: React.FC = () => {
  const [popup, setPopup] = useState(false);
  const loadingStatus = useAppSelector(getLoadingStatusSelector);
  const { pathname } = useLocation();
  const { t } = useTranslation();
  const profile = useAppSelector(getProfileSelector);
  const popupOpen = useAppSelector(getPopupSelector);
  const dispatch = useAppDispatch();
  const token = useAppSelector(getTokenSelector);
  const { loadingState } = useAppSelector((state) => state.settingsAccount);
  const [size, setSize] = useState(0);

  const initialValues = {
    bio: profile?.bio || '',
    birth_date: profile?.birth_date || '',
    first_name: profile?.first_name || '',
    image_base64: profile?.avatar_url || '',
    language: profile?.language || '',
    last_name: profile?.last_name || '',

    address_line1: profile?.address_line_1 || '',
    address_line2: profile?.address_line_2 || '',
    city: profile?.city || '',
    country: profile?.country || '',
    postal_code: profile?.postal_code || '',
    region: profile?.region || '',
    hide_avatar: false,
  };

  const settingsSubmit = (values: IProfileRequst) => {
    const data = { ...values };
    if (data.image_base64?.includes('http://') || data.image_base64?.includes('https://'))
      data.image_base64 = '';
    dispatch(postProfile(data));
  };

  const onClosePopup = () => {
    dispatch(activePopup(''));
  };

  const changeUsernameInHeader = (name: string) => {
    dispatch(changeUsername(name));
  };

  const userNameChangeSubmit = (values: IUserNameChangeSubmitValue) => {
    dispatch(postUsername({ paramsApi: values, changeUsername: changeUsernameInHeader }));
  };

  const emailChangeSubmit = (values: IEmailChangeSubmitValue) => {
    dispatch(postEmail(values));
  };

  const onPopup = () => {
    setPopup(!popup);
  };

  return (
    <>
      {popupOpen === 'display-name' ? (
        <PopupChangeUserName
          onClosePopup={onClosePopup}
          userNameChangeSubmit={userNameChangeSubmit}
          username={profile?.username}
        />
      ) : null}
      {popupOpen === 'email-address' ? (
        <PopupChangeEmail onClosePopup={onClosePopup} emailChangeSubmit={emailChangeSubmit} />
      ) : null}
      <Formik
        initialValues={initialValues}
        validationSchema={accountSchema}
        onSubmit={(values: IProfileRequst, { setSubmitting }) => {
          settingsSubmit(values);
          setSubmitting(false);
        }}
        validateOnBlur
        enableReinitialize
      >
        {({ isSubmitting, isValid, dirty, values, setFieldValue }) => {
          const addCountry = (value: string) => {
            setFieldValue('country', value);
          };

          const addDate = (value: string) => {
            setFieldValue('birth_date', value);
          };

          const addLan = (value: string) => {
            setFieldValue('language', value);
          };

          const addAvatar = (value: string) => {
            setFieldValue('image_base64', value);
          };

          const hideAvatar = () => {
            setFieldValue('hide_avatar', true);
          };

          const onDiscard = () => {
            setFieldValue('bio', profile?.bio || '');
            setFieldValue('birth_date', profile?.birth_date || '');
            setFieldValue('first_name', profile?.first_name || '');
            setFieldValue('image_base64', profile?.avatar_url || '');
            setFieldValue('language', profile?.language || '');
            setFieldValue('last_name', profile?.last_name || '');
            setFieldValue('address_line1', profile?.address_line_1 || '');
            setFieldValue('address_line2', profile?.address_line_2 || '');
            setFieldValue('city', profile?.city || '');
            setFieldValue('country', profile?.country || '');
            setFieldValue('postal_code', profile?.postal_code || '');
            setFieldValue('region', profile?.region || '');
            setFieldValue('hide_avatar', false);
          };

          const removeUser = () => {
            dispatch(deleteProfile({ token, id: profile?.uuid || '' }));
          };

          return (
            <Form className='profile-content'>
              {popup ? (
                <PopupDelete
                  removeUser={removeUser}
                  loading={loadingState}
                  onClosePopup={onPopup}
                />
              ) : null}
              <div className='content-block'>
                <h2 className='block-title'>{t('Page.Settings.Account.title')}</h2>
                <div className='block-text'>
                  <p>{t('Page.Settings.Account.subtitle')}</p>
                </div>
              </div>
              <div className='content-tabs'>
                <div className='tabs'>
                  <div className='tabs-btn'>
                    <Link
                      className={classNames('tabs-btn__item', {
                        active: pathname.includes(ERoutes.accountInformationTab),
                      })}
                      to={ERoutes.accountInformationTab}
                    >
                      {t('Page.Settings.Account.tab_one')}
                    </Link>
                    <Link
                      className={classNames('tabs-btn__item', {
                        active: pathname.includes(ERoutes.accountAddressTab),
                      })}
                      to={ERoutes.accountAddressTab}
                    >
                      {t('Page.Settings.Account.tab_two')}
                    </Link>
                  </div>
                  <div className='tabs-content'>
                    <Outlet
                      context={{
                        addCountry,
                        values,
                        size,
                        addDate,
                        addLan,
                        addAvatar,
                        hideAvatar,
                        setSize,
                      }}
                    />
                  </div>
                </div>
              </div>
              <div className='content-block content-block--type2'>
                <div className='form-confirm'>
                  <div className='d-flex form-confirm__group'>
                    <button
                      disabled={size > 124000 || !(isValid && dirty) || isSubmitting}
                      className='button button--width-230'
                      type='submit'
                    >
                      {loadingStatus === LoadingStatus.LOADING ? (
                        <LoadingSmall />
                      ) : (
                        t('Page.Settings.btn_save')
                      )}
                    </button>
                    <button
                      onClick={onDiscard}
                      className='button button--width-230 button--type3'
                      type='button'
                    >
                      {t('Page.Settings.Account.btn_discard')}
                    </button>
                  </div>
                  <div className='d-flex form-confirm__right'>
                    <button
                      onClick={onPopup}
                      className='button button--width-230 button--delete'
                      type='button'
                    >
                      {t('Page.Settings.Account.btn_delete')}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default AccountPage;

export function useCountries() {
  return useOutletContext<ContextType>();
}
