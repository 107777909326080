import React, { FC, useEffect, useRef, useState } from 'react';

interface ImageMotionProps {
  src: string;
  alt: string;
  transition: number;
  width: number;
  height: number;
}

export const ImageMotion: FC<ImageMotionProps> = ({ width, height, src, transition, alt }) => {
  const [isVisible, setIsVisible] = useState(false);
  const imgRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect(); // від'єднуємо, коли елемент стає видимим
        }
      },
      {
        threshold: 0.1, // налаштування видимості елемента (10% видимості)
      }
    );

    if (imgRef.current) {
      observer.observe(imgRef.current);
    }

    return () => {
      if (imgRef.current) {
        observer.unobserve(imgRef.current);
      }
    };
  }, []);

  return (
    <img
      width={width}
      height={height}
      src={src}
      alt={alt}
      ref={imgRef}
      style={{
        opacity: isVisible ? 1 : 0,
        transition: `opacity ${transition}s ease-in-out`,
      }}
    />
  );
};
