import React, { useEffect } from 'react';
import { Filter } from '../../../components/MyComents/Filter';
import { MyComentsBody } from '../../../components/MyComents/MyComentsBody';
import { MyComentsHeader } from '../../../components/MyComents/MyComentsHeader';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getMyComments } from '../../../store/redux-toolkit/settings/myComments/myComments-thunks';
import { getMyCommentsSelector } from '../../../store/redux-toolkit/settings/myComments/myComments-selector';

export const MyComments: React.FC = () => {
  const dispatch = useAppDispatch();
  const myComents = useAppSelector(getMyCommentsSelector);

  useEffect(() => {
    dispatch(
      getMyComments({
        show_count: 8,
        page_number: 1,
      })
    );
  }, []);

  return (
    <div className='profile-content'>
      <div className='content-block'>
        <h2 className='block-title'>My comments</h2>
        <Filter />
        <div className='account-wrap'>
          <div className='table-block'>
            <div className='table-wrap'>
              <div className='table table--transform-767 table--account-comments'>
                <MyComentsHeader />
                <MyComentsBody />
              </div>
            </div>
          </div>
          {/* pagination */}
        </div>
      </div>
    </div>
  );
};
