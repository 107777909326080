import React, { FC } from 'react';
import { Team } from '../../components/Team/Team';

const TeamPage: FC = () => {
  return (
    <section className='landing-section landing-section--team'>
      <Team />
    </section>
  );
};

export default TeamPage;
