import React, { FC } from 'react';
import { VideoPlayer } from './VideoPlayer/VideoPlayer';
import {
  FuncSvg,
  GlobalSvg,
  MobilePlatformSvg,
  PlayerSvg,
  SlashSvg,
} from '../../../assets/svg-icon';
import { useTranslation } from 'react-i18next';
import { ESportsMatches } from '../../../components/Svg/ESportsMatches';

export const VideoPlayerInfo: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-player-section'>
      <div className='container container--main'>
        <VideoPlayer />
        <div className='project-advantages'>
          <div className='project-advantage-card '>
            <div className='d-flex project-advantage-card__icon'>
              <PlayerSvg width={'5em'} height={'5em'} />
            </div>
            <h2 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
              {t('Page.Project.video_player_PLAYCH')}
            </h2>
            <div className='info-text'>
              <p>{t('Page.Project.video_player_PLAYCH_sub')}</p>
            </div>
            <ul className='info-list'>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.video_player_PLAYCH_item_one')}
              </li>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.video_player_PLAYCH_item_two')}
              </li>
            </ul>
            <div className='info-text'>
              <p>{t('Page.Project.video_player_PLAYCH_last_sub')}</p>
            </div>
          </div>
          <div className='project-advantage-card '>
            <div className='d-flex project-advantage-card__icon'>
              <GlobalSvg width={'5em'} height={'5em'} />
            </div>
            <h2 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
              {t('Page.Project.global_content_delivery')}
            </h2>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.global_content_delivery_sub')}</p>
            </div>
          </div>
          <div className='project-advantage-card '>
            <div className='d-flex project-advantage-card__icon'>
              <MobilePlatformSvg width={'5em'} height={'5em'} />
            </div>
            <h2 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
              {t('Page.Project.mobile_platforms')}
            </h2>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.mobile_platforms_sub')}</p>
            </div>
          </div>
          <div className='project-advantage-card '>
            <div className='d-flex project-advantage-card__icon'>
              <FuncSvg width={'5em'} height={'5em'} />
            </div>
            <h2 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
              {t('Page.Project.video_recorder')}
            </h2>
            <div className='info-text info-text--type2 '>
              <p>{t('Page.Project.video_recorder_sub')}</p>
            </div>
          </div>
        </div>
        <div className='project-advantages project-advantages--full'>
          <div className='project-advantage-card '>
            <div className='d-flex project-advantage-card__icon'>
              <ESportsMatches />
            </div>
            <h2 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
              {t('Page.Project.eSports_matches')}
            </h2>
            <div className='info-text info-text--type2 '>
              <p>{t('Page.Project.eSports_matches_desc')}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
