import { ERoutes } from '../../enum/types';
import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import img1 from '../../assets/images/comment-avatar1.png';
import img2 from '../../assets/images/comment-avatar2.png';
import img3 from '../../assets/images/comment-avatar3.png';
import { MinusSvg, PlusSvg } from '../../assets/svg-icon';
import { listFaq } from '../../CONST/faq';
import classNames from 'classnames';

export const FAQ: FC = () => {
  const [active, setActive] = useState<number>(0);

  const onActive = (value: number) => {
    setActive((prev: number) => (prev === value ? 0 : value));
  };

  useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, []);

  return (
    <>
      <div className='section-cover'>
        <div className='container'>
          <div className='section-cover-title'>
            <span className='section-cover-title__label'>FAQs</span>
            <h2 className='section-cover-title__item'>Frequently Asked Questions</h2>
            <p className='section-cover-title__text'>Have questions? We’re here to help.</p>
          </div>
        </div>
      </div>
      <section className='faq-section'>
        <div className='container'>
          <div className='faq-list'>
            {listFaq.map((question) => (
              <div
                key={question.id}
                className={classNames('faq', {
                  active: active === question.id,
                })}
              >
                <div className='d-flex faq__header'>
                  <p className='faq__header-title'>Lorem ipsum dolor sit amet consectetur?</p>
                  <button
                    onClick={() => onActive(question.id)}
                    type='button'
                    className='d-flex faq__header-btn'
                  >
                    {question.id === active ? <MinusSvg /> : <PlusSvg />}
                  </button>
                </div>
                <div className='faq__text'>
                  <p>
                    Nulla nulla libero a odio quis morbi nisl. Ac eu lobortis amet vivamus quisque
                    vitae purus massa. Aliquam imperdiet odio donec venenatis lectus faucibus ut non
                    morbi. Elit sapien non volutpat et in semper egestas feugiat duis. Augue
                    adipiscing facilisis est volutpat mauris morbi malesuada quisque erat.
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
      <div className='have-questions-box'>
        <div className='container'>
          <div className='have-questions'>
            <div className='d-flex have-questions__users'>
              <img loading='lazy' src={img1} alt='avatar' />
              <img loading='lazy' src={img2} alt='avatar' />
              <img loading='lazy' src={img3} alt='avatar' />
            </div>
            <h2 className='have-questions__title'>Still have questions?</h2>
            <div className='have-questions__text'>
              <p>We love what we do here, but that’s not even the half of it.</p>
            </div>
            <Link to={ERoutes.contacts} className='button button--size2' type='button'>
              Contact Us
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};
