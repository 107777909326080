import { InformationSvg } from '../../../assets/svg-icon';
import { FieldProps } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

interface CustomInputProps {
  type?: string;
}

const Input: FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors },
  type = 'text',
  ...props
}) => {
  const { t } = useTranslation();

  return (
    <div className='input'>
      <div className='input-wrapper'>
        <input type={type} {...field} {...props} className='input-item' />
        {field.name === 'username' && (
          <div className='input-actions'>
            <div className='input-info'>
              <div className='input-icon'>
                <InformationSvg />
              </div>
              <span className='tooltip tooltip--bottom tooltip--center '>
                <span className='tooltip__text '>
                  <span>{t('Forms.Sign_up.display_name_info')}</span>
                </span>
              </span>
            </div>
          </div>
        )}
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification input-notification--error'>
          {t(String(errors[field.name]))}
        </p>
      )}
    </div>
  );
};

export default Input;
