import React, { FC } from 'react';
import { ILoginForm, ILoginSubmitValue } from './types';
import { loginSchema } from '../../../form-validations/login-validation';
import { useAppSelector } from '../../../hooks/redux';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { ReactComponent as LogoDark } from '../../../assets/images/Logo-dark.svg';
import Input from '../../../ui/Formik/Input';
import PasswordField from '../../../ui/Formik/PasswordField';
import { Link } from 'react-router-dom';
import { ERoutes, LoadingStatus } from '../../../enum/types';
import { getLoadingStatusSelector } from '../../../store/redux-toolkit/auth/app-selector';
import { LoadingSmall } from '../../../ui/LoadingSmall/LoadingSmall';

const initialValues = {
  username: '',
  password: '',
  rememberMe: '',
};

export const LoginForm: FC<ILoginForm> = ({ loginSubmit }) => {
  const { t } = useTranslation();
  const loadingStatus = useAppSelector(getLoadingStatusSelector);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={loginSchema}
      onSubmit={(values: ILoginSubmitValue, { setSubmitting }) => {
        loginSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty, values }) => {
        return (
          <Form className='form form--authorization'>
            <div className='form__logo'>
              <LogoDark width={'3em'} height={'3em'} />
            </div>
            <h2 className='form-title'>{t('Forms.Login.title_login')}</h2>
            <div className='form__body'>
              <Field
                name='username'
                className='input-item'
                type='text'
                component={Input}
                value={values.username}
                placeholder={t('Forms.Login.name')}
              />
              <Field
                className='input-item input-item--pr-90'
                name='password'
                component={PasswordField}
                value={values.password}
                placeholder={t('Forms.Login.password')}
              />
              <div className='form-options'>
                <div></div>
                {/* <Field name='rememberMe' component={CheckBox} /> */}
                <Link to={ERoutes.forgotPass} className='link'>
                  {t('Forms.Login.forgot_password')}
                </Link>
              </div>
              {/* <div className='cred-or'>
                <div className='cred-or__line'></div>
                <div className='cred-or__text'>{t('Global.or')}</div>
                <div className='cred-or__line'></div>
              </div>
              <SocialButtons /> */}
            </div>
            <div className='form-submit'>
              <button
                disabled={!(isValid && dirty) || isSubmitting}
                type='submit'
                className={`button button--full-width ${
                  loadingStatus === LoadingStatus.LOADING ? 'button--loading' : ''
                }`}
              >
                {loadingStatus === LoadingStatus.LOADING ? <LoadingSmall /> : t('Forms.Login.btn')}
              </button>
            </div>
            <div className='form__extra'>
              <p>
                <a
                  href={ERoutes.privacyPolicy}
                  target='_blank'
                  rel='noopener noreferrer'
                  className='link'
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            <div className='form__extra'>
              <p>
                {t('Forms.Login.title_sign_up')}{' '}
                <Link to={ERoutes.registration} className='link'>
                  {t('Forms.Login.btn_sign_up')}
                </Link>
              </p>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
