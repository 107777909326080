import React, { FC, useEffect } from 'react';
import playch from '../../../assets/images/project-my-playch.png';
import icon1 from '../../../assets/images/project-info-icon1.png';
import icon2 from '../../../assets/images/project-info-icon2.png';
import icon3 from '../../../assets/images/project-info-icon3.png';
import icon4 from '../../../assets/images/project-info-icon4.png';
import { useTranslation } from 'react-i18next';
import { Support } from '../Support/Support';
import { ImageMotion } from '../../../ui/ImageMotion/ImageMotion';

export const MyPlaych: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-my-playch-section'>
      <div className='container container--main'>
        <div className='project-info project-info--my-playch'>
          <div className='project-img project-img--my-playch'>
            <div className='d-flex project-img__item--my-playch'>
              <ImageMotion src={playch} height={454} width={821} alt='my-playch' transition={2} />
            </div>
          </div>
          <div className='project-text-box'>
            <div className='project-title-block'>
              <h2 className='project-title'>{t('Page.Project.my_playch_title')}</h2>
            </div>
            <div className='project-text project-text--max-with'>
              <h3 className='info-subtitle info-subtitle--size2'>
                {t('Page.Project.my_playch_sub')}
              </h3>
            </div>
          </div>
        </div>
        <div className='project-info-grid project-info-grid--my-playch'>
          <div className='project-info-card'>
            <div className='project-info-card__img'>
              <img loading='lazy' src={icon1} alt='platform' />
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.my_playch_one_card_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.my_playch_one_card_subtitle')}</p>
            </div>
          </div>
          <div className='project-info-card'>
            <div className='project-info-card__img'>
              <img loading='lazy' src={icon2} alt='platform' />
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.my_playch_two_card_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.my_playch_two_card_subtitle')}</p>
            </div>
          </div>
          <div className='project-info-card'>
            <div className='project-info-card__img'>
              <img loading='lazy' src={icon3} alt='platform' />
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.my_playch_three_card_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.my_playch_three_card_subtitle')}</p>
            </div>
          </div>
          <div className='project-info-card'>
            <div className='project-info-card__img'>
              <img loading='lazy' src={icon4} alt='platform' />
            </div>
            <h3 className='info-subtitle info-subtitle--size2'>
              {t('Page.Project.my_playch_four_card_title')}
            </h3>
            <div className='info-text info-text--type2'>
              <p>{t('Page.Project.my_playch_four_card_subtitle')}</p>
            </div>
          </div>
        </div>
        <Support />
      </div>
    </div>
  );
};
