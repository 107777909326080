import * as Yup from 'yup';

export const displayNameSchema = Yup.object().shape({
  username: Yup.string()
    .required('Validation.password_required')
    .max(25, 'Validations.password_max_length'),
  username_confirmation: Yup.string()
    .required(String('Validation.password_confirm'))
    .oneOf([Yup.ref('username'), null], String('Validation.user_name_match')),
  checkbox: Yup.bool()
    .oneOf([true], 'Validation.checkbox_required')
    .required('Validation.checkbox_required'),
});
