import React, { FC } from 'react';

export const MyComentsHeader: FC = () => {
  return (
    <div className='table-header'>
      <div className='tr'>
        <div className='td'>
          <div className='td-name'>
            <p>Date</p>
          </div>
        </div>
        <div className='td'>
          <div className='td-name'>
            <p>Comment text</p>
          </div>
        </div>
        <div className='td td--right'>
          <div className='td-name'>
            <p>Reject reason</p>
          </div>
        </div>
      </div>
    </div>
  );
};
