import { ActivitySvg, RecentlySvg, UpdateSvg } from '../../../../assets/svg-icon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ProjectCard: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-card-wrap'>
      <div className='project-card-list project-card-list--library'>
        <div className='project-card'>
          <div className='d-flex project-card__icon'>
            <UpdateSvg width={'4em'} height={'4em'} />
          </div>
          <h2 className='project-card__title'>{t('Page.Project.updates')}</h2>
          <p className='project-card__text'>{t('Page.Project.updates_sub')}</p>
        </div>
        <div className='project-card'>
          <div className='d-flex project-card__icon'>
            <RecentlySvg width={'4em'} height={'4em'} />
          </div>
          <h2 className='project-card__title'>{t('Page.Project.recent')}</h2>
          <p className='project-card__text'>{t('Page.Project.recent_sub')}</p>
        </div>
        <div className='project-card'>
          <div className='d-flex project-card__icon'>
            <ActivitySvg width={'4em'} height={'4em'} />
          </div>
          <h2 className='project-card__title'>{t('Page.Project.activity')}</h2>
          <p className='project-card__text'>{t('Page.Project.activity_sub')}</p>
        </div>
      </div>
    </div>
  );
};
