import { ChangePasswordForm } from '../../../../components/Forms/ChangePasswordForm/ChangePasswordForm';
import { useAppDispatch } from '../../../../hooks/redux';
import { IResetPass } from '../../../../pages/Auth/ResetPassword/types';
import React, { FC } from 'react';
import { changePassword } from '../../../../store/redux-toolkit/auth/auth-thunks';

export const ChangePassword: FC = () => {
  const dispatch = useAppDispatch();

  const handleChangePassSubmit = (values: IResetPass): void => {
    dispatch(changePassword({ ...values }));
  };

  return (
    <div className='content-block'>
      <ChangePasswordForm changePasswordSubmit={handleChangePassSubmit} />
    </div>
  );
};
