import React, { FC, useEffect, useRef, useState } from 'react';
import project from '../../assets/images/project-img.png';
import logo from '../../assets/images/logo-with-text.svg';
import restream from '../../assets/images/project-icon-restream.png';
import frame from '../../assets/images/platform-frame.png';
import project_latform_second from '../../assets/images/project-platform2.png';
import playoff from '../../assets/images/project-esport.png';
import { Decorate } from './Decorate/Decorate';
import { Cards } from './Cards/Cards';
import { Partner } from './Partner/Partner';
import { VideoPlayerInfo } from './VideoPlayerInfo/VideoPlayerInfo';
import { Modules } from './Modules/Modules';
import { GamesLibrarys } from './GamesLibrary/GamesLibrary';
import { PlayYourWay } from './PlayYourWay/PlayYourWay';
import { MyPlaych } from './MyPlaych/MyPlaych';
import { ThereIsMore } from './ThereIsMore/ThereIsMore';
import { useTranslation } from 'react-i18next';
import { LogoSvg } from '../../assets/svg-icon';
import { ImageMotion } from '../../ui/ImageMotion/ImageMotion';
// import { Slider } from './Slider/Slider';
// import { EmblaOptionsType } from 'embla-carousel';

// const OPTIONS: EmblaOptionsType = { axis: 'x' };
// const SLIDE_COUNT = 5;
// const SLIDES = Array.from(Array(SLIDE_COUNT).keys());

export const Project: FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <Decorate />
      <div className='container container--main '>
        <div className='project-about'>
          <h2 className='home-title home-title--project'>
            {t('Page.Project.about_title')}
            <span className='logo'>
              <LogoSvg width={'8em'} height={'2em'} />
            </span>
          </h2>
          <div className='info-subtitle info-subtitle--ta-center info-subtitle--size2 info-subtitle--width-610'>
            <p>{t('Page.Project.about_subtitle')}</p>
          </div>
          <div className='project-about__img'>
            <ImageMotion src={project} height={697} width={1310} alt='project' transition={2} />
          </div>
        </div>
        <div className='info-block info-block--width-1140 info-block--about-project'>
          <div className='info-subtitle info-subtitle--ta-center info-subtitle--size2 '>
            <p>{t('Page.Project.about_title_second')}</p>
          </div>
          <div className='info-text info-text--type2 info-text--mt-16 info-text--ta-center'>
            <p>{t('Page.Project.about_subtitle_second')}</p>
          </div>
        </div>
      </div>
      {/* <Slider slides={SLIDES} options={OPTIONS} /> */}
      <div className='project-library-section'>
        <div className='container container--main'>
          <Cards />
          <div className='project-info  project-info--esport'>
            <div className='project-img project-img--esport'>
              <div className='d-flex project-img__item'>
                <ImageMotion src={playoff} height={375} width={633} alt='esport' transition={2} />
              </div>
            </div>
            <div className='project-text-box'>
              <div className='project-title-block'>
                <h2 className='project-title'>{t('Page.Project.esports')}</h2>
              </div>
              <div className='project-text '>
                <div className='info-text'>
                  <p>{t('Page.Project.esports_desc')}</p>
                </div>
                <div className='info-text info-text--type2 info-text--mt-8'>
                  <p>{t('Page.Project.esports_desc_two')}</p>
                </div>
              </div>
            </div>
          </div>
          <div className='project-info project-info--type2 project-info--platform'>
            <div className='project-text-box'>
              <div className='project-title-block'>
                <h2 className='project-title'>{t('Page.Project.open_platform')}</h2>
                <div className='project-block-icon project-block-icon--restream'>
                  <ImageMotion
                    src={restream}
                    height={375}
                    width={633}
                    alt='headphoes'
                    transition={3}
                  />
                </div>
              </div>
              <div className='project-text project-text--max-width'>
                <h3 className='info-subtitle info-subtitle--size2 '>
                  {t('Page.Project.stream_with_the_community')}
                </h3>
                {/* <div> */}
                <div className='info-text'>
                  <p>{t('Page.Project.legendary_streamers')}</p>
                </div>
                <div className='info-text info-text--type2 info-text--mt-8'>
                  <p>{t('Page.Project.we_are_also_working')}</p>
                </div>
                {/* </div> */}
              </div>
            </div>
            <div className='project-img project-img--platform'>
              <div className='d-flex streaming-platform-image'>
                <ImageMotion
                  src={project_latform_second}
                  height={375}
                  width={633}
                  alt='platform'
                  transition={1.5}
                />
              </div>
              <div className='d-flex project-img__frame'>
                <ImageMotion src={frame} height={375} width={633} alt='platform' transition={2} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Partner />
      <VideoPlayerInfo />
      <Modules />
      <GamesLibrarys />
      <PlayYourWay />
      <MyPlaych />
      <ThereIsMore />
    </>
  );
};
