import React, { FC, MouseEvent, useRef, useState } from 'react';
import { LanguageSvg } from '../../assets/svg-icon';
import classNames from 'classnames';
import { LanguagePopup } from '../../layouts-element/Header/LanguagePopup/LanguagePopup';
import { useTranslation } from 'react-i18next';
import useOutsideClick from '../../hooks/useOutsideClick';

export const Language: FC = () => {
  const [lanPopup, setLanPopup] = useState<boolean>(false);
  const ref = useRef<HTMLDivElement | null>(null);
  const { i18n } = useTranslation();
  useOutsideClick(ref, () => setLanPopup(false));

  const onOpenPopupLan = (event: MouseEvent) => {
    const { name } = event.target as HTMLButtonElement;
    name && i18n.changeLanguage(name);
    // name && dispatch(changeAppLanguage(name));
    setLanPopup(!lanPopup);
  };

  return (
    <div
      ref={ref}
      className={classNames('lang', {
        active: lanPopup,
      })}
    >
      <button aria-label='Language' type='button' onClick={onOpenPopupLan} className='lang__btn'>
        <LanguageSvg />
      </button>
      <LanguagePopup onOpenPopupLan={onOpenPopupLan} />
    </div>
  );
};
