import { RootState } from '../../../store/rootReducer';
import { IMetrics, IPostsResponse, IUpdatesState } from './updates-types';

export const selectUpdateState = (state: RootState): IUpdatesState => state.updatesSlice;
export const getPostsSelector = (state: RootState): IPostsResponse | null =>
  selectUpdateState(state).posts;
export const getMetricsSelector = (state: RootState): IMetrics | null =>
  selectUpdateState(state).metrics;
export const getLoadingStateSelector = (state: RootState): string =>
  selectUpdateState(state).loadingState;
