import * as Yup from 'yup';

export const registerSchema = Yup.object().shape({
  email: Yup.string().required('Validation.email').email('Validation.email_format'),
  password: Yup.string()
    .required('Validation.password_required')
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, 'Validation.password_validation')
    .max(25, 'Validations.password_max_length'),
  password_confirmation: Yup.string()
    .required('Validation.password_confirmation')
    .oneOf([Yup.ref('password'), null], 'Validation.passwords_must_match'),
  captcha_code: Yup.string().required('Validation.Sign_up.captcha_code'),
  country: Yup.string().required('Validation.country'),
  first_name: Yup.string().required('Validation.first_name'),
  last_name: Yup.string().required('Validation.last_name'),
  username: Yup.string()
    .matches(/^[0-9a-zA-Z\s]+$/, 'Validation.only_latin')
    .required('Validation.username'),
  terms_of_service: Yup.bool().oneOf([true], 'Validation.accept_terms'),
  // .required('Validation.username'),
  // receive_news: Yup.bool().oneOf([true], 'Validation.cookie_policy'),
  // .required('Validation.username'),
});
