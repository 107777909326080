import React from 'react';
import { EmblaOptionsType } from 'embla-carousel';
import { DotButton, useDotButton } from './EmblaCarouselDotButton';
import { PrevButton, NextButton, usePrevNextButtons } from './EmblaCarouselArrowButtons';
import useEmblaCarousel from 'embla-carousel-react';
import intro from '../../assets/images/intro-test-new.png';
import img1 from '../../assets/images/intro-slide1.jpg';
import img2 from '../../assets/images/intro-slide2.jpg';
import img3 from '../../assets/images/intro-slide3.jpg';
import img4 from '../../assets/images/intro-slide4.jpg';
import img5 from '../../assets/images/intro-slide5.jpg';

// const webpArr = [img1_webp, img2_webp, img3_webp, img4_webp, img5_webp];

type PropType = {
  slides: number[];
  options?: EmblaOptionsType;
};

const EmblaCarousel: React.FC<PropType> = (props) => {
  const { options, slides } = props;
  const [emblaRef, emblaApi] = useEmblaCarousel(options);

  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi);

  const { prevBtnDisabled, nextBtnDisabled, onPrevButtonClick, onNextButtonClick } =
    usePrevNextButtons(emblaApi);

  return (
    <div className='intro-media embla'>
      <div className='embla__viewport swiper-container intro-slider' ref={emblaRef}>
        <div className='embla__container'>
          {[img1, img2, img3, img4, img5].map((image, index) => (
            <div key={index} className='embla__slide intro-slider-item'>
              <img loading='lazy' src={image} alt='slide' />
              <div
                style={{
                  backgroundImage: `url(${intro})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundPosition: 'center',
                }}
                className='intro-slider-item-bg'
              ></div>
            </div>
          ))}
        </div>
      </div>

      <div className='embla__controls'>
        <div className='embla__buttons'>
          <PrevButton
            btnDisabled={prevBtnDisabled}
            onClick={onPrevButtonClick}
            disabled={prevBtnDisabled}
          />
          <NextButton
            btnDisabled={nextBtnDisabled}
            onClick={onNextButtonClick}
            disabled={nextBtnDisabled}
          />
        </div>
      </div>
    </div>
  );
};

export default EmblaCarousel;
