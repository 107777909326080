import {
  ProjectFifthIconSvg,
  ProjectFirstIconSvg,
  ProjectFourthIconSvg,
  ProjectSecondIconSvg,
  ProjectSixthIconSvg,
  ProjectThirdIconSvg,
} from '../../../assets/svg-icon';

export const dataCard: Array<ICard> = [
  {
    svg: ProjectFirstIconSvg,
    title: 'Page.Project.open_platform',
    subtitle: `Page.Project.open_platform_desc`,
  },
  {
    svg: ProjectSecondIconSvg,
    title: 'Page.Project.video_player',
    subtitle: `Page.Project.video_player_desc`,
  },
  {
    svg: ProjectFifthIconSvg,
    title: 'Page.Project.modules',
    subtitle: `Page.Project.modules_desc`,
  },
  {
    svg: ProjectThirdIconSvg,
    title: 'Page.Project.game_library',
    subtitle: `Page.Project.game_library_desc`,
  },
  {
    svg: ProjectFourthIconSvg,
    title: 'Page.Project.play_in_the_cloud',
    subtitle: `Page.Project.play_in_the_cloud_desc`,
  },
  {
    svg: ProjectSixthIconSvg,
    title: 'Page.Project.my_playch',
    subtitle: `Page.Project.my_playch_desc`,
  },
];

export interface ICard {
  svg: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  title: string;
  subtitle: string;
}
