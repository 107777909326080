import React, { FC, useEffect } from 'react';
import { BackersPlatinumBlok } from './BackersPlatinumBlok/BackersPlatinumBlok';
import { BackersSilverBlok } from './BackersSilverBlok/BackersSilverBlok';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { getBackers } from '../../store/redux-toolkit/backers/backers-thunks';
import { selectBakersState } from '../../store/redux-toolkit/backers/backers-selector';
import { BackersGoldBlok } from './BackersGoldBlok/BackersGoldBlok';
import noData from '../../assets/images/no-data.png';

export const Backers: FC = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const { backers_gold, backers_platinum, backers_silver, backer_bronze, backer_generous, backer } =
    useAppSelector(selectBakersState);

  useEffect(() => {
    dispatch(
      getBackers({
        show_count: 100,
        page_number: 1,
        level: 'backer_platinum',
      })
    );
    dispatch(
      getBackers({
        show_count: 100,
        page_number: 1,
        level: 'backer_gold',
      })
    );
    dispatch(
      getBackers({
        show_count: 100,
        page_number: 1,
        level: 'backer_silver',
      })
    );
    dispatch(
      getBackers({
        show_count: 100,
        page_number: 1,
        level: 'backer_bronze',
      })
    );
    dispatch(
      getBackers({
        show_count: 100,
        page_number: 1,
        level: 'backer_generous',
      })
    );
    dispatch(
      getBackers({
        show_count: 100,
        page_number: 1,
        level: 'backer',
      })
    );
  }, []);

  return (
    <div className='container container--main'>
      <h2 className='home-title'>{t('Page.Backers.title')}</h2>
      <div className='info-text info-text--ta-center info-text--width-1090 '>
        <p>{t('Page.Backers.sub_title')}</p>
      </div>
      {backers_gold?.data.length || backers_platinum?.data.length || backers_silver?.data.length ? (
        <div className='backers-box'>
          <BackersPlatinumBlok backers_platinum={backers_platinum?.data} />
          <BackersGoldBlok backers_gold={backers_gold?.data} />
          <BackersSilverBlok
            backers_silver={[
              ...(backers_silver?.data || []),
              ...(backer_bronze?.data || []),
              ...(backer_generous?.data || []),
              ...(backer?.data || []),
            ]}
          />
        </div>
      ) : (
        <div className='plug'>
          <img loading='lazy' src={noData} alt='No Data' />
        </div>
      )}
    </div>
  );
};
