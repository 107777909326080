import React from 'react';

import { Login } from '../pages/Auth/Login';
import { ERoutes } from '../enum/types';
import SignUp from '../pages/Auth/SignUp/SignUp';
import { VerifyEmail } from '../pages/Auth/VerifyEmail/VerifyEmail';
import { ForgotPass } from '../pages/Auth/ForgotPass/ForgotPass';

import { Navigate } from 'react-router-dom';
import { TermsPage } from '../pages/TermsPage/TermsPage';
import { FAQ } from '../pages/FAQ/FAQ';

import { Successful } from '../pages/Successful/Successful';
import { Error } from '../pages/Error/Error';

import { Page404 } from '../pages/404Page/404Page';
import { BackersPage } from '../pages/BackersPage/BackersPage';
import { Sponsors } from '../pages/Sponsors/Sponsors';
import ProjectPage from '../pages/ProjectPage/ProjectPage';
import UpdatesPage from '../pages/UpdatesPage/UpdatesPage';
import CommentsPage from '../pages/CommentsPage/CommentsPage';
import TeamPage from '../pages/TeamPage/TeamPage';
import ContactUsPage from '../pages/ContactUsPage/ContactUsPage';
import ResetPassword from '../pages/Auth/ResetPassword/ResetPassword';
import PrivacyPolicy from '../pages/PrivacyPolicy/PrivacyPolicy';
import CookiePolicy from '../pages/CookiePolicy/CookiePolicy';

export const PublicRoutes = [
  {
    element: <ProjectPage />,
    path: ERoutes.project,
  },
  {
    element: (
      // <CSSTransition timeout={500} classNames='alert'>
      <UpdatesPage />
      // </CSSTransition>
    ),
    path: ERoutes.updates,
  },
  {
    element: <CommentsPage />,
    path: ERoutes.comments,
  },
  {
    element: <TeamPage />,
    path: ERoutes.team,
  },
  {
    element: <BackersPage />,
    path: ERoutes.backers,
  },
  {
    element: <ContactUsPage />,
    path: ERoutes.contacts,
  },
  {
    element: <Login />,
    path: ERoutes.login,
  },
  {
    element: <SignUp />,
    path: ERoutes.registration,
  },
  {
    element: <VerifyEmail />,
    path: ERoutes.emailVerify,
  },
  {
    element: <ForgotPass />,
    path: ERoutes.forgotPass,
  },
  {
    element: <ResetPassword />,
    path: ERoutes.resetPass,
  },
  {
    element: <TermsPage />,
    path: ERoutes.terms,
  },
  {
    element: <PrivacyPolicy />,
    path: ERoutes.privacyPolicy,
  },
  {
    element: <CookiePolicy />,
    path: ERoutes.cookiePolicy,
  },
  {
    element: <FAQ />,
    path: ERoutes.faq,
  },
  {
    element: <Successful />,
    path: ERoutes.successful,
  },
  {
    element: <Sponsors />,
    path: ERoutes.sponsors,
  },
  {
    element: <Error />,
    path: ERoutes.error,
  },
  {
    element: <Navigate to={ERoutes.project} replace />,
    path: '/',
  },
  {
    path: '*',
    element: <Page404 />,
  },
];
