import React, { ComponentPropsWithRef, useCallback, useEffect, useState } from 'react';
import { EmblaCarouselType } from 'embla-carousel';
import { ArrowLeftSvg, ArrowRightSvg } from '../../assets/svg-icon';

type UsePrevNextButtonsType = {
  prevBtnDisabled: boolean;
  nextBtnDisabled: boolean;
  onPrevButtonClick: () => void;
  onNextButtonClick: () => void;
};

export const usePrevNextButtons = (
  emblaApi: EmblaCarouselType | undefined
): UsePrevNextButtonsType => {
  const [prevBtnDisabled, setPrevBtnDisabled] = useState(true);
  const [nextBtnDisabled, setNextBtnDisabled] = useState(true);

  const onPrevButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollPrev();
  }, [emblaApi]);

  const onNextButtonClick = useCallback(() => {
    if (!emblaApi) return;
    emblaApi.scrollNext();
  }, [emblaApi]);

  const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
    setPrevBtnDisabled(!emblaApi.canScrollPrev());
    setNextBtnDisabled(!emblaApi.canScrollNext());
  }, []);

  useEffect(() => {
    if (!emblaApi) return;
    onSelect(emblaApi);
    emblaApi.on('reInit', onSelect).on('select', onSelect);
  }, [emblaApi, onSelect]);

  return {
    prevBtnDisabled,
    nextBtnDisabled,
    onPrevButtonClick,
    onNextButtonClick,
  };
};

interface Props {
  btnDisabled: boolean;
}

type PropType = ComponentPropsWithRef<'button'> & Props;

export const PrevButton: React.FC<PropType> = (props) => {
  const { children, btnDisabled, ...restProps } = props;

  return (
    <button
      className={`slider-button slider-button--next ${
        btnDisabled ? 'swiper-button-disabled' : null
      }`}
      type='button'
      aria-label='Slider Previous Button'
      {...restProps}
    >
      <ArrowLeftSvg />
      {children}
    </button>
  );
};

export const NextButton: React.FC<PropType> = (props) => {
  const { children, btnDisabled, ...restProps } = props;

  return (
    <button
      className={`slider-button slider-button--next ${
        btnDisabled ? 'swiper-button-disabled' : null
      }`}
      type='button'
      aria-label='Slide Next Button'
      {...restProps}
    >
      <ArrowRightSvg />
      {children}
    </button>
  );
};
