import React, { FC } from 'react';
import player from '../../../../assets/images/player-frame.png';
import playerAnimate from '../../../../assets/images/player-animate-frame.png';
import { useTranslation } from 'react-i18next';
import { ImageMotion } from '../../../../ui/ImageMotion/ImageMotion';

export const VideoPlayer: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-info project-info--player'>
      <div className='project-img project-img--player'>
        <div className='d-flex project-img__item-custom-new project-img__item--player'>
          <ImageMotion src={player} height={375} width={633} alt='player' transition={2} />
        </div>
      </div>
      <div className='project-text-box'>
        <h2 className='project-title'>{t('Page.Project.video')}</h2>
        <div className='player-animate-row wow animate__animated animate__fadeInRight'>
          <img loading='lazy' src={playerAnimate} alt='player' />
        </div>
        <div className='project-text'>
          <h3 className='info-subtitle info-subtitle--size2 '>{t('Page.Project.video_title')}</h3>
          <div className='info-text info-text--type2 info-text--mt-8'>
            <p>{t('Page.Project.video_subtitle')}</p>
          </div>
        </div>
      </div>
    </div>
  );
};
