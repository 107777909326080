import { ArrowDownSvg } from '../../assets/svg-icon';
import classNames from 'classnames';
import { FieldProps } from 'formik';
import useOutsideClick from '../../hooks/useOutsideClick';
import React, { FC, useEffect, useState, useRef, ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { cutString } from '../../utils/cutString';
import { ReactCountryFlag } from 'react-country-flag';
import countris from '../../CONST/countris.json';

interface ICountry {
  value: string;
  label: string;
}

interface CustomInputProps {
  type?: string;
  addCountry: (value: string) => void;
  country?: string;
}

export const CountrySelect: FC<CustomInputProps & FieldProps> = ({
  field,
  form: { touched, errors },
  type = 'text',
  addCountry,
  country,
  ...props
}) => {
  const { t } = useTranslation();
  const [countries, setCountries] = useState<Array<ICountry>>(countris.countries);
  const [countriesDefault, setCountriesDefault] = useState<Array<ICountry>>(countris.countries);
  const [text, setText] = useState<string>(country || '');
  const ref = useRef<HTMLDivElement | null>(null);
  const [onOpen, setOnOpen] = useState<boolean>(false);
  const [selectedCountry, setSelectedCountry] = useState<ICountry>({
    value: '',
    label: '',
  });

  useOutsideClick(ref, () => setOnOpen(false));

  const handleSelect = (country: ICountry) => {
    setSelectedCountry(country);
    setOnOpen(false);
    setText(cutString(country.label, 4, country.label.length));
    addCountry(cutString(country.label, 4, country.label.length));
  };

  const onInput = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setText(value);
    addCountry(value);
    setCountries(
      countriesDefault.filter(
        (country) =>
          country.label.slice(4).trim().toLowerCase().slice(0, value.toLowerCase().length) ===
          value.toLowerCase()
      )
    );
    setOnOpen(true);
  };

  // useEffect(() => {
  //   fetch('https://valid.layercode.workers.dev/list/countries?format=select&flags=true&value=code')
  //     .then((response) => response.json())
  //     .then((data) => {
  //       setCountries(data.countries);
  //       setCountriesDefault(data.countries);
  //       // setSelectedCountry(data.userSelectValue);
  //     });
  // }, []);

  return (
    <div className='select-block'>
      <div ref={ref} className={classNames('select select--input-type', { active: onOpen })}>
        <div className='select__current'>
          <input
            type={type}
            {...field}
            {...props}
            onFocus={() => setOnOpen(true)}
            onChange={onInput}
            name='country'
            className='input-item'
            value={text}
            placeholder={t('Forms.Sign_up.country') || ''}
          />
          <span className='select__current-arrow pointer-events'>
            <ArrowDownSvg />
          </span>
        </div>
        <div className='select__drop'>
          <div className='select__drop-scroll'>
            <div className='select__drop-item'>
              <ul>
                {countries.map((country) => (
                  <li className='countries-list' key={country.label}>
                    <button onClick={() => handleSelect(country)} type='button'>
                      <li
                        style={{
                          fontSize: '1.5rem',
                          padding: '10px',
                          listStyle: 'none',
                          display: 'flex',
                          alignItems: 'center',
                        }}
                      >
                        <ReactCountryFlag
                          countryCode={country.value}
                          svg
                          style={{ fontSize: '2rem', marginRight: '10px' }}
                        />
                      </li>
                      {cutString(country.label, 4, country.label.length)}
                    </button>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {touched[field.name] && errors[field.name] && (
        <p className='input-notification input-notification--error'>
          {t(String(errors[field.name]))}
        </p>
      )}
    </div>
  );
};
