import { createHeadlessEditor } from '@lexical/headless';
import { $generateHtmlFromNodes } from '@lexical/html';
import { Klass, LexicalNode } from 'lexical';
import { AutoLinkNode, LinkNode } from '@lexical/link';
import { ListItemNode, ListNode } from '@lexical/list';
import { TextNodeWithOffset } from '@lexical/text';
import { OverflowNode } from '@lexical/overflow';
import { HorizontalRuleNode } from '@lexical/react/LexicalHorizontalRuleNode';
import { HeadingNode, QuoteNode } from '@lexical/rich-text';
import { TableCellNode, TableNode, TableRowNode } from '@lexical/table';
import { CodeHighlightNode, CodeNode } from '@lexical/code';
import { ImageNode } from '../nodes/ImageNode';

export const defaultEditorNodes: Array<Klass<LexicalNode>> = [
  HeadingNode,
  QuoteNode,
  ListNode,
  ListItemNode,
  OverflowNode,
  HorizontalRuleNode,
  TableNode,
  TableCellNode,
  TableRowNode,
  CodeHighlightNode,
  CodeNode,
  ImageNode,
  LinkNode,
  AutoLinkNode,
];

export function getHTMLFromEditorJSONString(editorState: string | null) {
  if (!editorState) {
    return '<h1>Editor State not found!</h1>';
  }

  const editor = createHeadlessEditor({
    nodes: defaultEditorNodes,
    onError: (err) => console.log(err),
  });

  editor.setEditorState(editor.parseEditorState(editorState));

  let html = '';
  editor.getEditorState().read(() => {
    html = $generateHtmlFromNodes(editor);
  });

  return html;
}
