import React, { FC, RefObject, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import Input from '../../../ui/Formik/Input';
import { IContactUsRequest } from '../../../store/redux-toolkit/contactUs/contactUs-types';
import { CaptchaWrapper } from '../SignUpForm/CaptchaWrapper/CaptchaWrapper';
import { TextareaField } from '../../../ui/Formik/TextareaField/TextareaField';
import { contactUsSchema } from '../../../form-validations/contact-us-validation';
import { getLoadingStatusSelector } from '../../../store/redux-toolkit/contactUs/contactUs-selector';
import { useAppSelector } from '../../../hooks/redux';
import { LoadingSmall } from '../../../ui/LoadingSmall/LoadingSmall';
import { LoadingStatus } from '../../../enum/types';
import type HCaptcha from '@hcaptcha/react-hcaptcha';

const initialValues = {
  captcha_code: '',
  content: '',
  email: '',
  name: '',
};

interface IProps {
  loginSubmit: (
    values: IContactUsRequest,
    resetForm: () => void,
    captchaRef: RefObject<HCaptcha> | null
  ) => void;
}

export const ContactUsForm: FC<IProps> = ({ loginSubmit }) => {
  const { t } = useTranslation();
  const loadingStatus = useAppSelector(getLoadingStatusSelector);
  const captchaRef = useRef<HCaptcha>(null);

  const [isClient, setIsClient] = useState(false);

  useEffect(() => {
    setIsClient(true);
  }, []);

  if (!isClient) {
    return null; // Запобігає рендерингу на сервері
  }

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={contactUsSchema}
      onSubmit={(values: IContactUsRequest, { setSubmitting, resetForm }) => {
        loginSubmit(values, resetForm, captchaRef);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty, setFieldValue, errors, values }) => {
        const onActiveCaptcha = (value: string) => {
          value && setFieldValue('captcha_code', value);
        };

        return (
          <Form className='form form--contact-us'>
            <h2 className='form-title form-title--ta-left form-title--big'>
              {t('Page.Contact_us.title')}
            </h2>
            <div className='form-text'>
              <p>{t('Page.Contact_us.description')}</p>
            </div>
            <div className='form__body form__body--type2'>
              <div className='input'>
                <p className='input-name'>{t('Page.Contact_us.name_text')}</p>
                <div className='input-wrapper'>
                  <Field
                    name='name'
                    className='input-item'
                    type='text'
                    placeholder={t('Page.Contact_us.name_text')}
                    component={Input}
                  />
                </div>
              </div>
              <div className='input'>
                <p className='input-name'>{t('Page.Contact_us.email_address_text')}</p>
                <div className='input-wrapper'>
                  <Field
                    name='email'
                    className='input-item'
                    type='email'
                    placeholder={t('Page.Contact_us.email_address_text')}
                    component={Input}
                  />
                </div>
              </div>
              <TextareaField
                name='content'
                label='Page.Contact_us.message_text'
                placeholder='Message'
              />
              <CaptchaWrapper
                onActiveCaptcha={onActiveCaptcha}
                errorCaptcha={errors.captcha_code}
                captchaRef={captchaRef}
              />
            </div>
            <div className='form-submit form-submit--end form-submit-custom'>
              <button
                disabled={!(isValid && dirty) || isSubmitting}
                className='button button--width-190 '
                type='submit'
              >
                {loadingStatus === LoadingStatus.LOADING ? (
                  <LoadingSmall />
                ) : (
                  t('Page.Contact_us.send_message')
                )}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
