import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../enum/types';
import { ELevel } from '../../../enum/levels';
import { getBackers } from './backers-thunks';
import { IBackersResponse, IBackersState } from './backers-types';

const initialState: IBackersState = {
  loadingState: LoadingStatus.NEVER,
  backers_platinum: null,
  backers_gold: null,
  backers_silver: null,
  backer_bronze: null,
  backer_generous: null,
  backer: null,
  success: '',
  error: '',
  errors: null,
};

const backersSlice = createSlice({
  name: 'backers',
  initialState,
  reducers: {
    resetBackersState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getBackers.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getBackers.fulfilled, (state, { payload }: PayloadAction<IBackersResponse>) => {
        state.loadingState = LoadingStatus.SUCCESS;

        switch (payload?.data[0]?.level) {
          case ELevel.LEVEL_BAKCER_PLATINUM:
            state.backers_platinum = payload;
            break;
          case ELevel.LEVEL_BAKCER_GOLD:
            state.backers_gold = payload;
            break;
          case ELevel.LEVEL_BAKCER_SILVER:
            state.backers_silver = payload;
            break;
          case ELevel.LEVEL_BAKCER_GENEROUS:
            state.backer_generous = payload;
            break;
          case ELevel.LEVEL_BAKCER_BRONZE:
            state.backer_bronze = payload;
            break;
          case ELevel.LEVEL_BAKCER:
            state.backer = payload;
            break;
          default:
            break;
        }
      })
      .addCase(getBackers.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      });
  },
});

export const { resetBackersState, clearErrors, clearSuccess } = backersSlice.actions;
export default backersSlice.reducer;
