import {
  EquipmentSvg,
  LightModuleSvg,
  ModuleSvg,
  NewsSvg,
  SocialSvg,
} from '../../../../assets/svg-icon';
import { CreateOrganize } from '../../../../components/Svg/CreateOrganize';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const ModulesList: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-modules-list'>
      <div className='project-module'>
        <div className='d-flex project-module__icon'>
          <CreateOrganize />
        </div>
        <h3 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
          {t('Page.Project.modules_create')}
        </h3>
        <div className='info-text info-text--type2'>
          <p>{t('Page.Project.modules_create_desc')}</p>
        </div>
      </div>
      <div className='project-module'>
        <div className='d-flex project-module__icon'>
          <ModuleSvg width={'4em'} height={'4em'} />
        </div>
        <h3 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
          {t('Page.Project.modules_card_one_title')}
        </h3>
        <div className='info-text info-text--type2'>
          <p>{t('Page.Project.modules_card_one_subtitle')}</p>
        </div>
      </div>
      <div className='project-module'>
        <div className='d-flex project-module__icon'>
          <LightModuleSvg width={'4em'} height={'4em'} />
        </div>
        <h3 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
          {t('Page.Project.modules_card_two_title')}
        </h3>
        <div className='info-text info-text--type2'>
          <p>{t('Page.Project.modules_card_two_subtitle')}</p>
        </div>
      </div>
      <div className='project-module'>
        <div className='d-flex project-module__icon'>
          <EquipmentSvg width={'4em'} height={'4em'} />
        </div>
        <h3 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
          {t('Page.Project.modules_card_three_title')}
        </h3>
        <div className='info-text info-text--type2'>
          <p>{t('Page.Project.modules_card_three_subtitle')}</p>
        </div>
      </div>
      <div className='project-module'>
        <div className='d-flex project-module__icon'>
          <NewsSvg width={'4em'} height={'4em'} />
        </div>
        <h3 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
          {t('Page.Project.modules_card_four_title')}
        </h3>
        <div className='info-text info-text--type2'>
          <p>{t('Page.Project.modules_card_four_subtitle')}</p>
        </div>
      </div>
      <div className='project-module'>
        <div className='d-flex project-module__icon'>
          <SocialSvg width={'4em'} height={'4em'} />
        </div>
        <h3 className='info-subtitle info-subtitle--size2 info-subtitle--full-w'>
          {t('Page.Project.modules_card_five_title')}
        </h3>
        <div className='info-text info-text--type2'>
          <p>{t('Page.Project.modules_card_five_subtitle')}</p>
        </div>
      </div>
      {/* <div className='project-module project-module--plug'>
        <div className='project-module__plug'>
          <SlashBigSvg />
        </div>
      </div> */}
    </div>
  );
};
