import * as Yup from 'yup';
// import { Buffer } from 'buffer';
// import { getSizeBase64 } from '../utils/getSizeBase64';
export const accountSchema = Yup.object().shape({
  bio: Yup.string(),
  // birth_date: Yup.string().required('Validation.this_field_is_required'),
  first_name: Yup.string().required('Validation.this_field_is_required'),
  //@ts-ignore
  image_base64: Yup.string(),
  // .test('fileSize', 'Your image is too big', (value: any) => {
  //   console.log(getSizeBase64(Buffer.from(value, 'base64').length));
  //   return getSizeBase64(Buffer.from(value, 'base64').length) <= 124000;
  // })
  language: Yup.string(),
  last_name: Yup.string().required('Validation.this_field_is_required'),

  address_line1: Yup.string(),
  address_line2: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  postal_code: Yup.string(),
  region: Yup.string(),
});

export const accountAddressSchema = Yup.object().shape({
  address_line1: Yup.string(),
  address_line2: Yup.string(),
  city: Yup.string(),
  country: Yup.string(),
  postal_code: Yup.string(),
  region: Yup.string(),
});
