import React, { FC } from 'react';
import { Updates } from '../../components/Updates/Updates';

const UpdatesPage: FC = () => {
  return (
    <section className='landing-section landing-section--updates'>
      <Updates />
    </section>
  );
};

export default UpdatesPage;
