import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAppState } from './app-types';

const initialState: IAppState = {
  lang: 'en',
  isBurger: false,
  isFirstBurger: false,
};

const appSlice = createSlice({
  name: '@@app',
  initialState,
  reducers: {
    changeAppLanguage: (state, { payload }: PayloadAction<string>) => {
      state.lang = payload;
    },
    setBurger: (state, { payload }: PayloadAction<boolean>) => {
      state.isBurger = payload;
    },
    setFirstBurger: (state, { payload }: PayloadAction<boolean>) => {
      state.isFirstBurger = payload;
    },
  },
});

export default appSlice.reducer;
export const { changeAppLanguage, setBurger, setFirstBurger } = appSlice.actions;
