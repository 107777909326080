import React, { FC } from 'react';
import icon from '../../../assets/images/project-extra-icon.png';
import { useTranslation } from 'react-i18next';
// import { Link } from 'react-router-dom';
// import { ERoutes } from 'enum/types';

export const ThereIsMore: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-extra-section'>
      <div className='project-extra-icon'>
        <img loading='lazy' src={icon} alt='hand-icon' />
      </div>
      <div className='container container--big'>
        <div className='project-extra-info'>
          <h2 className='project-title'>{t('Page.Project.there_is_more')}</h2>
          <h3 className='info-subtitle info-subtitle--size2'>
            {t('Page.Project.we_are_creating')}
          </h3>
          {/* <div className='info-text info-text--type2'>
            <p>
              {t('Page.Project.on_the')}
              <Link to={ERoutes.updates}>{t('Page.Project.update_orange')}</Link>
              {t('Page.Project.there_is_more_desc')}
            </p>
          </div> */}
        </div>
      </div>
      <div className='project-extra-line'>
        <div className='container container--big'>
          <div className='project-extra-banner'>
            <p>
              {t('Page.Project.we_want_PLAYCH')}
              ❤️️
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
