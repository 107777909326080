import { Middleware } from '@reduxjs/toolkit';

export const syncWithLocalStorage: Middleware = (store) => (next) => (action) => {
  const result = next(action);
  if (action.type === 'auth/login/fulfilled') {
    localStorage.setItem('token', store.getState().auth.token);
  }
  if (action.type === 'auth/logout/fulfilled' || action.type === 'auth/resetAuthState') {
    localStorage.removeItem('token');
  }
  return result;
};
