import React, { FC } from 'react';
import avatar1 from '../../../assets/images/comment-avatar1.png';
import avatar2 from '../../../assets/images/comment-avatar2.png';
import avatar3 from '../../../assets/images/comment-avatar3.png';
import { ERoutes } from '../../../enum/types';
import { Link } from 'react-router-dom';

export const HeaderLogin: FC = () => {
  return (
    <div className='landing-comment-form'>
      <div className='d-flex landing-comment-form__users'>
        <img loading='lazy' src={avatar1} alt='avatar' />
        <img loading='lazy' src={avatar2} alt='avatar' />
        <img loading='lazy' src={avatar3} alt='avatar' />
      </div>
      <p>Only backers can post comments.</p>
      <div className='d-flex landing-comment-form__btn'>
        <Link className='button button--size2' to={ERoutes.login}>
          Log in
        </Link>
      </div>
    </div>
  );
};
