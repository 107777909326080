import React, { FC } from 'react';
import { TableBody } from './TableBody/TableBody';
import { TableHeader } from './TableHeader/TableHeader';
import { IPayments } from '../../store/redux-toolkit/settings/transactions/transactions-types';

interface IProps {
  onOpenDesc: (id: number) => void;
  openDesc: number;
  transactions: Array<IPayments> | null;
}

export const Transactions: FC<IProps> = ({ onOpenDesc, openDesc, transactions }) => {
  return (
    <div className='account-wrap'>
      <div className='table-block'>
        <div className='table-wrap'>
          <div className='table table--transform-767 table--account-transactions'>
            <TableHeader />
            <TableBody onOpenDesc={onOpenDesc} openDesc={openDesc} transactions={transactions} />
          </div>
        </div>
      </div>
      {/* {item.sub_comments_count > 5 && item.uuid === id && (
        <Pagination
          pageCount={Math.ceil(item.sub_comments_count / 5)}
          forcePage={currentPage}
          onPageChange={onSetPage}
        />
      )} */}
    </div>
  );
};
