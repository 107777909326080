import React, { FC, useEffect } from 'react';
import { CloseToastifySvg, LogoSvg } from '../../assets/svg-icon';
import { LoadingStatus } from '../../enum/types';
import { LoadingSmall } from '../LoadingSmall/LoadingSmall';
import { useTranslation } from 'react-i18next';

interface IProps {
  onClosePopup: () => void;
  loading: string;
  onChangeButton: () => void;
  title: string;
  subtitle?: string;
  firstNameBtn: string;
  secondNameBtn: string;
  image?: string;
}

export const Popup: FC<IProps> = ({
  onChangeButton,
  loading,
  onClosePopup,
  title,
  subtitle,
  firstNameBtn,
  secondNameBtn,
  image,
}) => {
  const { t } = useTranslation();

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <div className='popup-window'>
      <div className='popup-window__inside'>
        <div className='popup'>
          {/* <button type='button' aria-label='Close' onClick={onClosePopup} className='popup__close'>
            <CloseToastifySvg />
          </button> */}
          <div className='popup-header-logo'>
            <img loading='lazy' src={image} alt='logo' />
          </div>
          <div className='popup-header'>
            <h2 className='popup-title'>{t(title)}</h2>
          </div>
          {subtitle ? (
            <div className='popup-body'>
              <div className='popup-text '>
                <p style={{ textAlign: 'center' }}>{subtitle}</p>
              </div>
            </div>
          ) : null}
          <div className='popup-footer'>
            <div className='form-submit form-submit--mob-column'>
              <button
                onClick={onClosePopup}
                className='button button--type3 button--full-width'
                type='button'
              >
                {t(firstNameBtn)}
              </button>
              <button onClick={onChangeButton} className='button button--full-width' type='button'>
                {loading === LoadingStatus.LOADING ? <LoadingSmall /> : t(secondNameBtn)}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
