import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ESuccess, LoadingStatus } from '../../../enum/types';
import { IAuthState, ILoginResponse, IRefresh, IResponse } from './auth-types';

import {
  login,
  registration,
  logout,
  confirmEmail,
  forgotPassword,
  changePassword,
  refreshToken,
  resetPassword,
} from './auth-thunks';

const initialState: IAuthState = {
  user: null,
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: null,
  isAuth: false,
  token: null,
  refresh_token: null,
  registerData: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuthState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
    resetRegisterState: (state) => {
      state.registerData = undefined;
    },
    changeUsername: (state, { payload }: PayloadAction<string>) => {
      if (state.user) state.user.username = payload;
    },
    changeEmail: (state, { payload }: PayloadAction<string>) => {
      if (state.user) state.user.email = payload;
    },
    changeAvatar: (state, { payload }: PayloadAction<string>) => {
      if (state.user) state.user.avatar_url = payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(login.fulfilled, (state, { payload }: PayloadAction<ILoginResponse>) => {
        state.user = payload.data;
        state.isAuth = true;
        state.token = payload.data.auth_token;
        state.loadingState = LoadingStatus.LOADED;
        state.refresh_token = payload.data.refresh_token;
      })
      .addCase(login.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      })
      .addCase(refreshToken.fulfilled, (state, { payload }: PayloadAction<IRefresh>) => {
        state.token = payload.auth_token;
        state.refresh_token = payload.refresh_token;
      })
      .addCase(refreshToken.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      })
      .addCase(registration.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(registration.fulfilled, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.LOADED;
        state.registerData = payload;
      })
      .addCase(registration.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(forgotPassword.fulfilled, (state, { payload }: PayloadAction<IResponse>) => {
        state.loadingState = LoadingStatus.LOADED;
        state.success = payload ? ESuccess.forgot : '';
      })
      .addCase(forgotPassword.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(confirmEmail.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(confirmEmail.fulfilled, (state) => {
        state.loadingState = LoadingStatus.LOADED;
        state.registerData = undefined;
        state.success = 'email-confirm';
      })
      .addCase(confirmEmail.rejected, (state, { payload }: PayloadAction<any>) => {
        state.error = payload;
      })
      .addCase(resetPassword.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(resetPassword.fulfilled, (state, { payload }: PayloadAction<IResponse>) => {
        state.loadingState = LoadingStatus.LOADED;
        state.success = payload ? ESuccess.reset : '';
      })
      .addCase(changePassword.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(changePassword.fulfilled, (state, { payload }: PayloadAction<IResponse>) => {
        state.loadingState = LoadingStatus.LOADED;
      })
      .addCase(logout.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(logout.fulfilled, (state) => {
        state.success = 'logout-confirmed';
        state.loadingState = LoadingStatus.LOADED;
      })
      .addCase(logout.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.error = payload;
      });
  },
});

export const {
  resetAuthState,
  clearErrors,
  resetRegisterState,
  clearSuccess,
  changeUsername,
  changeEmail,
  changeAvatar,
} = authSlice.actions;
export default authSlice.reducer;
