import { ChangeEvent, FC, MouseEvent } from 'react';
import avatar3 from '../../../assets/images/comment-avatar3.png';
import logoDark from '../../../assets/images/Logo-dark.svg';

interface IProps {
  comment: string;
  onSubmitComment: (event: any) => void;
  onCreateComment: (event: ChangeEvent) => void;
  clearInput: () => void;
  avatar: string | undefined;
  uuid?: string;
}

export const CreateComments: FC<IProps> = ({
  comment,
  onCreateComment,
  onSubmitComment,
  clearInput,
  avatar,
  uuid,
}) => {
  return (
    <div className='landing-comment-form landing-comment-form--registered'>
      <div className='d-flex landing-comment-form__my-avatar'>
        <div className='my-avatar my-avatar--size-56'>
          <div className='d-flex my-avatar__inner'>
            <img loading='lazy' src={avatar || logoDark} alt='avatar' />
          </div>
        </div>
      </div>
      <div className='landing-comment-form-item'>
        <div className='input input--mb-0'>
          <div className='textarea textarea--landing-comment'>
            <textarea
              onChange={onCreateComment}
              value={comment}
              placeholder='Enter your comment'
            ></textarea>
            <span className='symbol-counter'>{`${comment.length}/2000`}</span>
          </div>
        </div>
        <div className='form-submit form-submit--end'>
          <button
            id={uuid || ''}
            disabled={!comment}
            onClick={(event: MouseEvent) => onSubmitComment(event)}
            className='button button--size2'
            type='button'
          >
            Post
          </button>
        </div>
      </div>
    </div>
  );
};
