import React, { FC, useEffect } from 'react';
import library from '../../../assets/images/project-library.png';
import title from '../../../assets/images/project-title-icon3.png';
import cloud from '../../../assets/images/project-cloud.png';
import { ProjectCard } from './ProjectCard/ProjectCard';
import { useTranslation } from 'react-i18next';
import { ImageMotion } from '../../../ui/ImageMotion/ImageMotion';

export const GamesLibrarys: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-library-section'>
      <div className='container container--main'>
        <div className='project-info project-info--library'>
          <div className='project-img project-img--library'>
            <div className='d-flex project-img__item--games-library'>
              <ImageMotion src={library} height={454} width={821} alt='cloud' transition={2} />
            </div>
            <div className='project-title-icon project-title-icon--library'>
              <img loading='lazy' src={title} alt='icon' />
            </div>
          </div>
          <div className='project-text-box'>
            <div className='project-title-block'>
              <h2 className='project-title'>{t('Page.Project.games_library')}</h2>
            </div>
            <div className='project-text project-text--max-with'>
              <h3 className='info-subtitle info-subtitle--size2'>
                {t('Page.Project.games_library_sub')}
              </h3>
              <div className='info-text info-text--type2 info-text--mt-16'>
                <p>{t('Page.Project.games_library_sub_2')}</p>
              </div>
            </div>
          </div>
        </div>
        <ProjectCard />
        <div className='project-info project-info--type2 project-info--cloud'>
          <div className='project-text-box'>
            <div className='project-title-block'>
              <h2 className='project-title'>{t('Page.Project.play_cloud')}</h2>
            </div>
            <div className='project-text project-text--max-with'>
              <h3 className='info-subtitle info-subtitle--size2'>
                {t('Page.Project.play_cloud_sub')}
              </h3>
              <div className='info-text info-text--type2 info-text--mt-16'>
                <p>{t('Page.Project.play_cloud_sub_2')}</p>
              </div>
            </div>
          </div>
          <div className='project-img project-img--cloud'>
            <div className='d-flex project-img__item--play-in-the-cloud'>
              <ImageMotion src={cloud} height={435} width={788} alt='cloud' transition={2} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
