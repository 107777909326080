import { DiamondOrangeSvg, HeartRedSvg, ReplySvg, StarGreenSvg } from '../../assets/svg-icon';
import { useAppDispatch, useAppSelector } from '../../hooks/redux';
import { ChangeEvent, FC, useEffect, useState } from 'react';
import {
  getDataUserSelector,
  getIsAuthSelector,
} from '../../store/redux-toolkit/auth/app-selector';
import logoDark from '../../assets/images/Logo-dark.svg';
import { CreateComments } from './CreateComments/CreateComments';
import { HeaderLogin } from './HeaderLogin/HeaderLogin';
import {
  getComments,
  getSubComments,
  postComments,
} from '../../store/redux-toolkit/comments/comments-thunks';
import { getCommentsSelector } from '../../store/redux-toolkit/comments/comments-selector';
import moment from 'moment';
import Pagination from '../../ui/Pagination';
import { Loader } from '../../ui/Loader';

export const Comments: FC = () => {
  const [comment, setComment] = useState<string>('');
  const [subComment, setSubComment] = useState<string>('');
  const [openSubComment, setOpenSubComment] = useState<number>(0);
  const [prevId, setPrevId] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentOwnPage, setCurrentOwnPage] = useState<number>(1);
  const [id, setId] = useState<string>('');
  const [openPagin, setOpenPagin] = useState<string>('');
  const isAuth = useAppSelector(getIsAuthSelector);
  const user = useAppSelector(getDataUserSelector);
  const comments = useAppSelector(getCommentsSelector);
  const dispatch = useAppDispatch();
  const [hide, setHide] = useState<boolean>(false);

  const onCreateComment = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    value.length <= 2000 && setComment(value);
  };

  const onCreateSubComment = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    value.length <= 2000 && setSubComment(value);
  };

  const onSubmitComment = (event: MouseEvent) => {
    dispatch(
      postComments({
        content: comment,
        parent_comment_uuid: '',
      })
    );
    setComment('');
  };

  const onSubmitSubComment = (event: MouseEvent) => {
    const { id } = event?.target as HTMLInputElement;

    dispatch(
      postComments({
        content: subComment,
        parent_comment_uuid: id,
        published: true,
      })
    );

    setSubComment('');
  };

  const clearInput = () => {
    setComment('');
  };

  const clearSubInput = () => {
    setSubComment('');
  };

  useEffect(() => {
    dispatch(
      getComments({
        show_count: 5,
        page_number: currentOwnPage,
      })
    );
  }, [currentOwnPage]);

  const onOpenSubComments = (id: number) => {
    setPrevId(id);
    setOpenSubComment(id === prevId && openSubComment !== 0 ? 0 : id);
  };

  const moreSubComments = (uuid: string) => {
    setId(uuid);
    setHide(true);
    dispatch(
      getSubComments({
        commentUUID: uuid,
        page_number: currentPage,
        show_count: 5,
      })
    );
  };

  const onSetPage = (num: number) => {
    setOpenPagin(id);
    setCurrentPage(num);
    dispatch(
      getSubComments({
        commentUUID: id,
        page_number: num,
        show_count: 5,
      })
    );
  };

  const onSetMainPage = (num: number) => {
    setCurrentOwnPage(num);
  };

  const onCommentator = (key: string) => {
    switch (key.toLowerCase()) {
      case 'creator':
        return <DiamondOrangeSvg />;

      case 'follower':
        return <HeartRedSvg />;

      case 'collaborator':
        return <StarGreenSvg />;

      default:
        return <></>;
    }
  };

  return (
    <div className='container'>
      {isAuth ? (
        <CreateComments
          comment={comment}
          onCreateComment={onCreateComment}
          onSubmitComment={onSubmitComment}
          clearInput={clearInput}
          avatar={user?.avatar_url}
        />
      ) : (
        <HeaderLogin />
      )}

      {comments ? (
        <div className='landing-comments-list'>
          {comments?.data?.comments?.map((item) => (
            <div key={item.id} className='comment-block'>
              <div className='landing-comment'>
                <div className='author author--mt-0'>
                  <div className='my-avatar my-avatar--size-60'>
                    <div className='d-flex my-avatar__inner'>
                      <img loading='lazy' src={item.creator_avatar_url || logoDark} alt='avatar' />
                    </div>
                  </div>
                  <div className='author__info'>
                    <p className='author__name'>{item.creator_name}</p>
                    <p className='author__date'>
                      {moment(item.create_time).format('MMMM DD, YYYY')}
                    </p>
                  </div>
                  <div className={`commentator-status commentator-status--${item.creator_level}`}>
                    <div className='commentator-status__icon'>
                      {onCommentator(item.creator_level)}
                    </div>
                    {item.creator_level.length !== 3 ? (
                      <div className='commentator-status__label'>{item.creator_level}</div>
                    ) : null}
                  </div>
                </div>
                <div className='landing-comment__content'>
                  <div className='info-text info-text--fw-400 landing-comment__text'>
                    <p>{item.content}</p>
                  </div>
                </div>
                {isAuth && (
                  <div className='landing-comment-reply'>
                    <button
                      type='button'
                      onClick={() => onOpenSubComments(item.id)}
                      className='button button--size2 button--type4 '
                    >
                      <span className='btn-icon'>
                        <ReplySvg />
                      </span>
                      Reply
                    </button>
                  </div>
                )}
              </div>
              <div className='comment-replies'>
                {isAuth &&
                  (openSubComment === item.id ? (
                    <CreateComments
                      comment={subComment}
                      onCreateComment={onCreateSubComment}
                      onSubmitComment={onSubmitSubComment}
                      clearInput={clearSubInput}
                      avatar={user?.avatar_url}
                      uuid={item.uuid}
                    />
                  ) : null)}

                {item?.sub_comments?.map((subcoment) => (
                  <div key={subcoment.id} className='landing-comment landing-comment--reply'>
                    <div className='author author--mt-0'>
                      <div className='my-avatar my-avatar--size-60'>
                        <div className='d-flex my-avatar__inner'>
                          <img
                            loading='lazy'
                            src={subcoment.creator_avatar_url || logoDark}
                            alt='avatar'
                          />
                        </div>
                      </div>
                      <div className='author__info'>
                        <p className='author__name'>{subcoment.creator_name}</p>
                        <p className='author__date'>
                          {moment(item.create_time).format('MMMM DD, YYYY')}
                        </p>
                      </div>
                      <div
                        className={`commentator-status commentator-status--${subcoment.creator_level}`}
                      >
                        <div className='commentator-status__icon'>
                          {onCommentator(subcoment.creator_level)}
                        </div>
                        {item.creator_level.length !== 3 ? (
                          <div className='commentator-status__label'>{subcoment.creator_level}</div>
                        ) : null}
                      </div>
                    </div>
                    <div className='landing-comment__content'>
                      <div className='info-text info-text--fw-400 landing-comment__text'>
                        <p>{subcoment.content}</p>
                      </div>
                    </div>
                  </div>
                ))}
                <div className='pagin-padding-bottom'>
                  {item.sub_comments_count > 3 && !hide && (
                    <div className='other-answer-btn'>
                      <button
                        onClick={() => moreSubComments(item.uuid)}
                        className='link'
                      >{`Other answers (${item.sub_comments_count})`}</button>
                    </div>
                  )}
                  {item.sub_comments_count > 5 && item.uuid === id && (
                    <Pagination
                      pageCount={Math.ceil(item.sub_comments_count / 5)}
                      forcePage={currentPage}
                      onPageChange={onSetPage}
                    />
                  )}
                </div>
              </div>
            </div>
          ))}
          {Number(comments?.data.total) > 5 && comments && (
            <Pagination
              pageCount={Math.ceil(Number(comments.data.total) / 5)}
              forcePage={currentOwnPage}
              onPageChange={onSetMainPage}
            />
          )}
        </div>
      ) : (
        <div style={{ height: window.screen.availHeight }}>
          <Loader />
        </div>
      )}
    </div>
  );
};
