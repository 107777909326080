import React, { FC } from 'react';
import { dataCard, ICard } from './data';
import { useTranslation } from 'react-i18next';
import { ProjectZiroSvg } from '../../../assets/svg-icon';

export const Cards: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-card-wrap'>
      <div className='project-card-list project-card-list--full'>
        <div className='project-card'>
          <div className='d-flex project-card__icon'>
            <ProjectZiroSvg width={'4em'} height={'4em'} />
          </div>
          <h2 className='project-card__title'>{t('Page.Project.esports_hub')}</h2>
          <p className='project-card__text'>{t('Page.Project.esports_hub_desc')}</p>
        </div>
      </div>
      <div className='project-card-list'>
        {dataCard.map((card: ICard, index: number) => (
          <div key={index} className='project-card project-card-custom'>
            <div className='d-flex project-card__icon'>
              <card.svg />
            </div>
            <h2 className='project-card__title'>{t(card.title)}</h2>
            <p className='project-card__text'>{t(card.subtitle)}</p>
          </div>
        ))}
      </div>
    </div>
  );
};
