import { RootState } from '../../../store/rootReducer';
import { IAuthState, IUser } from './auth-types';

export const selectAppState = (state: RootState): IAuthState => state.auth;
export const getIsAuthSelector = (state: RootState): boolean => selectAppState(state).isAuth;
export const getTokenSelector = (state: RootState): string => selectAppState(state).token as string;
export const getDataUserSelector = (state: RootState): IUser | null => selectAppState(state).user;
export const getRefreshTokenSelector = (state: RootState): string | null =>
  selectAppState(state).refresh_token as string;
export const getStatusPopupsSelector = (state: RootState): string => selectAppState(state).success;
export const getLoadingStatusSelector = (state: RootState): string =>
  selectAppState(state).loadingState;
