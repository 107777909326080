import { LogoutSvg, SettingsPopupSvg } from '../../../assets/svg-icon';
import { ERoutes } from '../../../enum/types';
import React, { FC, MouseEvent } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

type TProps = {
  onLogout: (event: MouseEvent) => void;
  onPopupLink: (event: MouseEvent) => void;
};

export const MenuPopup: FC<TProps> = ({ onLogout, onPopupLink }) => {
  const { t } = useTranslation();

  return (
    <div className='dropdown dropdown--header-user-nav '>
      <ul className='dropdown-list'>
        <li>
          <Link id='setting' onClick={onPopupLink} to={ERoutes.settings}>
            <span className='dropdown-icon'>
              <SettingsPopupSvg style={{ color: 'white' }} width={'8em'} height={'2em'} />
            </span>
            {t('Header.MenuAccount.account_settings')}
          </Link>
        </li>
        <li>
          <Link to='/' onClick={onLogout} type='button'>
            <span className='dropdown-icon'>
              <div className='header-action-btn'>
                <LogoutSvg width={'8em'} height={'2em'} />
              </div>
            </span>
            {t('Header.MenuAccount.log_out')}
          </Link>
        </li>
      </ul>
    </div>
  );
};
