import React, { FC } from 'react';

export const ESportsMatches: FC = () => (
  <svg width='84' height='84' viewBox='0 0 84 84' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <rect
      x='0.5'
      y='0.5'
      width='83'
      height='83'
      rx='11.5'
      stroke='url(#paint0_linear_3409_59940)'
    />
    <path
      d='M61.8047 42.0007H55.3047C54.4427 42.0007 53.6161 42.3431 53.0066 42.9526C52.3971 43.5621 52.0547 44.3887 52.0547 45.2507V53.3757C52.0547 54.2376 52.3971 55.0643 53.0066 55.6738C53.6161 56.2833 54.4427 56.6257 55.3047 56.6257H58.5547C59.4166 56.6257 60.2433 56.2833 60.8528 55.6738C61.4623 55.0643 61.8047 54.2376 61.8047 53.3757V42.0007ZM61.8047 42.0007C61.8048 39.4262 61.2951 36.8771 60.3049 34.5006C59.3148 32.1241 57.8639 29.9672 56.0359 28.1543C54.2079 26.3414 52.0391 24.9085 49.6544 23.9381C47.2698 22.9678 44.7166 22.4792 42.1422 22.5007C39.5695 22.4819 37.0185 22.9725 34.6362 23.944C32.2539 24.9156 30.0874 26.349 28.2616 28.1616C26.4358 29.9742 24.9867 32.1302 23.9978 34.5054C23.009 36.8805 22.4999 39.4279 22.5 42.0007V53.3757C22.5 54.2376 22.8424 55.0643 23.4519 55.6738C24.0614 56.2833 24.888 56.6257 25.75 56.6257H29C29.862 56.6257 30.6886 56.2833 31.2981 55.6738C31.9076 55.0643 32.25 54.2376 32.25 53.3757V45.2507C32.25 44.3887 31.9076 43.5621 31.2981 42.9526C30.6886 42.3431 29.862 42.0007 29 42.0007H22.5'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M61.8047 53.375V58.25C61.8047 59.9739 61.1199 61.6272 59.9009 62.8462C58.6819 64.0652 57.0286 64.75 55.3047 64.75H43.625'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <defs>
      <linearGradient
        id='paint0_linear_3409_59940'
        x1='0'
        y1='0'
        x2='84'
        y2='84'
        gradientUnits='userSpaceOnUse'
      >
        <stop offset='0.270833' stopColor='#F96C6C' />
        <stop offset='0.755208' stopColor='#00FFE0' />
      </linearGradient>
    </defs>
  </svg>
);
