import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../services/http/interceptor/request-service';
import { showError } from '../../../utils/showError';
import { endpoint } from '../../../services/endpoint';
import { IProfileError } from '../settings/account/account-types';
import { clearErrors } from './contactUs-slice';
import { IContactUsThunk } from './contactUs-types';
import { notificationContainer } from '../../../ui/notificationContainer';
import { t } from 'i18next';

export const postContactUs = createAsyncThunk(
  'settingsAccount/postEmail',
  async (payload: IContactUsThunk, thunkApi) => {
    const { paramsApi, resetForm, captchaRef } = payload;

    try {
      const response = await requestService.post(endpoint.support.CONTACT_US, paramsApi);
      notificationContainer(t('Page.Contact_us.message_sent_successfully'), 'success');
      resetForm();
      captchaRef?.current?.resetCaptcha();
      thunkApi.dispatch(clearErrors());
      return response.data;
    } catch (error) {
      captchaRef?.current?.resetCaptcha();
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);
