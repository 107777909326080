import { CloseToastifySvg } from '../../assets/svg-icon';
import { LoadingStatus } from '../../enum/types';
import { displayNameSchema } from '../../form-validations/display-name-validation';
import { Field, Form, Formik } from 'formik';
import { useAppSelector } from '../../hooks/redux';
import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLoadingStatusSelector } from '../../store/redux-toolkit/settings/account/account-selector';
import { IUserNameChangeSubmitValue } from '../../store/redux-toolkit/settings/account/account-types';
import CheckBox from '../../ui/Formik/Checkbox';
import Input from '../../ui/Formik/Input';
import { LoadingSmall } from '../../ui/LoadingSmall/LoadingSmall';
import logo from '../../assets/images/Logo-dark.svg';

interface IProps {
  onClosePopup: () => void;
  userNameChangeSubmit: (value: IUserNameChangeSubmitValue) => void;
  username: string | undefined | null;
}

const initialValues = {
  username: '',
  username_confirmation: '',
};

export const PopupChangeUserName: FC<IProps> = ({
  onClosePopup,
  userNameChangeSubmit,
  username,
}) => {
  const loadingStatus = useAppSelector(getLoadingStatusSelector);
  const { t } = useTranslation();
  const text = t('Page.Settings.Account.DisplayNameChange.btn_change_confirm');

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  }, []);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={displayNameSchema}
      onSubmit={(values: IUserNameChangeSubmitValue, { setSubmitting }) => {
        userNameChangeSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Form className='popup-window'>
            <div className='popup-window__inside'>
              <div className='popup'>
                <button
                  type='button'
                  aria-label='Close'
                  onClick={onClosePopup}
                  className='popup__close'
                >
                  <CloseToastifySvg />
                </button>
                <div className='popup-header'>
                  <div className='popup-logo'>
                    <img loading='lazy' src={logo} alt='logo' />
                  </div>
                  <h2 className='popup-title'>
                    {t('Page.Settings.Account.DisplayNameChange.change_title')}
                  </h2>
                </div>
                <div className='popup-body'>
                  <div className='note'>
                    <p className='note__title'>
                      {t('Page.Settings.Account.DisplayNameChange.change_note_1')}
                    </p>
                    <div className='note__text'>
                      <p>{t('Page.Settings.Account.DisplayNameChange.change_note_2')}</p>
                    </div>
                  </div>
                  <div className='popup-text '>
                    <p>
                      <strong>{t('Page.Settings.Account.DisplayNameChange.change_current')}</strong>
                      {` ${username || ''}`}
                    </p>
                  </div>
                  <div className='input'>
                    <div className='input-wrapper'>
                      <Field
                        name='username'
                        component={Input}
                        className='input-item'
                        type='text'
                        placeholder={t('Page.Settings.Account.DisplayNameChange.change_new_name')}
                      />
                    </div>
                  </div>
                  <div className='input'>
                    <div className='input-wrapper'>
                      <Field
                        name='username_confirmation'
                        component={Input}
                        className='input-item'
                        type='text'
                        placeholder={t(
                          'Page.Settings.Account.DisplayNameChange.change_confirm_name'
                        )}
                      />
                    </div>
                  </div>
                  <Field
                    name='checkbox'
                    component={CheckBox}
                    type='checkbox'
                    className='hidden'
                    text={text}
                  />
                </div>
                <div className='popup-footer'>
                  <div className='form-submit form-submit--mob-column'>
                    <button
                      onClick={onClosePopup}
                      className='button button--type3 button--full-width'
                      type='button'
                    >
                      {t('Global.cancel')}
                    </button>
                    <button
                      disabled={!(isValid && dirty) || isSubmitting}
                      className='button button--full-width'
                      type='submit'
                    >
                      {loadingStatus === LoadingStatus.LOADING ? (
                        <LoadingSmall />
                      ) : (
                        t('Global.continue')
                      )}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
