import React, { FC } from 'react';
import { useField } from 'formik';
import { ITextareaFieldProps } from './types';
import { useTranslation } from 'react-i18next';

export const TextareaField: FC<ITextareaFieldProps> = ({
  name,
  label,
  placeholder,
  textareaProps = {},
}) => {
  const [field, meta] = useField(name);
  const isShowError = meta.touched && meta.error;
  const { t } = useTranslation();

  return (
    <div className='form-group'>
      <div className='input input--mb-0'>
        {label && <p className='input-name'>{t(label)}</p>}
        <div className='textarea'>
          <textarea {...field} placeholder={placeholder} {...textareaProps} />
        </div>
      </div>
      {isShowError && <p className='input-notification input-notification--error'>{meta.error}</p>}
    </div>
  );
};
