import React, { FC } from 'react';
import { Project } from '../../components/Project/Project';

const ProjectPage: FC = () => {
  return (
    <section className='landing-section landing-section--project'>
      <Project />
    </section>
  );
};

export default ProjectPage;
