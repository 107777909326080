import React from 'react';
import joystick from '../../../assets/images/project-figure-icon.png';

export const Decorate: React.FC = () => (
  <>
    <span className='project-figure project-figure--type1'>
      <svg
        width='37'
        height='37'
        viewBox='0 0 37 37'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <g filter='url(#filter0_d_2633_6950)'>
          <g filter='url(#filter1_di_2633_6950)'>
            <path
              d='M21.3845 25.8093L17.1913 21.7446C16.9348 21.496 16.8282 21.1039 16.9282 20.7767L18.0753 17.0221C18.2057 16.5954 18.6364 16.4276 19.0211 16.6537L22.4057 18.6429C22.7007 18.8162 22.8874 19.1771 22.8667 19.5337L22.5282 25.3638C22.491 26.0042 21.8451 26.2558 21.3845 25.8093Z'
              fill='#FFF8DE'
            />
          </g>
          <path
            d='M13.0338 28.6013C12.8717 28.1851 13.0879 27.7122 13.5167 27.5452L29.4987 21.3192C29.9275 21.1521 30.4066 21.3541 30.5687 21.7703L31.7414 24.7805C31.9036 25.1968 31.6874 25.6696 31.2586 25.8367L15.2765 32.0627C14.8477 32.2297 14.3686 32.0277 14.2065 31.6115L13.0338 28.6013Z'
            fill='#FF7A00'
          />
          <path
            d='M12.2459 14.9073L12.9989 6.53392C13.1179 5.21071 14.6832 4.60092 15.666 5.49493L21.8848 11.1523C22.6274 11.8279 23.79 11.6689 24.3164 10.8197L26.3099 7.60422C27.0529 6.40583 28.8638 6.69381 29.2227 8.06741L32.8497 21.9508C33.0572 22.7451 32.6433 23.5657 31.8866 23.8605L13.4765 31.0324C12.7198 31.3272 11.8599 31.0028 11.4754 30.2775L4.75497 17.5991C4.09006 16.3448 5.22894 14.9077 6.58677 15.2877L10.2301 16.3073C11.1922 16.5765 12.156 15.9072 12.2459 14.9073Z'
            fill='url(#paint0_radial_2633_6950)'
          />
        </g>
        <defs>
          <filter
            id='filter0_d_2633_6950'
            x='0.560547'
            y='1.07422'
            width='36.3418'
            height='35.0469'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset />
            <feGaussianBlur stdDeviation='2' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 1 0 0 0 0 0.780392 0 0 0 0 0 0 0 0 0.3 0'
            />
            <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_2633_6950' />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2633_6950'
              result='shape'
            />
          </filter>
          <filter
            id='filter1_di_2633_6950'
            x='12.8906'
            y='13.5547'
            width='13.9785'
            height='17.4805'
            filterUnits='userSpaceOnUse'
            colorInterpolationFilters='sRGB'
          >
            <feFlood floodOpacity='0' result='BackgroundImageFix' />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='1' />
            <feGaussianBlur stdDeviation='2' />
            <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.68 0' />
            <feBlend
              mode='overlay'
              in2='BackgroundImageFix'
              result='effect1_dropShadow_2633_6950'
            />
            <feBlend
              mode='normal'
              in='SourceGraphic'
              in2='effect1_dropShadow_2633_6950'
              result='shape'
            />
            <feColorMatrix
              in='SourceAlpha'
              type='matrix'
              values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0'
              result='hardAlpha'
            />
            <feOffset dy='-1' />
            <feGaussianBlur stdDeviation='1' />
            <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
            <feColorMatrix
              type='matrix'
              values='0 0 0 0 1 0 0 0 0 0.914286 0 0 0 0 0.55 0 0 0 1 0'
            />
            <feBlend mode='normal' in2='shape' result='effect2_innerShadow_2633_6950' />
          </filter>
          <radialGradient
            id='paint0_radial_2633_6950'
            cx='0'
            cy='0'
            r='1'
            gradientUnits='userSpaceOnUse'
            gradientTransform='translate(12.4581 15.957) rotate(35.6187) scale(17.2091 15.3153)'
          >
            <stop stopColor='#FFEB81' />
            <stop offset='0.682292' stopColor='#FFC839' />
          </radialGradient>
        </defs>
      </svg>
    </span>
    <span className='project-figure project-figure--type2'>
      <img loading='lazy' src={joystick} alt='gamepad' />
    </span>

    <span className='project-dot project-dot--type1'>
      <svg width='5' height='5' viewBox='0 0 5 5' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='2.5' cy='2.5' r='2.5' fill='#FF9600' />
      </svg>
    </span>

    <span className='project-dot project-dot--type2'>
      <svg width='3' height='3' viewBox='0 0 3 3' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <circle cx='1.5' cy='1.5' r='1.5' fill='#FBFBFB' />
      </svg>
    </span>
    <span className='project-dot project-dot--type3'>
      <svg
        width='11'
        height='11'
        viewBox='0 0 11 11'
        fill='none'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle cx='5.5' cy='5.5' r='5.5' fill='#FF9600' />
      </svg>
    </span>
  </>
);
