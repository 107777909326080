export function getHost() {
  if (
    window.location.host.includes('localhost:3000') ||
    window.location.host.includes('localhost:5000')
  ) {
    return 'dev-playch.dev-now.xyz/gateway';
  } else {
    return window.location.host;
  }
}
