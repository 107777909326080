import React from 'react';

export const MyComentsBody: React.FC = () => {
  return (
    <div className='table-body'>
      <div className='tr'>
        <div className='td'>
          <p className='td-hidden-name'>Date</p>
          <p>01.01.2023</p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Comment text</p>
          <p>This is comment text. Its long and containes span and bad word. #$*!!</p>
        </div>
        <div className='td td--right'>
          <p className='td-hidden-name'>Reject reason</p>
          <p>Its brakes Privacy policy - paragraph 5.</p>
        </div>
      </div>
      <div className='tr'>
        <div className='td'>
          <p className='td-hidden-name'>Date</p>
          <p>01.01.2023</p>
        </div>
        <div className='td'>
          <p className='td-hidden-name'>Comment text</p>
          <p>This is comment text. Its long and containes span and bad word. #$*!!</p>
        </div>
        <div className='td td--right'>
          <p className='td-hidden-name'>Reject reason</p>
          <p>Spam</p>
        </div>
      </div>
    </div>
  );
};
