import { isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../services/http/interceptor/request-service';
import { showError } from '../../../utils/showError';
import { endpoint } from '../../../services/endpoint';
import { IProfileError } from '../settings/account/account-types';
import { notificationContainer } from '../../../ui/notificationContainer';
import { IBackersRequest } from './backers-types';
import { clearErrors } from './backers-slice';

export const getBackers = createAsyncThunk(
  'backers/getBackers',
  async (payload: IBackersRequest, thunkApi) => {
    try {
      const response = await requestService.get(endpoint.backers.BACKERS, { ...payload });
      thunkApi.dispatch(clearErrors());
      return response.data.data;
    } catch (error) {
      const err = error as IProfileError;
      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);
