import React, { FC } from 'react';
import { Comments } from '../../components/Comments/Comments';

const CommentsPage: FC = () => {
  return (
    <section className='landing-section landing-section--comments'>
      <Comments />
    </section>
  );
};

export default CommentsPage;
