import React, { FC } from 'react';
import ContactUs from '../../components/ContactUs/ContactUs';
import contact_bg from '../../assets/images/contact-us-photo.png';

const ContactUsPage: FC = () => {
  return (
    <div
      style={{
        backgroundImage: `url(${contact_bg})`,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center',
      }}
      className='contact-us-wrap'
    >
      <ContactUs />
    </div>
  );
};

export default ContactUsPage;
