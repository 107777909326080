import { onScrollUp } from '../../layouts-element/Footer/scrollUp';
import React, { FC, useEffect } from 'react';

const CookiePolicy: FC = () => {
  useEffect(() => {
    onScrollUp();
  }, []);

  return (
    <section className='landing-section landing-section--terms'>
      <div className='container container--big'>
        <p className='terms-last-modify'>Last Modified on 09/04/2023</p>
        <div className='terms-header'>
          <h2 className='terms-header__title'>Cookie Policy</h2>
          <div className='select-block select-block--mb-0'>
            <div className='select '>
              <button type='button' className='select__current'>
                <span className='dropdown-icon'>
                  <img loading='lazy' src='./images/flag1.png' alt='English' />
                </span>
                English
                <span className='select__current-arrow'>
                  <svg
                    width='16'
                    height='8'
                    viewBox='0 0 16 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.6004 0.958984L9.16706 6.39232C8.52539 7.03399 7.47539 7.03399 6.83372 6.39232L1.40039 0.958984'
                      stroke='#FBFBFB'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </button>
              <div className='select__drop'>
                <div className='select__drop-scroll'>
                  <div className='select__drop-item'>
                    <ul>
                      <li>
                        <button type='button'>
                          <span className='dropdown-icon'>
                            <img loading='lazy' src='./images/flag1.png' alt='United States' />
                          </span>
                          United States
                        </button>
                      </li>
                      <li>
                        <button type='button'>
                          <span className='dropdown-icon'>
                            <img loading='lazy' src='./images/flag3.png' alt='Ukrainian' />
                          </span>
                          Ukrainian
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='editable-content'>
          <p>Last updated: 5th April 2023</p>
          <p>
            {`This is the Cookies Policy (the "Policy") for Playch, accessible from playch.com. To
            make this site function properly, we sometimes place small data files called cookies on
            your device. These cookies are downloaded to your computer to improve your browsing
            experience as well as other purposes that are outlined in this Policy.`}
          </p>
          <p>
            {`Accordingly, this Policy is a legally binding agreement between you ("user," "visitor,"
            "you," or "your") and this Website administrator or operator ("administrator,"
            "operator," "we," "us," or "our"). This cookies policy page describes what cookies are,
            what information they gather, why, and how they're being used by Playch and any of its
            related products and services (the "Services"). We will also share how to control and
            disable these cookies and withdraw consent to collect cookies. However, please know that
            these actions may downgrade some aspects of this site's functionality.`}
          </p>
          <p>
            For further information on how we use, collect and manage your personal data and
            information, see our Privacy Policy.
          </p>
          <h3>What Are Cookies</h3>
          <p>
            {`Cookies are those small pieces of data stored in text files saved on your mobile device
            or computer when you visit a website. Cookies allow the website's server to record and
            store your actions and preferences, such as login information, user history, language
            settings, font settings, colour settings, and other display preferences, over a
            particular period. Cookies ensure visitors do not have to continuously re-enter their
            details or information whenever they revisit the site or surf between pages.`}
          </p>
          <p>
            Cookies are used to remember your preferences for single visits through sessional
            cookies or multiple repeat visits through permanent cookies.
          </p>
          <h3>How We Use Cookies</h3>
          <p>
            Cookies are essential for our website to function optimally and enhance website
            performance for a better user experience. The website administrator(s) controls all
            cookies used on the site and grants third parties access for statistics and analytics
            purposes. Cookies are needed to remember to identify visitors and remember their site
            actions and online behaviours.
          </p>
          <p>
            We use cookies for various reasons, which are explained in detail below. Please know
            that cookie-related information is not used to identify you personally.
          </p>
          <h3>How to Control Cookies</h3>
          <p>
            {`You can control and/or delete cookies on your mobile device or computer and adjust your
            browser settings to prevent cookies. Users can withdraw consent to collect cookies on
            the website. You can also determine whether you want to agree to the site's use of
            cookies. However, doing this may cause you to repeat your preferences every time you
            visit the site manually, and some of the site's services and functionality may not work
            optimally.`}
          </p>
          <h3>Disabling Cookies</h3>
          <p>
            {`You can disable cookies setting by adjusting the option on your browser settings (see
            your browser "Help" for more details). However, please be aware that the act of
            disabling cookies will affect your visit's functionality and the use of this website and
            many others. Unfortunately, disabling cookies will usually result in also disabling
            certain functionality and features of this site. In most cases, there are no website
            standard options for disabling cookies without completely disabling the functionality
            and features they add to the site. It is recommended that you enable cookies for optimal
            site functionality and service.`}
          </p>
          <h3>The Cookies We Use</h3>
          <h4>Sessional Cookies</h4>
          <p>
            Sessional cookies are temporary cookies that are operational during your visit and
            expire when you close the browser.
          </p>
          <h4>Permanent Cookies</h4>
          <p>
            Permanent cookies store and retain your site preferences on your mobile device or
            computer even after you close the browser or your mobile device or computer.
          </p>
          <h4>Necessary Cookies</h4>
          <p>
            Necessary cookies allow the website to provide a better user experience when navigating
            the website, its services, and features. These cookies allow us to recognize if you have
            created and logged into an account to access site content.
          </p>
          <h4>Functionality Cookies</h4>
          <p>
            Functionality cookies allow the website to operate optimally, following your site
            preferences for future visits. These recognize your site username and customizations.
          </p>
          <h4>Analytical Cookies</h4>
          <p>
            {`Analytical cookies allow first-party and affiliated third-party services to collect and
            store aggregated sate for statistical and analytical purposes on how our visitors use
            the website. While these cookies respect visitors' privacy and personal information,
            they optimize the user experience.`}
          </p>
          <h4>Account Cookies</h4>
          <p>
            These cookies will help us manage the signup and general administration of creating or
            opening an account opening. These cookies may be deleted when you log out but sometimes
            remain afterwards to remember your site preferences.
          </p>
          <h4>Login Cookies</h4>
          <p>
            We use login cookies to remember your Login details and prevent you from logging in
            every time you visit a new page on the site. These cookies are often cleared when you
            log out to secure your account information and privacy and prevent access to restricted
            features.
          </p>
          <h4>Email newsletters Cookies</h4>
          <p>
            This site uses cookies to offer newsletter or email subscription services and cookies
            and remember if you are already registered or subscribed to show specific notifications
            or features reserved for subscribed/unsubscribed users.
          </p>
          <h4>Orders-processing Cookies</h4>
          <p>
            We use cookies to store and remember your payment orders on our e-commerce or payment
            facilities for proper payment processing.
          </p>
          <h4>Survey Cookies</h4>
          <p>
            We periodically issue our users surveys and questionnaires to provide interesting
            insights, helpful tools or understand their preferences or opinions more accurately.
            These surveys may use cookies to remember participants or to generate accurate results
            even after you change pages.
          </p>
          <h4>Forms Cookies</h4>
          <p>
            We use cookies to remember your details for future correspondence when you submit data
            through forms such as contact or comment details.
          </p>
          <h4>Site preferences Cookies</h4>
          <p>
            To provide you with a beautiful user experience and site interaction, we use cookies to
            provide the functionality to set your site preferences when you use it.
          </p>
          <h4>First-Party Cookies</h4>
          <p>First-party cookies are the cookies explained above that are set by the website.</p>
          <h4>Third-Party Cookies</h4>
          <p>
            In some cases, the website uses cookies provided by trusted third parties that serve
            content or render advertising and analytics services on this website. The following
            section explains the third-party cookies you may encounter while using this site.
          </p>
          <p>
            {`This site uses Google Analytics, one of the most trusted analytics solutions on the
            global web, to understand how you use the site and ways to improve your site experience.
            As a result, these cookies may track your use of the site, time spent on the site, and
            its pages to allow us to continue producing engaging content. For more information on
            Google Analytics cookies, we refer you to see the official Google Analytics page:
            https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage We
            periodically test new features and make subtle changes to our site's user interaction.
            Cookies may be used to provide you with a consistent experience on the site and
            understand our user's preferred optimizations. We use cookies to track and understand
            the statistics about the number of visitors that make purchases for the products that we
            sell. This allows us to accurately make informed business and market predictions that
            monitor our advertising and product costs to ensure the best equilibrium price. We use
            Google AdSense service, which uses a DoubleClick cookie to serve more relevant ads
            across the web and limit the number of times a particular ad is shown to our users. For
            more information on Google AdSense, we refer you to see the official Google AdSense
            privacy FAQ: https://support.google.com/adsense/answer/3394713 We rely on adverts to
            offset the running costs of this site and generate revenue for further development. We
            use behavioural advertising cookies to ensure that we provide you with the most relevant
            adverts where possible by anonymously tracking your specific interests and presenting
            similar interests. Several partners advertise on our behalf, and we use affiliate
            tracking cookies to determine the number of our customers that visit the site through
            each of our partner sites to credit them appropriately. Where applicable, these cookies
            allow our affiliate partners to provide any bonus that they may provide you for making a
            purchase. We use cookies for social media buttons and/or plugins on this site to connect
            with your social network in different ways. These cookies also allow us to track social
            network users when they visit our site or share content using a tagging mechanism
            provided by those social media sites.`}
          </p>
          <h3>Amendments and Modifications</h3>
          <p>
            Playch reserves the right to modify this Policy and its terms as they relate to this
            website and services at any time, effective and in full force upon posting the updated
            version on the website.
          </p>
          <p>
            Please check this page regularly for updates. The continued use of the website and its
            services after that shall constitute your consent to such amendments and modifications.
          </p>
          <h3>Policy Acceptance</h3>
          <p>
            You hereby acknowledge that you have read this Cookies Policy and agree to all its
            provisions, terms, and conditions. By continuing to access and use this website and its
            services, you also agree to be legally bound by this Cookies Policy. If you do not agree
            to be bound by the terms and conditions of this Policy, you are not permitted to
            continue to access or use this website and its services.
          </p>
          <h3>More Information</h3>
          <p>
            We trust that you have gained clarity concerning our use of cookies. For further
            information about our cookies policy, please email us at support@playch.com.
          </p>
        </div>
      </div>
    </section>
  );
};

export default CookiePolicy;
