import { SignUpForm } from '../../../components/Forms/SignUpForm/SignUpForm';
import { useAppDispatch } from '../../../hooks/redux';
import React, { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { registration } from '../../../store/redux-toolkit/auth/auth-thunks';
import { IRegistration } from '../../../store/redux-toolkit/auth/auth-types';

const SignUp: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLoginSubmit = (values: IRegistration): void => {
    dispatch(
      registration({
        paramsApi: values,
        navigate,
      })
    );
  };

  return (
    <section className='authorization-section'>
      <div className='authorization'>
        <SignUpForm loginSubmit={handleLoginSubmit} />
      </div>
    </section>
  );
};

export default SignUp;
