import { PanelSvg, SlashSvg, SupportSvg } from '../../../assets/svg-icon';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const Partner: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-bg-section'>
      <div className='container container--main'>
        <div className='stream-info-box'>
          <div className='stream-info'>
            <h3 className='info-subtitle info-subtitle--main-color'>{t('Page.Project.partner')}</h3>
            <h3 className='info-subtitle info-subtitle--size3 info-subtitle--fw-500'>
              {t('Page.Project.partner_sub')}
            </h3>
            <ul className='info-list'>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.partner_item_one')}
              </li>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.partner_item_two')}
              </li>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.partner_item_three')}
              </li>
            </ul>
          </div>
          <div className='stream-info'>
            <h3 className='info-subtitle info-subtitle--main-color'>
              {t('Page.Project.premium_partner')}
            </h3>
            <h3 className='info-subtitle info-subtitle--size3 info-subtitle--fw-500'>
              {t('Page.Project.premium_partner_sub')}
            </h3>
            <ul className='info-list'>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.premium_partner_item_one')}
              </li>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.premium_partner_item_two')}
              </li>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.premium_partner_item_three')}
              </li>
              <li>
                <span className='info-list-icon'>
                  <SlashSvg />
                </span>
                {t('Page.Project.premium_partner_item_four')}
              </li>
            </ul>
          </div>
        </div>
        <div className='stream-info-box'>
          <div className='stream-info'>
            <div className='stream-about'>
              <div className='stream-about__icon'>
                <PanelSvg width={'3em'} height={'3em'} />
              </div>
              <h3 className='info-subtitle info-subtitle--size2 '>
                {t('Page.Project.streamer_panel')}
              </h3>
              <div className='info-text info-text--type2 info-text--mt-16'>
                <p>{t('Page.Project.streamer_panel_desc')}</p>
              </div>
            </div>
          </div>
          <div className='stream-info'>
            <div className='stream-about'>
              <div className='stream-about__icon'>
                <SupportSvg width={'3em'} height={'3em'} />
              </div>
              <h3 className='info-subtitle info-subtitle--size2 '>
                {t('Page.Project.multi_streaming')}
              </h3>
              <div className='info-text info-text--type2 info-text--mt-16'>
                <p>{t('Page.Project.multi_streaming_desc')}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
