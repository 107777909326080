import { onScrollUp } from '../../layouts-element/Footer/scrollUp';
import React, { FC, useEffect } from 'react';

const PrivacyPolicy: FC = () => {
  useEffect(() => {
    onScrollUp();
  }, []);

  return (
    <section className='landing-section landing-section--terms'>
      <div className='container container--big'>
        <p className='terms-last-modify'>Last Modified on 17/01/2024</p>
        <div className='terms-header'>
          <h2 className='terms-header__title'>Privacy Policy</h2>
          {/* <div className='select-block select-block--mb-0'>
            <div className='select '>
              <button type='button' className='select__current'>
                <span className='dropdown-icon'>
                  <img loading="lazy" src='./images/flag1.png' alt='' />
                </span>
                English
                <span className='select__current-arrow'>
                  <svg
                    width='16'
                    height='8'
                    viewBox='0 0 16 8'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <path
                      d='M14.6004 0.958984L9.16706 6.39232C8.52539 7.03399 7.47539 7.03399 6.83372 6.39232L1.40039 0.958984'
                      stroke='#FBFBFB'
                      strokeWidth='1.5'
                      strokeMiterlimit='10'
                      strokeLinecap='round'
                      strokeLinejoin='round'
                    />
                  </svg>
                </span>
              </button>
              <div className='select__drop'>
                <div className='select__drop-scroll'>
                  <div className='select__drop-item'>
                    <ul>
                      <li>
                        <button type='button'>
                          <span className='dropdown-icon'>
                            <img loading="lazy" src='./images/flag1.png' alt='' />
                          </span>
                          United States
                        </button>
                      </li>
                      <li>
                        <button type='button'>
                          <span className='dropdown-icon'>
                            <img loading="lazy" src='./images/flag2.png' alt='' />
                          </span>
                          Ukrainian
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <div className='editable-content'>
          <p>
            PLAYCH, or its affiliates (collectively either “PLAYCH”, “we” or “us”) provide this
            privacy policy (“Privacy Policy”) to inform you of our policies and procedures regarding
            the collection, storage, use and disclosure of personal information of registered and
            unregistered users of PLAYCH (“PLAYCH Users”) in connection with the use by PLAYCH Users
            of our website accessible at{' '}
            <a href='https://playch.tv' target='_blank' rel='noopener noreferrer'>
              https://playch.tv
            </a>{' '}
            and{' '}
            <a href='https://playch.net' target='_blank' rel='noopener noreferrer'>
              https://playch.net
            </a>{' '}
            (“Site”) and our PLAYCH applications for mobile, desktop, and the use of software or
            video player platforms provided by PLAYCH, including those which enable the sharing of
            live and recorded video and audio content and chat on the Internet and via computers and
            mobile devices by PLAYCH Users (collectively, and including the Site, “Services”).
            <br />
            <br />
            Please note that this Privacy Policy may be revised periodically by making such
            revisions available on the Site or otherwise providing notice to you. Thus, please
            revisit this page to stay aware of any changes. Your use of the Services constitutes
            your agreement to this Privacy Policy, and your continued use of the Services after any
            revisions to this Privacy Policy constitutes your agreement to such revisions.
            <br />
            <br />
            By using the Site or any of the Services, you expressly consent to our collection,
            storage, use and disclosure of your personal information in the manner described in this
            Privacy Policy.
            <br />
            <br />
            We collect the following information in connection with the Services:
          </p>

          <h3>PERSONAL INFORMATION</h3>
          <p>
            In the course of your use of Services, including as part of the registration process, we
            may ask you to provide us with certain personal information that can be used to identify
            you, such as your name, phone number, credit card or other billing information, email
            address and home and business postal addresses (“Personal Information”). Personal
            Information may also be collected in connection with surveys, sweepstakes, contests or
            promotions.
          </p>

          <h3>NON-IDENTIFYING INFORMATION</h3>
          <p>
            We may also collect information that is not Personal Information in connection with your
            use of the Services, including via your registration and the administration and
            personalization of your profile webpage (e.g., zip code, gender, age and individual
            preferences) (“Non-Identifying Information”). We will treat Non-Identifying Information
            as Personal Information when it is combined with other identifiers (for example,
            combining your zip code with your street address) in a way that enables you to be
            identified.
          </p>

          <h3>LOG DATA</h3>
          <p>
            As you use the Services, whether as a registered or non-registered user, our servers
            automatically record information that your browser sends whenever you visit the Site
            (“Log Data”). Log Data may include device-specific information such as your computer’s
            IP address, operating system version, browser type, unique device identifiers, mobile
            network information including phone number, the content and pages that you access on the
            Services, the dates and times that you visit the Services, device event information such
            as crashes, system activity, hardware settings or browser language, and cookies that may
            uniquely identify your browser or PLAYCH account or the Internet protocol address from
            which you are visiting. Log Data may also include the URL or other identifiers of the
            server of any link followed to reach the Site, and usage data gathered through ‘cookies’
            as explained in the following paragraph. All Log Data that does not identify you will be
            considered Non-Identifying Information under this Privacy Policy.
          </p>

          <h3>COOKIES</h3>
          <p>
            Like many websites, we use one or more cookies or anonymous identifiers to collect usage
            information. A cookie is a small data file that we transfer to your computer’s hard disk
            for record-keeping purposes. We use cookies for two purposes. First, we utilize
            persistent cookies to save your encrypted registration ID and login password for future
            logins to the Site as well as display preference settings (such as preferred language).
            Second, we utilize session ID cookies to enable certain features of the Services. We
            collect data regarding use of the cookies to better understand how you interact with the
            Services and to monitor aggregate usage by PLAYCH Users and web traffic routing on the
            Services. Unlike persistent cookies, session cookies are deleted from your computer when
            you close your browser. Third-party advertisers on the Services may also place or read
            cookies on your browser. You can set your browser, by editing its options, to block all
            cookies, including cookies associated with our Services, or to prompt you before
            accepting a cookie from the websites you visit. If you do not accept cookies, however,
            you may not be able to use all portions of the Site or all functionality of the
            Services.
          </p>

          <h3>INFORMATION USE</h3>
          <p>
            We may use Non-Identifying Information for any purpose, including the allowance of
            third-party companies to serve ads and/or collect certain anonymous information when you
            visit our web site. These companies may use non-personally identifiable information
            (e.g., click stream information, browser type, time and date, subject of advertisements
            clicked or scrolled over) during your visits to this and other websites in order to
            provide advertisements about goods and services likely to be of greater interest to you.
            These companies typically use a cookie or third-party web beacon to collect this
            information. To learn more about this behavioral advertising practice or to opt-out of
            this type of advertising, please visit the following websites:
            <br />
            <br />
            Consumer Choices Page:{' '}
            <a href='http://www.aboutads.info/choices/' target='_blank' rel='noopener noreferrer'>
              http://www.aboutads.info/choices/
            </a>
            <br />
            Network Advertising Initiative (USA and Canada):{' '}
            <a href='https://thenai.org/' target='_blank' rel='noopener noreferrer'>
              Home - NAI: Network Advertising Initiative
            </a>
            <br />A Guide to Behavioral Advertising (Europe):{' '}
            <a href='https://youronlinechoices.eu/' target='_blank' rel='noopener noreferrer'>
              Your Online Choices | EDAA
            </a>
          </p>

          <h3>SITE ADMINISTRATION AND OPERATION</h3>
          <p>
            Personal Information may be stored and used to verify your identify, contact you,
            provide you with notifications, administer your account, provide our Services, improve
            our Services, create new Services, enable your use of the Site and Services, analyze
            PLAYCH Users and use of the Services and related preferences, and otherwise operate the
            Site and Services. Personal Information may be transferred and stored anywhere in the
            USA or internationally.
          </p>

          <h3>MARKETING AND PROMOTIONS</h3>
          <p>
            We may use your Personal Information to contact you with PLAYCH newsletters, marketing
            or promotional materials and other information that may be of interest to you, including
            information regarding third-party products and services. PLAYCH members who agree to the
            PLAYCH Terms of Service are given the opportunity to opt-in to receiving such
            communications from us in the future. If you decide at any time that you no longer wish
            to receive such marketing or promotional communications from us, please follow the
            unsubscribe instructions provided in any of the communications or update your “user
            preferences” information. (See “Changing or Deleting Your Information” below).{' '}
          </p>

          <h3>INFORMATION DISCLOSURE</h3>
          <p>
            We may disclose Non-Identifying Information to any party for any purpose. We may
            disclose the Personal Information we collect as follows:
          </p>

          <h3>PUBLIC PROFILE INFORMATION</h3>
          <p>
            When you register through the Site and submit your Personal Information to create a
            profile webpage, PLAYCH Users will see your screen or user name and any other comments
            or submissions that you post on the Site or submit via the Services. You can choose
            which other Personal Information you provide as part of your profile (“Other Member
            Information”) and, in some cases, you can choose whether to share that information
            publicly on the Site by making selections via the user preferences section of your
            profile webpage. Providing Other Member Information is voluntary and should correlate
            with the degree of interaction you want to have with PLAYCH Users. We recommend that you
            guard your anonymity and sensitive information, and that you not use your real name as
            your screen or user name to the extent that doing so might allow others to personally
            identify you.
          </p>

          <h3>AFFILIATES</h3>
          <p>
            PLAYCH may also share your Personal Information with other networks, websites and
            entities affiliated, owned, controlled and/or connected with PLAYCH.
          </p>

          <h3>DEMOGRAPHICS AND MARKETING</h3>
          <p>
            We may share aggregated information that includes your Personal Information,
            Non-Identifying Information and Log Data with third parties for industry analysis and
            demographic profiling and to deliver targeted advertising about PLAYCH or other products
            and services.{' '}
          </p>

          <h3>PROMOTIONAL PARTNERS</h3>
          <p>
            We may, from time to time, offer special access to pay-per-view or other special
            promotional events, and we may share your Personal Information with those entities and
            advertisers that are participating in such events. Unless otherwise stated in connection
            with such promotion, we will only share your name and email address with such parties,
            so that you may receive newsletters or promotional materials from such entities.{' '}
          </p>

          <h3>SERVICE PROVIDERS</h3>
          <p>
            We may employ third-party companies and individuals to facilitate our Services, to
            provide the Services on our behalf, to perform Site-related services or to assist us in
            analysing how our Services are used (each, a “Service Provider”). The types of Service
            Providers we use include those that provide the following types of services: credit-card
            processing, accounting, customer relationship management, website maintenance,
            notification services, database management and web analytics. These third parties have
            access to your Personal Information only to perform these tasks on our behalf and are
            obligated not to disclose or use it for any other purpose.
          </p>

          <h3>Legal Basis for Processing Personal Data</h3>
          <p>
            As an PLAYCH member, you can link your account information with certain social
            networking services (“SNS”) and publish your activities and other data to your friends.
            In these instances, depending on the user preferences you set in your PLAYCH account,
            with your permission and on your behalf, we will share the information contained in your
            PLAYCH account (e.g., PLAYCH profile page, channel page and any other information you
            deem public in any content you create on the Site) with the SNS. We also receive certain
            information (which may include your Personal Information) in accordance with the privacy
            settings you have set in your SNS account, if any. Other than what we may share with the
            SNS in connection with your linking of accounts, the personal information that an SNS
            has about you is obtained by such SNS independently of our Services. Other services
            follow different rules regarding the use or disclosure of the personal information you
            submit to them. We encourage you to read the privacy policies or statements of the other
            online services you use.
          </p>

          <h3>COMPLIANCE WITH LAWS AND LAW ENFORCEMENT</h3>
          <p>
            PLAYCH cooperates with government and law-enforcement officials or private parties to
            enforce and comply with the law. We may disclose any information about you to government
            or law enforcement officials or private parties as we, in our sole discretion, believe
            necessary or appropriate to respond to claims and legal process (including, but not
            limited to, subpoenas), to protect the property and rights of PLAYCH or a third party,
            to enforce our rights, including under our Terms of Service, to protect the safety of
            the public or any person, or to prevent or stop any illegal, unethical or legally
            actionable activity. We may also disclose any information as otherwise required by law.
          </p>

          <h3>BUSINESS TRANSFERS</h3>
          <p>
            We may sell, transfer or otherwise share some or all of our assets, including your
            Personal Information, in connection with a merger, acquisition, reorganisation or sale
            of assets or in the event of bankruptcy.
          </p>

          <h3>CHANGING OR DELETING YOUR INFORMATION</h3>
          <p>
            All PLAYCH members may review, update, correct or delete the Personal Information in
            their registration profile under account settings. Upon disabling your account, all such
            information previously belonging to the account will be lost. However, PLAYCH will
            preserve the basic account information for 90 days subsequent to termination of service
            for general security purposes. We will then discard all information pertaining to your
            account immediately after the expiration of the 90-day grace period. If you would like
            to modify or delete all or parts of your record in our system, please follow the steps
            to disable your account on the Site. We will attempt to accommodate your request within
            a reasonable period of time after our receipt of your request. However, we make no
            exception to the previously set forth rule for retention of records.
          </p>

          <h3>SECURITY</h3>
          <p>
            We are very concerned with safeguarding your information. We employ reasonable physical,
            technological and administrative security measures to protect PLAYCH and PLAYCH Users
            from unauthorised access to or unauthorised alteration, disclosure or destruction of
            information we hold. Nonetheless, please note that no method of transmission over the
            Internet, or method of electronic storage, is completely secure. Thus, while we strive
            to use commercially acceptable means to protect your Personal Information, we cannot
            ensure or warrant its absolute security.
            <br />
            <br />
            We will make any legally required disclosures of any breach of security, confidentiality
            or integrity of your unencrypted electronically stored Personal Information to you via
            email or conspicuous posting on this Site in the most expedient time possible and
            without unreasonable delay, insofar as consistent with the legitimate needs of law
            enforcement or any measures necessary to determine the scope of the breach and restore
            the reasonable integrity of the data system.
          </p>

          <h3>INTERNATIONAL TRANSFER</h3>
          <p>
            Your information may be transferred to and maintained on computers located outside of
            your state, province, country or other governmental jurisdiction where the privacy laws
            may not be as protective as those in your jurisdiction. Since PLAYCH operates worldwide,
            we may make information we gather available to business units and/or affiliates located
            globally. Your submission of such information represents your agreement to such
            transfer.
          </p>

          <h3>LINKS TO OTHER SITES</h3>
          <p>
            The Services contain links to other websites and we may allow advertisers, third-party
            advertising networks and third-party advertising serving companies to serve
            advertisement directly to you within the Services. If you choose to visit an advertiser
            by clicking on a banner ad or other type of advertisement, or click on another
            third-party link, you will be directed to that third party’s website. We do not exercise
            control over third-party websites. These other websites may place their own cookies or
            other files on your computer, collect data or solicit personal information from you.
            <br />
            <br />
            This Privacy Policy addresses the use and disclosure of information that we collect from
            you through our Services and does not apply to, and we cannot control the activities of,
            such other advertisers or websites. Other sites follow different rules regarding the use
            or disclosure of the personal information you submit to them. We encourage you to read
            the privacy policies or statements of such other websites you visit.
            <br />
            <br />
            PLAYCH reserves the right to add or remove third-party ad networks or ad servers in its
            sole discretion and may not list, at all times, such updated ad network or ad server
            partners in this Privacy Policy.
          </p>

          <h3>OUR POLICY TOWARDS CHILDREN</h3>
          <p>
            If you are under the age of 13, please do not use or access the Services at any time or
            in any manner. PLAYCH does not knowingly collect Personal Information from children
            under the age of 13. If a parent or guardian becomes aware that his or her child has
            provided us with Personal Information without his/her consent, he or she should contact
            us at{' '}
            <a href='mailto:contact@playch.tv' target='_blank' rel='noopener noreferrer'>
              contact@playch.tv
            </a>
            . If we become aware that a child under the age of 13 has provided us with Personal
            Information, we will delete such information from our files and terminate the account
            immediately.
          </p>

          <h3>CONTACTING US</h3>
          <p>
            If you have any questions about this Privacy Policy, please contact us at{' '}
            <a href='mailto:contact@playch.tv' target='_blank' rel='noopener noreferrer'>
              contact@playch.tv
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default PrivacyPolicy;
