import React, { FC } from 'react';

export const Sponsors: FC = () => {
  return (
    <section className='landing-section landing-section--backers'>
      <div className='container container--main'>
        <h2 className='home-title'>Sponsors & Backers</h2>
        <div className='info-text info-text--ta-center info-text--width-1090 '>
          <p>
            Project Development would not have been possible without the support by the awesome
            sponsors and backers listed below.
          </p>
        </div>
        <div className='backers-box'>
          <form className='form form--backers'>
            <div className='form-icon'>
              <img loading='lazy' src='./images/backer-form-icon.svg' alt='' />
            </div>
            <div className='form-text form-text--ta-center form-text--color2'>
              <p>There are no sponsors or backers yet :(</p>
            </div>
            <div className='form__body'>
              <h2 className='form-subtitle'>If you want to support the project</h2>
              <div className='support-project'>
                <a
                  href='/'
                  className='button button--full-width   button--buy-coffee button--buy-coffee-size2 '
                >
                  <img loading='lazy' src='./images/donate-coffee.png' alt='buy me a coffee' />
                </a>
                <div className='cred-or cred-or--form '>
                  <div className='cred-or__text'>Or</div>
                </div>
                <a
                  href='/'
                  className='button button--full-width button--patreon button--patreon-size2'
                >
                  <img loading='lazy' src='./images/donate-patreon2.png' alt='patreon' />
                </a>
              </div>
              <div className='form-text form-text--ta-center form-text--color2'>
                <p>If you have any questions please contact us</p>
              </div>
            </div>
            <div className='form-submit'>
              <button className='button button--type3 button--full-width' type='button'>
                Contact us
              </button>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
};
