import { DonateSvg } from '../../../assets/svg-icon';
import React, { ChangeEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { notificationContainer } from '../../../ui/notificationContainer';
import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import { getPeymentLink } from '../../../store/redux-toolkit/peyments/updates-thunks';
import { BakePopUp } from '../../../ui/BakePopUp/BakePopUp';
import { getLoaderSelector } from '../../../store/redux-toolkit/peyments/updates-selector';
import avatar from '../../../assets/images/default-avatar.png';

interface IProps {
  name: string | null | undefined;
  urlImg: string | null | undefined;
}

export const HeaderBar: React.FC<IProps> = ({ name, urlImg }) => {
  const { t } = useTranslation();
  const [amount, setAmount] = useState<string>('5');
  const dispatch = useAppDispatch();
  const [open, setOpen] = useState(false);
  const [num, setNum] = useState<string>('5');
  const loading = useAppSelector(getLoaderSelector);

  const onGetSum = (event: ChangeEvent) => {
    const { id } = event.target as HTMLInputElement;
    setNum(id);
    setAmount(typeof +id === 'number' ? id : '1');
  };

  const onSubmit = () => {
    if (+amount < 1) {
      notificationContainer('Page.Project.number_must', 'info');
      return;
    }

    dispatch(
      getPeymentLink({
        close: setOpen,
        api: {
          amount: +amount || +num,
          currency: 'usd',
        },
      })
    );
  };

  const onBackProject = () => {
    setOpen(true);
  };

  const handleInput = (event: ChangeEvent) => {
    const { value } = event.target as HTMLInputElement;
    setAmount(value);
  };

  const onClosePpopUp = () => {
    setOpen(false);
  };

  return (
    <div className='profile-bar__top'>
      {open ? (
        <BakePopUp
          onSubmit={onSubmit}
          onGetSum={onGetSum}
          onClosePpopUp={onClosePpopUp}
          handleInput={handleInput}
          num={num}
          amount={amount}
          loading={loading}
        />
      ) : null}
      <div className='profile-user'>
        <div className='my-avatar my-avatar--size-90'>
          <div className='d-flex my-avatar__inner'>
            <img loading='lazy' src={urlImg || avatar} alt='avatar' />
          </div>
        </div>
        <div className='profile-user__info'>
          <p className='profile-user__name'>{name}</p>
          <div onClick={onBackProject} className='profile-status profile-status-custom'>
            <div className='d-flex profile-status__icon'>
              <DonateSvg />
            </div>
            {t('Page.Project.back_this_project')}
          </div>
        </div>
      </div>
      {/* <div className='input input--mt-24 input--mb-0'>
        <div className='input-wrapper'>
          <input className='input-item input-item--pr-90' type='text' placeholder='Search' />
          <div className='input-actions'>
            <div className='input-icon input-icon--dark'>
              <SearchSvg />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
};
