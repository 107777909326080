import React, { FC } from 'react';
import { changePassShema } from '../../../form-validations/login-validation';
import { useTranslation } from 'react-i18next';
import { Field, Form, Formik } from 'formik';
import { IPropsResetPass, IResetPass } from '../../../pages/Auth/ResetPassword/types';
import PasswordField from '../../../ui/Formik/PasswordField';
import { LoadingSmall } from '../../../ui/LoadingSmall/LoadingSmall';
import { LoadingStatus } from '../../../enum/types';
import { useAppSelector } from '../../../hooks/redux';

const initialValues = {
  password: '',
  password_confirmation: '',
};

export const ResetPasswordForm: FC<IPropsResetPass> = ({ resetPassSubmit }) => {
  const { t } = useTranslation();
  const { loadingState } = useAppSelector((store) => store.auth);

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={changePassShema}
      onSubmit={(values: IResetPass, { setSubmitting }) => {
        resetPassSubmit(values);
        setSubmitting(false);
      }}
      validateOnBlur
      enableReinitialize
    >
      {({ isSubmitting, isValid, dirty }) => {
        return (
          <Form className='form form--authorization'>
            <h2 className='form-title'>Reset password</h2>
            <div className='form-text form-text--ta-center form-text--color2 form-text--fw-700'>
              <p>Enter a new account password</p>
            </div>
            <div className='form__body'>
              <div className='input'>
                <Field
                  className='input-item input-item--pr-90'
                  name='password'
                  component={PasswordField}
                  placeholder={t('Forms.Sign_up.password')}
                />
              </div>
              <div className='input'>
                <Field
                  className='input-item input-item--pr-90'
                  name='password_confirmation'
                  component={PasswordField}
                  placeholder={t('Forms.Sign_up.password')}
                />
              </div>
            </div>
            <div className='form-submit'>
              <button
                disabled={!(isValid && dirty) || isSubmitting}
                className={`button button--full-width ${
                  loadingState === LoadingStatus.LOADING ? 'button--loading' : ''
                }`}
                type='submit'
              >
                {loadingState === LoadingStatus.LOADING ? <LoadingSmall /> : 'Reset Password'}
              </button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
