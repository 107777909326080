import { AxiosError, isAxiosError } from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import requestService from '../../../../services/http/interceptor/request-service';
import { showError } from '../../../../utils/showError';
import { activePopup, clearErrors } from './account-slice';
import { endpoint } from '../../../../services/endpoint';
import {
  IEmailChangeSubmitValue,
  IProfileError,
  IProfileRequst,
  IProfileRespone,
  IRequestDeleteUser,
  IUserNameChangeThunk,
} from './account-types';
import { changeAvatar } from '../../../../store/redux-toolkit/auth/auth-slice';
import { logout } from '../../../../store/redux-toolkit/auth/auth-thunks';

export const getProfile = createAsyncThunk<IProfileRespone>(
  'settingsAccount/getProfile',
  async (_, thunkApi) => {
    try {
      const response = await requestService.get(endpoint.settings.GET_PROFILE);
      thunkApi.dispatch(clearErrors());
      return response.data.data;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const deleteProfile = createAsyncThunk(
  'settingsAccount/deleteProfile',
  async (payload: IRequestDeleteUser, thunkApi) => {
    const { id, token } = payload;
    try {
      const response = await requestService.delete(endpoint.settings.GET_PROFILE, id);
      thunkApi.dispatch(clearErrors());
      thunkApi.dispatch(logout(token));
      return response.data.data;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const postProfile = createAsyncThunk(
  'settingsAccount/postProfile',
  async (payload: IProfileRequst, thunkApi) => {
    try {
      const response = await requestService.post(endpoint.settings.GET_PROFILE, payload);
      thunkApi.dispatch(changeAvatar(response.data.data.avatar_url));
      thunkApi.dispatch(clearErrors());
      return response.data.data;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const postUsername = createAsyncThunk(
  'settingsAccount/postUsername',
  async (payload: IUserNameChangeThunk, thunkApi) => {
    const { paramsApi, changeUsername } = payload;
    try {
      await requestService.post(endpoint.settings.POST_USERNAME, paramsApi);
      thunkApi.dispatch(clearErrors());
      thunkApi.dispatch(activePopup(''));
      changeUsername(paramsApi.username);
      return paramsApi.username;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);

export const postEmail = createAsyncThunk(
  'settingsAccount/postEmail',
  async (payload: IEmailChangeSubmitValue, thunkApi) => {
    try {
      const response = await requestService.post(endpoint.settings.POST_EMAIL, payload);
      thunkApi.dispatch(clearErrors());
      thunkApi.dispatch(activePopup(''));
      return payload.email;
    } catch (error) {
      const err = error as IProfileError;

      if (isAxiosError(error)) {
        if (error.response?.data) {
          showError(error);
        }
      }

      return thunkApi.rejectWithValue(err);
    }
  }
);
