import { RootState } from '../../../store/rootReducer';
import { IBackersResponse, IBackersState } from './backers-types';

export const selectBakersState = (state: RootState): IBackersState => state.backersSlice;
export const getBackersPlatinumSelector = (state: RootState): IBackersResponse | null =>
  selectBakersState(state).backers_platinum;
export const getBackersGoldSelector = (state: RootState): IBackersResponse | null =>
  selectBakersState(state).backers_gold;
export const getBackersSilverSelector = (state: RootState): IBackersResponse | null =>
  selectBakersState(state).backers_silver;
export const getBackersBronzeSelector = (state: RootState): IBackersResponse | null =>
  selectBakersState(state).backer_bronze;
export const getBackersGenerousSelector = (state: RootState): IBackersResponse | null =>
  selectBakersState(state).backer_generous;
export const getBackersBackerSelector = (state: RootState): IBackersResponse | null =>
  selectBakersState(state).backer;
