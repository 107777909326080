import { AvatarDropSvg, CalenderSvg, EditSvg } from '../../../../assets/svg-icon';
import { Field } from 'formik';
import { useCountries } from '../../../../pages/SettingsPages/AccountPage/AccountPage';
import React, { FC, useState, useCallback } from 'react';
import { Calendar } from '../../../../ui/Calendar/Calendar';
import Input from '../../../../ui/Formik/Input';
import { GeneralInfo } from './GeneralInfo/GeneralInfo';
import { PopupAddPhoto } from '../../../../ui/PopupAddPhoto/PopupAddPhoto';
import { toBase64 } from '../../../../utils/files';
import { Link } from 'react-router-dom';
import { ERoutes } from '../../../../enum/types';
import { useTranslation } from 'react-i18next';
import FileUploader from '../../../../ui/DragDropFiles/FileUploader';

const fileTypes = ['JPG', 'PNG', 'JPEG', 'BMP'];

export const Information: FC = () => {
  const { values, addDate, addLan, addAvatar, hideAvatar, setSize, size } = useCountries();
  const [file, setFile] = useState<Blob | null>(null);
  const [image, setImage] = useState(null);
  const [keyInput, setKeyInput] = useState('');
  const [resetRef, setResetRef] = useState<HTMLInputElement | null>(null);
  const { t } = useTranslation();

  const handleChange = (img: any) => {
    setFile(img);
    setSize(img.size);

    // @ts-ignore
    setImage(URL.createObjectURL(img));
  };

  // const changePhoto = () => {
  //   fetch('http://localhost:8100/assets/audios/english_test.wav')
  //     .then(res => res.blob()) // Gets the response and returns it as a blob
  //     .then(blob => {
  //       this.sendToWebsocket(blob);
  //     });
  // }

  const onSaveImage = async (value: any) => {
    const file =
      value &&
      (await fetch(value)
        .then((r) => r.blob())
        .then((blobFile) => new File([blobFile], 'avatar', { type: blobFile.type })));

    addAvatar(await toBase64(file));
    setFile(null);
  };

  const onClosePopup = useCallback(() => {
    setFile(null);
    setSize(0);
    setImage(null);
    //@ts-ignore
    if (resetRef) resetRef.current.value = '';
  }, []);

  const removeAvatar = () => {
    addAvatar('');
    hideAvatar();
    setSize(0);
  };

  const resetInput = (value: any) => {
    setResetRef(value);
    setSize(0);
  };

  return (
    <div className='tab-item active'>
      <GeneralInfo addLan={addLan} profileLan={values.language} />
      <div className='content-block content-block--type2 '>
        <h2 className='block-title'>{t('Page.Settings.Account.personal_details')}</h2>
        <div className='block-text'>
          <p>{t('Page.Settings.Account.personal_details_subtitle')}</p>
          <p>
            {t('Page.Settings.Account.personal_details_subtitle_view')}
            <Link to={ERoutes.privacyPolicy}>{t('Footer.privacy_policy')}</Link>
          </p>
        </div>
        <div className='content-body'>
          <div className='account-wrap'>
            <div className='account-row'>
              <div className='form-group'>
                <div className='input '>
                  <p className='input-name'>
                    <span className='req'>*</span>
                    {t('Page.Settings.Account.first_name')}
                  </p>
                  <div className='input-wrapper'>
                    <Field
                      name='first_name'
                      className='input-item '
                      type='text'
                      placeholder={t('Page.Settings.Account.first_name')}
                      component={Input}
                    />
                  </div>
                </div>
                <div className='input '>
                  <p className='input-name'>
                    <span className='req'>*</span>
                    {t('Page.Settings.Account.last_name')}
                  </p>
                  <div className='input-wrapper'>
                    <Field
                      name='last_name'
                      className='input-item '
                      type='text'
                      placeholder={t('Page.Settings.Account.last_name')}
                      component={Input}
                    />
                  </div>
                </div>
                <div className='input input--mb-0 '>
                  <p className='input-name'>
                    {/* <span className='req'>*</span> */}
                    {t('Page.Settings.Account.age')}
                  </p>
                  <div className='input-wrapper'>
                    <Calendar
                      startDate={values.birth_date}
                      addDate={addDate}
                      className='input-item--pr-60'
                    />
                    <div className='input-actions input-actions-custom'>
                      <span className='input-icon input-icon-custom'>
                        <CalenderSvg />
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              {values.image_base64 ? (
                <div className='form-group'>
                  <div className='input input--mb-0 '>
                    <p className='input-name'>{t('Page.Settings.Account.avatar')}</p>
                    <div className='uploaded-photo'>
                      <div className='my-avatar my-avatar--size-170'>
                        <div className='d-flex my-avatar__inner'>
                          <img loading='lazy' src={values.image_base64} alt='avatar' />
                        </div>
                      </div>
                      <div className='d-flex uploaded-photo__actions'>
                        <button type='button' className='text-btn text-btn-custom'>
                          <FileUploader
                            classes='drag-n-drop'
                            handleChange={handleChange}
                            resetInput={resetInput}
                            name='file'
                            types={fileTypes}
                          />
                          {t('Page.Settings.Account.btn_avatar_changes')}
                        </button>
                        <p>{t('Page.Settings.Account.btn_avatar_or')}</p>
                        <button
                          onClick={() => removeAvatar()}
                          className='text-btn text-btn--type2'
                          type='button'
                        >
                          {t('Page.Settings.Account.btn_avatar_delete')}
                        </button>
                      </div>
                      <p className='input-notification input-notification--mt-4 input-notification--type2'>
                        {t('Page.Settings.Account.avatar_file_limit')}
                      </p>
                    </div>
                  </div>
                  <div className='error-sms'>
                    {size < 124000 ? null : t('Errors.error_big_image')}
                  </div>
                </div>
              ) : (
                <div className='form-group'>
                  <div className='input input--mb-0 '>
                    <p className='input-name'>{t('Page.Settings.Account.avatar')}</p>
                    <div className='input-wrapper'>
                      <label className='upload-file-box'>
                        <FileUploader
                          classes='drag-n-drop'
                          handleChange={handleChange}
                          resetInput={resetInput}
                          name='file'
                          types={fileTypes}
                        />
                        <span className='upload-file'>
                          <span className='d-flex upload-file__icon'>
                            <AvatarDropSvg />
                          </span>
                          <span className='upload-file__title'>
                            {t('Page.Settings.Account.avatar_info')}
                          </span>
                          <span className='upload-file__label'>
                            {t('Page.Settings.Account.btn_avatar_select')}
                          </span>
                        </span>
                      </label>
                    </div>
                    <p className='input-notification input-notification--mt-4 input-notification--type2'>
                      {t('Page.Settings.Account.avatar_file_limit')}
                    </p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      {file && (
        <PopupAddPhoto image={image} onSaveImage={onSaveImage} onClosePopup={onClosePopup} />
      )}
    </div>
  );
};
