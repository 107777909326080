import { BurgerCloseSvg, BurgerSvg } from '../../assets/svg-icon';
import classNames from 'classnames';
import React, { FC, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setBurger } from '../../store/redux-toolkit/app/app-slice';
import { onScrollDown } from '../../utils/scrollY';

interface IProps {
  activeBurger: boolean;
}

export const BtnBurger: FC<IProps> = ({ activeBurger }) => {
  const dispatch = useDispatch();

  const onBurger = () => {
    onScrollDown();
    dispatch(setBurger(!activeBurger));

    if (!activeBurger) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  };

  return (
    <button
      onClick={onBurger}
      type='button'
      className={`nav-btn ${activeBurger ? 'active' : null}`}
    >
      <BurgerSvg className='nav-btn__open' height={'3em'} width={'3em'} />
      <BurgerCloseSvg className='nav-btn__close' height={'3em'} width={'3em'} />
    </button>
  );
};
