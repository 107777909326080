import React, { FC, RefObject } from 'react';
import { ContactUsForm } from '../../components/Forms/ContactUsForm/ContactUsForm';
import { IContactUsRequest } from '../../store/redux-toolkit/contactUs/contactUs-types';
import { useAppDispatch } from '../../hooks/redux';
import { postContactUs } from '../../store/redux-toolkit/contactUs/contactUs-thunks';
import type HCaptcha from '@hcaptcha/react-hcaptcha';

const ContactUs: FC = () => {
  const dispatch = useAppDispatch();

  const handleLoginSubmit = (
    values: IContactUsRequest,
    resetForm: () => void,
    captchaRef: RefObject<HCaptcha> | null
  ): void => {
    dispatch(postContactUs({ paramsApi: values, resetForm, captchaRef }));
  };

  return (
    <div className='container container--main'>
      <div className='contact-us'>
        <div className='d-flex contact-us__info'>
          {/* <a href='/' className='logo'>
              <img loading="lazy" src={logo} alt='logo' />
            </a> */}
        </div>
        <div className='d-flex contact-us__form'>
          <ContactUsForm loginSubmit={handleLoginSubmit} />
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
