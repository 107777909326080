import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../enum/types';
import { IMetricsResponse, IPostsResponse, IUpdatesState } from './updates-types';
import { getListPosts, getMetrics, postUpdatesLike } from './updates-thunks';

const initialState: IUpdatesState = {
  loadingState: LoadingStatus.NEVER,
  posts: null,
  metrics: null,
  success: '',
  error: '',
  errors: null,
};

const updatesSlice = createSlice({
  name: 'updates',
  initialState,
  reducers: {
    resetUpdatesUsState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
    editLike: (state, { payload }: PayloadAction<string>) => {
      if (state.posts?.data) {
        state.posts.data.data.forEach((post) => {
          if (post.uuid === payload) post.likes ? (post.likes = 0) : (post.likes = 1);
        });
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postUpdatesLike.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postUpdatesLike.fulfilled, (state, { payload }) => {
        state.loadingState = LoadingStatus.SUCCESS;
      })
      .addCase(postUpdatesLike.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(getListPosts.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getListPosts.fulfilled, (state, { payload }: PayloadAction<IPostsResponse>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        state.posts = payload;
      })
      .addCase(getListPosts.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      })
      .addCase(getMetrics.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(getMetrics.fulfilled, (state, { payload }: PayloadAction<IMetricsResponse>) => {
        state.loadingState = LoadingStatus.SUCCESS;
        state.metrics = payload.data;
      })
      .addCase(getMetrics.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      });
  },
});

export const { resetUpdatesUsState, clearErrors, clearSuccess, editLike } = updatesSlice.actions;
export default updatesSlice.reducer;
