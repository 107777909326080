import * as Yup from 'yup';

export const loginSchema = Yup.object().shape({
  username: Yup.string()
    .required('Validation.username')
    .min(3, 'Validation.min_usernmae')
    .email((el) => {
      if (el.originalValue.includes('@')) {
        return el.regex.test(el.originalValue) ? '' : 'Validation.email_format';
      }
      return;
    }),
  password: Yup.string().required('Validation.password_required'),
  rememberMe: Yup.string(),
});

export const resetSchema = Yup.object().shape({
  email: Yup.string().email('Validation.email_format').required('Validation.required_email'),
});

export const changePassShema = Yup.object().shape({
  password: Yup.string()
    .matches(/^\S*$/, 'Whitespace is not allowed')
    .matches(
      // eslint-disable-next-line no-useless-escape
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
      'Validation.password_validation'
    )
    .required('Validation.password_required')
    .max(25, 'Validations.password_max_length'),
  password_confirmation: Yup.string()
    .required(String('Validation.password_confirm'))
    .oneOf([Yup.ref('password'), null], String('Validation.password_match')),
});

export const changeSettingPassShema = Yup.object().shape({
  old_password: Yup.string().required('Validation.password_required'),
  password: Yup.string()
    .matches(/^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9]).{8,}$/, 'Validation.password_validation')
    .required('Validation.password_required')
    .max(25, 'Validations.password_max_length'),
  confirmPassword: Yup.string()
    .required(String('Validation.password_confirm'))
    .oneOf([Yup.ref('password'), null], String('Validation.password_match')),
});
