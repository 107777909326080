import React, { FC, useEffect } from 'react';
import { ModulesList } from './ModulesList/ModulesList';
import star from '../../../assets/images/project-title-icon2.png';
import modules from '../../../assets/images/project-modules1.png';
import modules2 from '../../../assets/images/project-modules2.png';
import modulesFrame from '../../../assets/images/modules-frame.png';
import { useTranslation } from 'react-i18next';
import { ImageMotion } from '../../../ui/ImageMotion/ImageMotion';

export const Modules: FC = () => {
  const { t } = useTranslation();

  return (
    <div className='project-modules-sectin'>
      <div className='container container--main'>
        <div className='project-info project-info--type2 project-info--modules'>
          <div className='project-text-box'>
            <div className='project-title-block'>
              <h2 className='project-title'>{t('Page.Project.modules_block_title')}</h2>
              <div className='project-title-icon'>
                <img loading='lazy' src={star} alt='icon' />
              </div>
            </div>
            {/* <div className='project-text project-text--max-with'>
              <h3 className='info-subtitle info-subtitle--size2 info-subtitle--mb-24'>
                {t('Page.Project.modules_block_sub')}
              </h3>
              <div className='info-text'>
                <p>{t('Page.Project.modules_block_desc')}</p>
              </div>
              <div className='info-text info-text--type2 info-text-mt-8'>
                <p>{t('Page.Project.modules_block_desc_2')}</p>
              </div>
            </div> */}
          </div>
          <div className='project-img project-img--modules'>
            <div className='d-flex project-img__item'>
              <ImageMotion src={modules} height={375} width={761} alt='project' transition={2} />
            </div>
            <div className='d-flex project-img__item'>
              <ImageMotion src={modules2} height={375} width={633} alt='project' transition={2} />
            </div>
          </div>
        </div>
        <ModulesList />
      </div>
    </div>
  );
};
