import React, { FC } from 'react';
import backer from '../../../assets/images/Backer.png';
import Bronze from '../../../assets/images/Bronze-Backer.png';
import Generous from '../../../assets/images/Generous-Backer.png';
import Silver from '../../../assets/images/Silver-Backer.png';
import { FacebookSvg, InstagramSvg, TwitterSvg } from '../../../assets/svg-icon';
import { IBackers } from '../../../store/redux-toolkit/backers/backers-types';
import { ELevel } from '../../../enum/levels';

interface IProps {
  backers_silver: undefined | Array<IBackers>;
}

export const BackersSilverBlok: FC<IProps> = ({ backers_silver }) => {
  const getPhotoBaker = (level: string) => {
    switch (level) {
      case ELevel.LEVEL_BAKCER_SILVER:
        return Silver;
      case ELevel.LEVEL_BAKCER_GENEROUS:
        return Generous;
      case ELevel.LEVEL_BAKCER_BRONZE:
        return Bronze;
      default:
        return backer;
    }
  };

  return (
    <div className='backers-list backers-list--like-list'>
      {backers_silver?.map((item, index) => (
        <div key={index} className='backer backer--list-type'>
          <div className='d-flex backer__info-box'>
            <div className='d-flex backer__logo-wrap'>
              <div className='d-flex backer__logo'>
                <img loading='lazy' src={item.avatar_url} alt='sponsor' />
              </div>
            </div>
            <div className='d-flex backer__lvl'>
              <img loading='lazy' src={getPhotoBaker(item.level)} alt='lvl' />
            </div>
            <div className='backer__details'>
              <div className='backer__info'>
                <p>{item.company_name || item.username}</p>
              </div>
              <div className='backer-extra'>
                <p className='backer-extra__date'>November 16, 2024</p>
                <span className='backer-extra__label'>on</span>
                <div className='d-flex backer-extra__logo'>
                  {/* <img loading="lazy" src={patreon} alt='patreon' /> */}
                  <span className='backer-extra__label'>
                    {item.sponsored_by[0].toUpperCase() +
                      item.sponsored_by.replaceAll('_', ' ').slice(1)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div className='d-flex backer__social'>
            <div className='footer-social footer-social--backers'>
              <a
                href='/'
                className='d-flex footer-social__link d-flex footer-social__link--facebook'
              >
                <FacebookSvg />
              </a>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
