import React, { FC, useState } from 'react';

import team1h_png from '../../assets/images/team1__hover.png';
import team2h_png from '../../assets/images/team2__hover.png';
import team3h_png from '../../assets/images/team3__hover.png';
import team4h_png from '../../assets/images/team4__hover.png';
import team5h_png from '../../assets/images/team5_hover.png';

import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

export const Team: FC = () => {
  const { t } = useTranslation();
  const [active, setActive] = useState<number>(0);

  const onActive = (num: number) => {
    setActive((prev) => (prev === num ? 0 : num));
  };

  return (
    <>
      <div className='team-banner'>
        <canvas id='canvas' height='0'></canvas>
        <div className='container container--main'>
          <h2 className='home-title'>{t('Page.Team.header')}</h2>
          <div className='info-text info-text--ta-center info-text--width-1090 '>
            <p>{t('Page.Team.description')}</p>
          </div>
        </div>
      </div>
      <div className='container container--main'>
        <div className='team-list'>
          <div
            onClick={() => onActive(1)}
            className={classNames('team-member', { active: active === 1 })}
          >
            <div className='d-flex team-member__photo'>
              <img className='team-member__photo-default' src={team1h_png} alt='team' />
              <img className='team-member__photo-hover' src={team1h_png} alt='team' />
            </div>
            <div className='team-member__info'>
              <p className='team-member__name'>{t('Page.Team.Ostrogliad.name')}</p>
              <p className='team-member__role'>{t('Page.Team.Ostrogliad.position')}</p>
            </div>
            <div className='team-extra'>
              <p>{t('Page.Team.Ostrogliad.bio')}</p>
            </div>
          </div>

          <div
            onClick={() => onActive(3)}
            className={classNames('team-member', { active: active === 3 })}
          >
            <div className='d-flex team-member__photo'>
              <img className='team-member__photo-default' src={team2h_png} alt='team' />
              <img className='team-member__photo-hover' src={team2h_png} alt='team' />
            </div>
            <div className='team-member__info'>
              <p className='team-member__name'>{t('Page.Team.Marchenko.name')}</p>
              <p className='team-member__role'>{t('Page.Team.Marchenko.position')}</p>
            </div>
            <div className='team-extra'>
              <p>{t('Page.Team.Marchenko.bio')}</p>
            </div>
          </div>

          <div
            onClick={() => onActive(2)}
            className={classNames('team-member', { active: active === 2 })}
          >
            <div className='d-flex team-member__photo'>
              <img className='team-member__photo-default' src={team3h_png} alt='team' />
              <img className='team-member__photo-hover' src={team3h_png} alt='team' />
            </div>
            <div className='team-member__info'>
              <p className='team-member__name'>{t('Page.Team.Davidenko.name')}</p>
              <p className='team-member__role'>{t('Page.Team.Davidenko.position')}</p>
            </div>
            <div className='team-extra'>
              <p>{t('Page.Team.Davidenko.bio')}</p>
            </div>
          </div>
          <div
            onClick={() => onActive(4)}
            className={classNames('team-member', { active: active === 4 })}
          >
            <div className='d-flex team-member__photo'>
              <img className='team-member__photo-default' src={team4h_png} alt='team' />
              <img className='team-member__photo-hover' src={team4h_png} alt='team' />
            </div>
            <div className='team-member__info'>
              <p className='team-member__name'>{t('Page.Team.Smikhotur.name')}</p>
              <p className='team-member__role'>{t('Page.Team.Smikhotur.position')}</p>
            </div>
            <div className='team-extra'>
              <p>{t('Page.Team.Smikhotur.bio')}</p>
            </div>
          </div>
          <div
            onClick={() => onActive(5)}
            className={classNames('team-member', { active: active === 5 })}
          >
            <div className='d-flex team-member__photo'>
              <img loading='lazy' src={team5h_png} alt='team' />

              <img className='team-member__photo-hover' src={team5h_png} alt='team' />
            </div>
            <div className='team-member__info'>
              <p className='team-member__name'>{t('Page.Team.Bubnov.name')}</p>
              <p className='team-member__role'>{t('Page.Team.Bubnov.position')}</p>
            </div>
            <div className='team-extra'>
              <p>{t('Page.Team.Bubnov.bio')}</p>
            </div>
          </div>
          {/* <div
            onClick={() => onActive(6)}
            className={classNames('team-member', { active: active === 6 })}
          >
            <div className='d-flex team-member__photo'>
              <img className='team-member__photo-default' src={team6h_png} alt='team' />
              <img className='team-member__photo-hover' src={team6h_png} alt='team' />
            </div>
            <div className='team-member__info'>
              <p className='team-member__name'>{t('Page.Team.Shinkorenko.name')}</p>
              <p className='team-member__role'>{t('Page.Team.Shinkorenko.position')}</p>
            </div>
            <div className='team-extra'>
              <p>{t('Page.Team.Shinkorenko.bio')}</p>
            </div>
          </div> */}
          {/* <div
            onClick={() => onActive(7)}
            className={classNames('team-member', { active: active === 7 })}
          >
            <div className='d-flex team-member__photo'>
              <img loading="lazy" src={isWebpSupported() ? team7 : team7_png} alt='team' />
              <img
                className='team-member__photo-hover'
                src={isWebpSupported() ? team7h : team7h_png}
                alt='team'
              />
            </div>

            <div className='team-member__info'>
              <p className='team-member__name'>{t('Page.Team.Sivak.name')}</p>
              <p className='team-member__role'>{t('Page.Team.Sivak.position')}</p>
            </div>
            <div className='team-extra'>
              <p>{t('Page.Team.Sivak.bio')}</p>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};
