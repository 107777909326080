/* eslint-disable @typescript-eslint/no-explicit-any */

export const toBase64 = (blob: Blob): Promise<any> => {
  return new Promise((res, rej) => {
    const reader = new FileReader();
    reader.readAsDataURL(blob);
    reader.onload = () => res(reader.result);
    reader.onerror = (error) => rej(error);
  });
};

export const downloadFile = (path: string) => {
  const link = document.createElement('a');
  link.href = path;
  link.target = '__blank';
  link.download = '';
  link.click();
};
