import { Field } from 'formik';
import { useCountries } from '../../../../pages/SettingsPages/AccountPage/AccountPage';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CountrySelect } from '../../../../ui/Countries/Countries';
import Input from '../../../../ui/Formik/Input';
import { IPropsAccount } from '../types';

export const Address: FC = () => {
  const { addCountry, values } = useCountries();
  const { t } = useTranslation();
  return (
    <div className='tab-item active'>
      <div className='content-block'>
        <div className='account-wrap'>
          <div className='account-row'>
            <div className='form-group'>
              <div className='input input--mb-0'>
                <p className='input-name'>{t('Page.Settings.Account.address_line_1')}</p>
                <div className='input-wrapper'>
                  <Field
                    className='input-item '
                    type='text'
                    placeholder={t('Page.Settings.Account.address_line_1')}
                    name='address_line1'
                    component={Input}
                  />
                </div>
              </div>
            </div>
            <div className='form-group'>
              <div className='input input--mb-0'>
                <p className='input-name'>{t('Page.Settings.Account.address_line_2')}</p>
                <div className='input-wrapper'>
                  <Field
                    className='input-item '
                    type='text'
                    placeholder={t('Page.Settings.Account.address_line_2')}
                    name='address_line2'
                    component={Input}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className='account-row'>
            <div className='form-group'>
              <div className='input input--mb-0'>
                <p className='input-name'>{t('Page.Settings.Account.city')}</p>
                <div className='input-wrapper'>
                  <Field
                    className='input-item '
                    type='text'
                    placeholder={t('Page.Settings.Account.city')}
                    name='city'
                    component={Input}
                  />
                </div>
              </div>
            </div>
            <div className='input-line'>
              <div className='form-group'>
                <div className='input input--mb-0'>
                  <p className='input-name'>{t('Page.Settings.Account.region')}</p>
                  <div className='input-wrapper'>
                    <Field
                      className='input-item '
                      type='text'
                      placeholder={t('Page.Settings.Account.region')}
                      name='region'
                      component={Input}
                    />
                  </div>
                </div>
              </div>
              <div className='form-group'>
                <div className='input input--mb-0'>
                  <p className='input-name'>{t('Page.Settings.Account.postal_code')}</p>
                  <div className='input-wrapper'>
                    <Field
                      className='input-item '
                      type='text'
                      placeholder={t('Page.Settings.Account.postal_code_index')}
                      name='postal_code'
                      component={Input}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='account-row'>
            <div className='form-group'>
              <div className='select-block select-block--mb-0'>
                <p className='input-name'>{t('Page.Settings.Account.country')}</p>
                <Field
                  name='country'
                  className='input-item'
                  type='text'
                  component={CountrySelect}
                  addCountry={addCountry}
                  country={values.country}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
