export const authPathname = [
  '/login',
  '/registration',
  '/email-verify',
  '/forgot-password',
  '/password/reset',
  // '/faq',
];

export const authInfo = ['/registration', '/password/reset'];
