import React, { FC, MouseEvent } from 'react';
import { LanUkrSvg, LanUsaSvg } from '../../../assets/svg-icon';

interface IProps {
  onOpenPopupLan: (event: MouseEvent) => void;
}

export const LanguagePopup: FC<IProps> = ({ onOpenPopupLan }) => (
  <div className='dropdown dropdown--lang'>
    <ul className='dropdown-list'>
      <li>
        <button name='en' onClick={onOpenPopupLan} type='button'>
          <span className='dropdown-icon'>
            <LanUsaSvg />
          </span>
          English
        </button>
      </li>
      <li>
        <button name='ua' onClick={onOpenPopupLan} type='button'>
          <span className='dropdown-icon'>
            <LanUkrSvg />
          </span>
          Ukrainian
        </button>
      </li>
    </ul>
  </div>
);
