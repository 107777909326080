import { LoginForm } from '../../../components/Forms/LoginForm';
import { ILoginSubmitValue } from '../../../components/Forms/LoginForm/types';

import { useAppDispatch, useAppSelector } from '../../../hooks/redux';
import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../store/redux-toolkit/auth/auth-thunks';

export const Login: FC = () => {
  const { isAuth } = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const handleLoginSubmit = (values: ILoginSubmitValue, totp?: string): void => {
    const { password, rememberMe, username } = values;

    dispatch(
      login({ password, rememberMe, [username?.includes('@') ? 'email' : 'username']: username })
    );
  };

  useEffect(() => {
    if (isAuth) {
      navigate('/');
    }
  }, [isAuth, navigate]);

  return (
    <section className='authorization-section'>
      <div className='authorization'>
        <LoginForm loginSubmit={handleLoginSubmit} />
      </div>
    </section>
  );
};
