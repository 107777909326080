import { Address } from '../components/Settings/Account/Address/Address';
import { Information } from '../components/Settings/Account/Information/Information';
import { ChangePassword } from '../components/Settings/Security/ChangePassword/ChangePassword';
import { TwoFactor } from '../components/Settings/Security/TwoFactor/TwoFactor';

import AccountPage from '../pages/SettingsPages/AccountPage/AccountPage';
import { MyComments } from '../pages/SettingsPages/MyComments/MyComments';
import { NotificationPage } from '../pages/SettingsPages/NotificationPage/NotificationPage';
import { PaymentPage } from '../pages/SettingsPages/PaymentPage/PaymentPage';
import { SecurityPage } from '../pages/SettingsPages/SecurityPage/SecurityPage';
import { TransactionPage } from '../pages/SettingsPages/TransactionPage/TransactionPage';
import { Navigate } from 'react-router-dom';

import { ERoutes } from '../enum/types';
import React, { ReactElement } from 'react';
import { Page404 } from '../pages/404Page/404Page';

interface IRoutes {
  element: ReactElement;
  path: string;
  children?: Array<{
    element: any;
    path: string;
  }>;
}

export const SettingsRoutes: Array<IRoutes> = [
  {
    element: <AccountPage />,
    path: ERoutes.account,
    children: [
      { element: <Information />, path: ERoutes.accountInformationTab },
      { element: <Address />, path: ERoutes.accountAddressTab },
      {
        element: <Navigate to={ERoutes.accountInformationTab} replace />,
        path: ERoutes.account,
      },
    ],
  },
  {
    element: <SecurityPage />,
    path: ERoutes.security,
    children: [
      { element: <ChangePassword />, path: ERoutes.securityChangePassword },
      { element: <TwoFactor />, path: ERoutes.securityTwoFactor },
      {
        element: <Navigate to={ERoutes.securityChangePassword} replace />,
        path: ERoutes.security,
      },
    ],
  },
  {
    element: <NotificationPage />,
    path: ERoutes.notification,
  },
  {
    element: <PaymentPage />,
    path: ERoutes.payment,
  },
  {
    element: <TransactionPage />,
    path: ERoutes.transaction,
  },
  {
    element: <MyComments />,
    path: ERoutes.myComments,
  },
  {
    element: <Navigate to={ERoutes.account} replace />,
    path: ERoutes.settings,
  },
  {
    path: '*',
    element: <Page404 />,
  },
];
