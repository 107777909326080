import { RootState } from '../../../../store/rootReducer';
import { IProfileRespone, ISettingsAccountState } from './account-types';

export const selectSettingsState = (state: RootState): ISettingsAccountState =>
  state.settingsAccount;

export const getProfileSelector = (state: RootState): IProfileRespone | null =>
  selectSettingsState(state).profile;

export const getPopupSelector = (state: RootState): string => selectSettingsState(state).popup;
export const getLoadingStatusSelector = (state: RootState): string =>
  selectSettingsState(state).loadingState;
