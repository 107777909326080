import moment from 'moment';
import React, { FC, useState } from 'react';
import DatePicker from 'react-datepicker';

type TProps = {
  startDate: string | undefined;
  addDate: (value: string) => void;
  placeholder?: string;
  className?: string;
};

export const Calendar: FC<TProps> = ({ className, startDate, addDate, placeholder = '' }) => {
  return (
    <DatePicker
      className={`input-item ${className} input-item`}
      onChange={(date: Date) => addDate(moment(date).format('YYYY-MM-DD'))}
      value={startDate?.replaceAll('-', '.').split('.').reverse().join('.') || ''}
      showMonthDropdown
      showYearDropdown
      dropdownMode='select'
      placeholderText={placeholder}
    />
  );
};
