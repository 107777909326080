// import { PaginationLeftIcon, PaginationRightIcon } from '../../../assets/svg-icon';
import React, { FC } from 'react';
import ReactPaginate from 'react-paginate';

interface IPaginationProps {
  pageCount: number;
  forcePage: number;
  onPageChange: (selected: number) => void;
}

const Pagination: FC<IPaginationProps> = ({ pageCount, forcePage, onPageChange }) => {
  return (
    <div className='pagination-block'>
      <ReactPaginate
        pageCount={pageCount}
        forcePage={forcePage - 1}
        onPageChange={({ selected }) => onPageChange(selected + 1)}
        className='pagination'
        activeClassName='active'
        activeLinkClassName='active'
        previousLinkClassName='arrow'
        nextLinkClassName='arrow'
        previousLabel={
          <svg
            width='9'
            height='17'
            viewBox='0 0 9 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M7.87404 16.0902L1.6257 9.84182C0.887786 9.10391 0.887786 7.89641 1.6257 7.15849L7.87404 0.910156'
              stroke='#8B8B8B'
              strokeWidth='1.5'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
        nextLabel={
          <svg
            width='9'
            height='17'
            viewBox='0 0 9 17'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              d='M1.03906 16.0902L7.2874 9.84182C8.02531 9.10391 8.02531 7.89641 7.2874 7.15849L1.03906 0.910156'
              stroke='#8B8B8B'
              strokeWidth='1.5'
              strokeMiterlimit='10'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </svg>
        }
      />
    </div>
  );
};

export default Pagination;
