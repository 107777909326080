import { CloseToastifySvg } from '../../assets/svg-icon';
import { LoadingStatus } from '../../enum/types';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { LoadingSmall } from '../../ui/LoadingSmall/LoadingSmall';

interface IProps {
  onClosePopup: () => void;
  loading: string;
  removeUser: () => void;
}

export const PopupDelete: FC<IProps> = ({ removeUser, loading, onClosePopup }) => {
  const { t } = useTranslation();

  return (
    <div className='popup-window'>
      <div className='popup-window__inside'>
        <div className='popup'>
          <button type='button' aria-label='Close' onClick={onClosePopup} className='popup__close'>
            <CloseToastifySvg />
          </button>
          <div className='popup-header'>
            <h2 className='popup-title'>{t('Page.Settings.Account.Delete.title')}</h2>
          </div>
          <div className='popup-body'>
            <div className='popup-text '>
              <p style={{ textAlign: 'center' }}>{t('Page.Settings.Account.Delete.description')}</p>
            </div>
          </div>
          <div className='popup-footer'>
            <div className='form-submit form-submit--mob-column'>
              <button
                onClick={onClosePopup}
                className='button button--type3 button--full-width'
                type='button'
              >
                {t('Global.cancel')}
              </button>
              <button onClick={removeUser} className='button button--full-width' type='button'>
                {loading === LoadingStatus.LOADING ? <LoadingSmall /> : t('Global.delete')}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
