import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoadingStatus } from '../../../enum/types';
import { postContactUs } from './contactUs-thunks';
import { IContactUsState } from './contactUs-types';

const initialState: IContactUsState = {
  loadingState: LoadingStatus.NEVER,
  success: '',
  error: '',
  errors: null,
};

const contactUsSlice = createSlice({
  name: 'contactUs',
  initialState,
  reducers: {
    resetContactUsState: () => initialState,
    clearErrors: (state) => {
      state.error = '';
      state.errors = null;
    },
    clearSuccess: (state) => {
      state.success = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postContactUs.pending, (state) => {
        state.loadingState = LoadingStatus.LOADING;
      })
      .addCase(postContactUs.fulfilled, (state, { payload }) => {
        state.loadingState = LoadingStatus.SUCCESS;
      })
      .addCase(postContactUs.rejected, (state, { payload }: PayloadAction<any>) => {
        state.loadingState = LoadingStatus.ERROR;
        state.errors = payload;
      });
  },
});

export const { resetContactUsState, clearErrors, clearSuccess } = contactUsSlice.actions;
export default contactUsSlice.reducer;
