export const listFaq = [
  {
    id: 1,
    desc: ` Nulla nulla libero a odio quis morbi nisl. Ac eu lobortis amet vivamus quisque
    vitae purus massa. Aliquam imperdiet odio donec venenatis lectus faucibus ut non
    morbi. Elit sapien non volutpat et in semper egestas feugiat duis. Augue
    adipiscing facilisis est volutpat mauris morbi malesuada quisque erat.`,
    question: 'Lorem ipsum dolor sit amet consectetur?',
  },
  {
    id: 2,
    desc: ` Nulla nulla libero a odio quis morbi nisl. Ac eu lobortis amet vivamus quisque
    vitae purus massa. Aliquam imperdiet odio donec venenatis lectus faucibus ut non
    morbi. Elit sapien non volutpat et in semper egestas feugiat duis. Augue
    adipiscing facilisis est volutpat mauris morbi malesuada quisque erat.`,
    question: 'Lorem ipsum dolor sit amet consectetur?',
  },
  {
    id: 3,
    desc: ` Nulla nulla libero a odio quis morbi nisl. Ac eu lobortis amet vivamus quisque
    vitae purus massa. Aliquam imperdiet odio donec venenatis lectus faucibus ut non
    morbi. Elit sapien non volutpat et in semper egestas feugiat duis. Augue
    adipiscing facilisis est volutpat mauris morbi malesuada quisque erat.`,
    question: 'Lorem ipsum dolor sit amet consectetur?',
  },
  {
    id: 4,
    desc: ` Nulla nulla libero a odio quis morbi nisl. Ac eu lobortis amet vivamus quisque
    vitae purus massa. Aliquam imperdiet odio donec venenatis lectus faucibus ut non
    morbi. Elit sapien non volutpat et in semper egestas feugiat duis. Augue
    adipiscing facilisis est volutpat mauris morbi malesuada quisque erat.`,
    question: 'Lorem ipsum dolor sit amet consectetur?',
  },
  {
    id: 5,
    desc: ` Nulla nulla libero a odio quis morbi nisl. Ac eu lobortis amet vivamus quisque
    vitae purus massa. Aliquam imperdiet odio donec venenatis lectus faucibus ut non
    morbi. Elit sapien non volutpat et in semper egestas feugiat duis. Augue
    adipiscing facilisis est volutpat mauris morbi malesuada quisque erat.`,
    question: 'Lorem ipsum dolor sit amet consectetur?',
  },
];
