import React from 'react';
import { Provider } from 'react-redux';
import { createRoot, hydrateRoot } from 'react-dom/client';
import { ToastContainer } from 'react-toastify';

import { PersistGate } from 'redux-persist/integration/react';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { persistor, store } from './store/store';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import { HelmetProvider } from 'react-helmet-async';
import i18next from './languages/i18n';
import { I18nextProvider } from 'react-i18next';

import 'react-toastify/dist/ReactToastify.css';
import 'react-datepicker/dist/react-datepicker.css';

const container = document.getElementById('root')!;

// if (container?.hasChildNodes()) {
//   hydrateRoot(
//     container,
//     <Provider store={store}>
//       <HelmetProvider>
//         <React.StrictMode>
//           <I18nextProvider i18n={i18next}>
//             <BrowserRouter>
//               <App />
//             </BrowserRouter>
//           </I18nextProvider>
//         </React.StrictMode>
//       </HelmetProvider>
//       <ToastContainer />
//     </Provider>
//   );
// } else {
createRoot(container).render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <I18nextProvider i18n={i18next}>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </I18nextProvider>
        <ToastContainer />
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);
// }

declare global {
  interface Window {
    initialLanguage: string;
    initialI18nStore: Record<string, any>;
  }
}
