import React, { FC } from 'react';

export const CreateOrganize: FC = () => (
  <svg width='52' height='52' viewBox='0 0 52 52' fill='none' xmlns='http://www.w3.org/2000/svg'>
    <path
      d='M26.1949 20.7688C26.0812 20.7525 25.9349 20.7525 25.8049 20.7688C22.9449 20.6713 20.6699 18.3313 20.6699 15.455C20.6699 12.5138 23.0424 10.125 25.9999 10.125C28.9412 10.125 31.3299 12.5138 31.3299 15.455C31.3137 18.3313 29.0549 20.6713 26.1949 20.7688Z'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M36.9519 31.4927C34.0594 34.1414 30.2244 35.7502 25.9994 35.7502C21.7744 35.7502 17.9394 34.1414 15.0469 31.4927C15.2094 29.9652 16.1844 28.4702 17.9231 27.3002C22.3756 24.3427 29.6556 24.3427 34.0756 27.3002C35.8144 28.4702 36.7894 29.9652 36.9519 31.4927Z'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26 35.75C34.9746 35.75 42.25 28.4746 42.25 19.5C42.25 10.5254 34.9746 3.25 26 3.25C17.0254 3.25 9.75 10.5254 9.75 19.5C9.75 28.4746 17.0254 35.75 26 35.75Z'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M26 35.75V41.1667'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M25.9993 49.8346C28.3926 49.8346 30.3327 47.8945 30.3327 45.5013C30.3327 43.1081 28.3926 41.168 25.9993 41.168C23.6061 41.168 21.666 43.1081 21.666 45.5013C21.666 47.8945 23.6061 49.8346 25.9993 49.8346Z'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M39.0007 45.5H30.334'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
    <path
      d='M21.6667 45.5H13'
      stroke='#FBFBFB'
      strokeWidth='1.5'
      strokeMiterlimit='10'
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
);
